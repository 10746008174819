import { Control, Controller, FieldValues } from 'react-hook-form'
import { FormControl, FormControlProps, TextFieldProps } from '@mui/material'

import { ReactComponent as Checkmark } from '@assets/icons/Checkmark.svg'
import MuiCheckbox from '@mui/material/Checkbox'
import React from 'react'
import styles from './Checkbox.module.scss'

interface Props extends FormControlProps {
  name: string
  label?: React.ReactNode
  control?: Control<FieldValues> | undefined
  defaultValue?: string
  inputProps?: TextFieldProps
}

export const Checkbox = ({
  name,
  control,
  label,
  defaultValue,
  ...props
}: Props) => {
  return (
    <FormControl {...props}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <div className={styles.container}>
            <MuiCheckbox
              icon={<div className={`checkbox ${styles.checkbox}`} />}
              checkedIcon={
                <div className={styles.checkbox}>
                  <Checkmark className={styles.checked} />
                </div>
              }
              className={styles.container}
              onChange={onChange}
              onBlur={onBlur}
              checked={value ? true : false}
              inputRef={ref}
            />
            <label className={styles.label}>{label}</label>
          </div>
        )}
      />
    </FormControl>
  )
}
