import { Control, Controller, FieldValues } from 'react-hook-form'

import { FormControlProps } from '@mui/material'
import Rating from '@mui/material/Rating'
import React from 'react'
import { ReactComponent as StarIcon } from '@assets/icons/ratingStar.svg'
import styles from './Stars.module.scss'

interface Props extends FormControlProps {
  name?: string
  control?: Control<FieldValues> | undefined
  readonly?: boolean
  value?: number
  precision?: number
  className?: string
}

export const Stars = ({
  name,
  control,
  readonly,
  value,
  precision,
  className,
}: Props) => {
  return (
    <>
      {readonly ? (
        <Rating
          emptyIcon={<StarIcon />}
          readOnly
          name={readonly && 'read-only'}
          value={value}
          className={`${styles.starsRating} ${className}`}
          precision={precision}
        />
      ) : (
        <Controller
          name={name ? name : ''}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Rating
              precision={precision}
              icon={
                <StarIcon
                  className={`${styles.selectedStar} ${styles.star} star`}
                />
              }
              emptyIcon={
                <StarIcon
                  className={`${styles.emptyStar} ${styles.star} star`}
                />
              }
              onChange={onChange}
              onBlur={onBlur}
              value={Number(value)}
              className={`${styles.starsRating} ${className}`}
            />
          )}
        />
      )}
    </>
  )
}
