import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { FieldErrorsImpl } from 'react-hook-form'

interface Props {
  errors: Partial<FieldErrorsImpl<{ [p: string]: any }>>
  name: string
  style?: any
}

function FormError({ errors, name, style }: Props) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }: { message: string }) => (
        <p style={style ? { position: style } : {}} className={'form_error'}>
          {message}
        </p>
      )}
    />
  )
}

export default FormError
