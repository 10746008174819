import { axiosInstance } from '@/config'
import {
  createSubscribeType,
  getSubscribtionAdvertsType,
  deleteSubscribeAdvertsType,
} from './subscriptionAdverts.types'
import { AxiosError } from 'axios'
import QueryParamsParser from '@/helpers/queryParamsParser/queryParamsParser'

export const createSubscribe: createSubscribeType = async (params) => {
  try {
    return axiosInstance.post(`/subscription-adverts`, { ...params })
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const getSubscribtionAdverts: getSubscribtionAdvertsType = async (
  params: any
) => {
  const url = QueryParamsParser('/subscription-adverts', { ...params })

  try {
    return axiosInstance.get(url)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const deleteSubscribeAdverts: deleteSubscribeAdvertsType = async (
  subscriptionId
) => {
  try {
    return axiosInstance.delete(`/subscription-adverts/${subscriptionId}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}
