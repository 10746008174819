import {
  BlogNews,
  deleteBlogNewsById,
  getBlogNewsByTheme,
} from '@/services/api/blog'
import { Button, TableCell, TableRow } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import { ReactComponent as AddSVG } from '@assets/icons/CircleCross.svg'
import { AxiosError } from 'axios'
import BlogTable from './BlogTable'
import Modal from '@/components/modals/Modal/Modal'
import TableContainer from '@/components/elements/TableContainer'
import styles from './blog-moderation.module.scss'
import { useNavigate } from 'react-router-dom'

const BlogModeration = () => {
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const [blogId, setBlogId] = useState<number>(0)
  const navigation = useNavigate()
  const [blogs, setBlogs] = useState<{ total: number; items: BlogNews[] }>({
    items: [],
    total: 0,
  })
  const [params, setParams] = useState({
    page: 0,
    limit: 10,
  })

  useEffect(() => {
    const getBlogsData = async () => {
      const blogsData = (
        await getBlogNewsByTheme({
          ...params,
          themeId: 1,
          page: params.page + 1,
        })
      )?.data
      blogsData && setBlogs(blogsData)
    }
    getBlogsData()
  }, [params, submitted])

  const handleChangePage = (_: unknown, newPage: number) => {
    setParams((prev) => ({ ...prev, page: newPage }))
  }
  const handleClose = () => setIsOpen((prev) => !prev)

  const handleOpenSuccessModal = () => {
    setIsOpen(true)
    setIsSuccess(true)
  }

  const handleOpenFailedModal = () => {
    setIsOpen(true)
    setIsSuccess(false)
  }

  const handleConfirm = async (id: number) => {
    setBlogId(id)
    setConfirmModal(true)
    setIsOpen(true)
  }

  const handleEdit = async (id: number, slug: string) => {
    setConfirmModal(false)
    try {
      navigation(`add?id=${id}`, { state: slug })
      handleOpenSuccessModal()
      setSubmitted((prev) => !prev)
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        handleOpenFailedModal()
        setSubmitted((prev) => !prev)
        throw new Error(e.message)
      }
    }
  }

  const handleDelete = async () => {
    try {
      await deleteBlogNewsById(blogId)
      setConfirmModal(false)
      handleOpenSuccessModal()
      setSubmitted((prev) => !prev)
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        setConfirmModal(false)
        handleOpenFailedModal()
        setSubmitted((prev) => !prev)
        throw new Error(e.message)
      }
    }
  }

  const renderList = useMemo(
    () =>
      blogs.items.map((blog) => (
        <BlogTable
          handleConfirm={handleConfirm}
          handleEdit={handleEdit}
          key={blog.id}
          {...blog}
        />
      )),
    [blogs.items]
  )
  return (
    <>
      <Modal onClose={handleClose} open={isOpen}>
        {confirmModal ? (
          <div className={styles.confirmModal}>
            <p>Вы уверены что хотите удалить блог?</p>
            <div className={styles.btns}>
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={handleDelete}
              >
                Да
              </Button>
              <Button
                color="error"
                size="small"
                onClick={() => {
                  setConfirmModal(false)
                  setBlogId(0)
                }}
              >
                Нет
              </Button>
            </div>
          </div>
        ) : (
          <h4>
            {!isSuccess
              ? 'Произошла ошибка, повторите еще раз'
              : 'Ответ успешно записан'}
          </h4>
        )}
      </Modal>
      <div className={styles.containerHeader}>
        <h2 style={{ marginBottom: 20 }}>Модерация блогов</h2>
        <Button
          variant="contained"
          color="success"
          size="small"
          sx={{ height: 35, display: 'flex', gap: '10px !important' }}
          onClick={() => navigation('add')}
        >
          Создать блог
          <AddSVG />
        </Button>
      </div>
      <div className={styles.table}>
        <TableContainer
          Header={
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell width={'50%'}>Заголовок:</TableCell>
              <TableCell width={'50%'}>Дата и время: </TableCell>
              <TableCell></TableCell>
            </TableRow>
          }
          Body={renderList}
          handleChangePage={handleChangePage}
          page={params.page}
          rowsPerPage={params.limit}
          count={blogs.total}
        />
      </div>
    </>
  )
}

export default BlogModeration
