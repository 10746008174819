import { getModerationsType, updateAdminAnswerType } from './moderations.types'
import { axiosInstance } from '@/config'
import QueryParamsParser from '@/helpers/queryParamsParser/queryParamsParser'
import { AxiosError } from 'axios'

export const getModerations: getModerationsType = async (params) => {
  const url = QueryParamsParser('moderations', { ...params })
  try {
    return axiosInstance.get(url)
  } catch (e: unknown) {
    if (e instanceof AxiosError) throw new Error(e.message)
  }
}

export const updateAdminAnswer: updateAdminAnswerType = async (
  answer,
  moderationId
) => {
  try {
    return axiosInstance.put(`moderations/${moderationId}`, { ...answer })
  } catch (e: unknown) {
    if (e instanceof AxiosError) throw new Error(e.message)
  }
}
