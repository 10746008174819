import { LoginResponse } from '@/services/api/auth'
import axios, { AxiosResponse } from 'axios'
import { io } from 'socket.io-client'

export const domain =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PRODUCTION_URL
    : process.env.REACT_APP_DEV_URL

export const backendUrlV1 = domain + 'api/v1'

export const backendUrl = domain + 'api/'

export const axiosInstance = axios.create({
  baseURL: backendUrlV1,
  withCredentials: true,
})

export const socket = io({
  autoConnect: false,
  withCredentials: true,
  extraHeaders: {
    Authorization: `${localStorage.getItem('actok')}`,
  },
})

axiosInstance.interceptors.request.use((successReq) => {
  if (successReq && successReq.headers) {
    successReq.headers.Authorization = localStorage.getItem('actok') // к каждому нашему запросу прикрепляем наш access токен
  }
  return successReq
})

axiosInstance.interceptors.response.use(
  (successRes: AxiosResponse) => {
    // срабатывает каждый раз когда мы получаем ответ
    return successRes
  },
  async (error) => {
    // если статус 401 (например access токен истек)
    const originalRequest = error.config

    if (originalRequest.url === '/auth/login') {
      return false
    }

    if (
      error.response.status == 401 &&
      error.config &&
      originalRequest._isRetry !== true
    ) {
      originalRequest._isRetry = true
      try {
        const response = await axios.post<LoginResponse>(
          `${backendUrlV1}/auth/refresh`,
          { withCredentials: true }
        )
        localStorage.setItem('actok', response.data.accessToken)
        return axiosInstance.request(originalRequest) // продолжаем запрос
      } catch (e) {
        console.log('НЕ АВТОРИЗОВАН')
      }
    }
    throw error
  }
)

// export const mediaUrl = process.env.REACT_APP_MEDIA_URL;
