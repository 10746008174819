import {
  ChangePasswordType,
  ForgotPasswordType,
  LoginResponse,
  LoginType,
  ProceedSignupResponse,
  ProceedSignupType,
  SignupType,
  logoutType,
} from './auth.types'

import { AxiosError } from 'axios'
import { axiosInstance } from '@/config'
import { getCurrentUser } from '../users'
import { userSignup } from '@/types/Auth'

export * from './auth.types' // экспортирую типы, чтобы к ним можно было обратиться через @/services/api/auth

export const signup: SignupType = async (params) => {
  try {
    const signReq = await axiosInstance.post<userSignup>(
      '/auth/sign-up',
      params
    )
    return signReq
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      if (e.code === 'ERR_BAD_REQUEST') {
        throw new Error(e!.response!.data.errors[0].type)
      }
      throw new Error(e!.response!.data.message)
    }
  }
}

export const login: LoginType = async (params) => {
  try {
    const loginRes = await axiosInstance.post<LoginResponse>(
      '/auth/login',
      params
    )

    localStorage.setItem('actok', loginRes.data.accessToken)
    localStorage.setItem('uid', String(loginRes.data.id))

    getCurrentUser(loginRes.data.id)

    return loginRes
  } catch (e: any) {
    console.error(e)
    throw new Error(e.message)
  }
}

export const proceedRegistration: ProceedSignupType = async (params) => {
  try {
    const proceed = await axiosInstance.post<ProceedSignupResponse>(
      '/auth/proceed-registration',
      params
    )
    const currentId = localStorage.getItem('uid')

    if (currentId) {
      getCurrentUser(+currentId)
    }

    return proceed
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const forgotPassword: ForgotPasswordType = async (
  email,
  recaptchaValue
) => {
  try {
    const sendStatus = await axiosInstance.post<ProceedSignupResponse>(
      '/auth/forgot-password',
      {
        email,
        recaptchaValue,
      }
    )

    return sendStatus
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      console.log(e.code)
      if (e!.response!.data.message === 'VALIDATION_ERROR') {
        throw new Error(e!.response!.data.errors[0].type)
      }
      throw new Error(e!.response!.data.message)
    }
  }
}

export const changePassword: ChangePasswordType = async ({
  token,
  password,
}) => {
  try {
    const sendStatus = await axiosInstance.post<ProceedSignupResponse>(
      '/auth/change-password',
      {
        token,
        password,
      }
    )

    return sendStatus
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const logout: logoutType = async () => {
  try {
    const response = await axiosInstance.post('/auth/logout')
    localStorage.removeItem('actok')
    localStorage.removeItem('uid')
    return response
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}
