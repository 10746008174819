import { Button, TableCell, TableRow } from '@mui/material'
import { deleteTheme, getThemes } from '@/services/api/themes'
import { useEffect, useMemo, useState } from 'react'

import AddNewTheme from './AddNewTheme'
import Modal from '@/components/modals/Modal/Modal'
import TableContainer from '@/components/elements/TableContainer'
import ThemeTable from './ThemeTable'
import styles from './theme-moderation.module.scss'
import { themeType } from '@/types/theme'

type AllThemesType = {
  items: themeType[]
  total: number
}

const ThemeModeration = () => {
  const [allThemes, setThemes] = useState<AllThemesType>({
    items: [],
    total: 0
  })
  const [confirmModal, setConfirmModal] = useState(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [params, setParams] = useState({
    page: 0,
    limit: 50,
  })

  const [selectedThemeId, setSelectedThemeId] = useState(0)

  useEffect(() => {
    const getAllThemes = async () => {
      try {
        const tempParams = { ...params, page: params.page + 1 }
        const fetchedThemes = (await getThemes(tempParams))?.data
        setThemes(fetchedThemes || {} as AllThemesType )
      } catch (err) {
        setIsSuccess(false)
        setIsOpen(true)
      }
    }
    getAllThemes()
  }, [params, submitted])

  const handleChangePage = (_: unknown, newPage: number) => {
    setParams((prev) => ({ ...prev, page: newPage }))
  }

  const handleClose = () => setIsOpen((prev) => !prev)
  const handleDelete = async () => {
    await deleteTheme(selectedThemeId)
      .then(() => {
        setConfirmModal(false)
        setIsSuccess(true)
      })
      .catch(() => {
        setIsSuccess(false)
        setIsOpen(true)
      })
    setSelectedThemeId(0)
    setSubmitted((prev) => !prev)
  }

  const handleDeleteConfirmation = (id: number) => {
    setIsOpen(true)
    setConfirmModal(true)
    setSelectedThemeId(id)
  }

  const { handleOpen, AddThemeModal } = AddNewTheme()

  const renderList = useMemo(
    () =>
      allThemes?.items.map((theme) => (
        <ThemeTable
          handleDelete={handleDeleteConfirmation}
          key={theme.id}
          {...theme}
        />
      )),
    [allThemes?.items]
  )
  return (
    <>
      <AddThemeModal setSubmitted={setSubmitted} />
      {confirmModal ? (
        <Modal onClose={handleClose} open={isOpen}>
          <div className={styles.confirmModal}>
            <p>Вы уверены, что хотите удалить тему?</p>
            <div className={styles.btns}>
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={() => handleDelete()}
              >
                Да
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => {
                  setConfirmModal(false)
                  setSelectedThemeId(0)
                }}
              >
                Нет
              </Button>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal onClose={handleClose} open={isOpen}>
          <h4>
            {!isSuccess
              ? 'Произошла ошибка, повторите еще раз'
              : 'Ответ успешно записан'}
          </h4>
        </Modal>
      )}
      <Button
        style={{ float: 'right', marginBottom: 20 }}
        variant="contained"
        color="success"
        onClick={handleOpen}
      >
        Добавить новую тему
      </Button>
      <h2 style={{ marginBottom: 20 }}>Модерация темы</h2>
      <TableContainer
        Header={
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Название</TableCell>
            <TableCell></TableCell>
          </TableRow>
        }
        Body={renderList}
        handleChangePage={handleChangePage}
        page={params.page}
        rowsPerPage={params.limit}
        count={allThemes.total}
      />
    </>
  )
}

export default ThemeModeration
