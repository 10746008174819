import { backendUrl } from '@/config'
import { ReactComponent as LocationSVG } from '@assets/icons/location_pin.svg'
import dayjs from 'dayjs'
import styles from './UserFeedback.module.scss'
import { ReactComponent as PhotoIcon } from '@assets/icons/photo.svg'
import { Stars } from '@/components/forms/elements/ratings'

interface Props {
  review: any
  className?: string
}

export const UserFeedback = ({ className, review }: Props) => {
  return (
    <div className={`${className} ${styles.container}`}>
      <div
        className={styles.avatar}
        style={{
          backgroundImage: `url(${backendUrl}files/${review.author.avatar})`,
        }}
      >
        {!review.author.avatar && (
          <PhotoIcon className={styles.photoIcon} />
        )}
      </div>
      <div className={styles.userInfo}>
        <div className={styles.ratingAndName}>
          <strong>
            {review?.author?.firstName}, {review?.author?.age}
          </strong>
          <Stars className={styles.rating} readonly value={review.mark} />
        </div>
        <div className={styles.details}>
          <p className={styles.location}>
            <LocationSVG />
            {review?.author?.cityName || 'Москва'}
          </p>
          <p>{dayjs(review?.createdAt).format('DD.MM.YYYY')}</p>
        </div>
        <p className={styles.desc}>{review?.message}</p>
      </div>
    </div>
    // <h1>hello world</h1>
  )
}
