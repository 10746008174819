import DOMPurify from 'dompurify'
import { ReactComponent as ReadCheck } from '@/assets/icons/readCheck.svg'
import { ReactComponent as SentCheck } from '@/assets/icons/sentCheck.svg'
import { backendUrl } from '@/config'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { dialogMessageType } from '@/types/Dialogs'
import parse from 'html-react-parser'
import styles from './Message.module.scss'

interface Props {
  messageInfo: dialogMessageType
  userInfoEmail?: string
  handleImageLoadScroll: () => void
  finalMessage?: boolean
}

export const Message = ({
  userInfoEmail,
  handleImageLoadScroll,
  messageInfo,
}: Props) => {
  const { createdAt, isRead, id, messages } = messageInfo
  const messageSenderClasses = classNames({
    [styles.message_messageWrap]: true,
    [styles.message_sender]: userInfoEmail === messageInfo.sender,
    [styles.message_reciever]: userInfoEmail !== messageInfo.sender,
    [styles.message_image]: messages?.includes('EXTF'),
  })

  const file = messages?.includes('EXTF:') ? messages?.split(':') : []

  const handleRedirect = () => {
    window.location.href = `${backendUrl}files/${file[1]}`
  }

  return (
    <div
      data-id={id}
      data-isread={isRead}
      data-author={messageInfo.sender}
      className={styles.message}
    >
      <div className={messageSenderClasses}>
        {!messages?.includes('EXTF') ? (
          <>
            <div className={styles.message_text}>
              {parse(
                DOMPurify.sanitize(`${messages.replace(/\n/g, '<br />')}`)
              )}
            </div>
          </>
        ) : (
          <div
            className={styles.message_image}
            onClick={() => handleRedirect()}
          >
            <img
              onLoad={() => handleImageLoadScroll()}
              src={`${backendUrl}files/${file[1]}`}
            />
          </div>
        )}
        <div className={styles.extraInfo}>
          <span className={styles.message_time}>
            {dayjs(createdAt).format('HH:mm')}
          </span>
          {userInfoEmail === messageInfo.sender && (
            <div className={styles.message_checks}>
              {isRead ? <ReadCheck /> : <SentCheck />}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
