import { Button, TableCell, TableRow } from '@mui/material'

import EllipsisText from '@/components/elements/EllipsisText'
import React from 'react'

interface IProps {
  id: number
  name: string
  handleDelete: (id: number) => void
}

const ThemeTable: React.FC<IProps> = ({ id, name, handleDelete }) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell align="left">
        {<EllipsisText len={30}>{name}</EllipsisText>}
      </TableCell>
      <TableCell align="left">
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => handleDelete(id)}
        >
          Удалить
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default ThemeTable
