import TableContainer from '@/components/elements/TableContainer'
import Modal from '@/components/modals/Modal/Modal'
import { useAppDispatch, useAppSelector } from '@/helpers/hooks'
import { TableCell, TableRow } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import UsersTable from './UsersTable'
import { fetchUsers } from '@/store/slices/users'

const UsersModeration = () => {
  const { users } = useAppSelector((state) => state.users)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [params, setParams] = useState({
    page: 0,
    limit: 10,
  })
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchUsers({ ...params, page: params.page + 1 }))
  }, [params, submitted])

  const handleClose = () => setIsOpen((prev) => !prev)
  const handleOpenSuccessModal = () => {
    setIsOpen(true)
    setIsSuccess(true)
  }
  const handleOpenFailedModal = () => {
    setIsOpen(true)
    setIsSuccess(false)
  }

  const renderList = useMemo(
    () =>
      users.items.map((user) => (
        <UsersTable
          handleOpenSuccessModal={handleOpenSuccessModal}
          handleOpenFailedModal={handleOpenFailedModal}
          key={user.id}
          {...user}
          setSubmitted={setSubmitted}
        />
      )),
    [users.items]
  )

  const handleChangePage = (_: unknown, newPage: number) => {
    setParams((prev) => ({ ...prev, page: newPage }))
  }
  return (
    <>
      <Modal onClose={handleClose} open={isOpen}>
        <h4>
          {!isSuccess
            ? 'Произошла ошибка, повторите еще раз'
            : 'Ответ успешно записан'}
        </h4>
      </Modal>
      <h2 style={{ marginBottom: 20 }}>Пользователи</h2>
      <TableContainer
        Header={
          <TableRow>
            <TableCell>ФИО</TableCell>
            <TableCell>Почта</TableCell>
            <TableCell>Телефон</TableCell>
          </TableRow>
        }
        Body={renderList}
        handleChangePage={handleChangePage}
        page={params.page}
        rowsPerPage={params.limit}
        count={users.total}
      />
    </>
  )
}

export default UsersModeration
