import { FieldValues, useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { SearchInput, TextInput } from '@/components/forms/elements/inputs'
import { getCategories, getSubCategories } from '@/services/api/categories'

import { ReactComponent as Arrow } from '@assets/icons/Arrow.svg'
import { Checkbox } from '@/components/forms/elements/checkboxes'
import FormError from '@/components/forms/elements/FormError/FormError'
import { GenderRadio } from '@/components/forms/elements/radio/GenderRadio'
import { GreenButton } from '@/components/elements/buttons'
import MobileSetting from '@/assets/icons/MainSettings.svg'
import { Select } from '@/components/forms/elements/selects'
import dayjs from 'dayjs'
import { debounce } from '@helpers/decorators/debounce'
import { getAllCities } from '@/services/api/cities'
import { homeAdvertParamsType } from '@/components/pages/Home'
import styles from './top-select-filter.module.scss'
import { useAppSelector } from '@helpers/hooks'
import { useLocation } from 'react-router-dom'

type SelectOption = {
  id: number | string
  value: string
}

type SelectArraysType = {
  categories: SelectOption[]
  subcategories: SelectOption[]
  maritalStatus: string[]
  age: SelectOption[]
}

type Props = {
  advertParams: homeAdvertParamsType
  setAdvertParams: React.Dispatch<
    React.SetStateAction<{ page: number; filterParams: any }>
  >
  setCurrentCity: React.Dispatch<React.SetStateAction<string>>
}

const defaults = {
  maritalStatus: { id: 'default', value: 'Семейное положение' },
  subcategories: { id: 'default', value: 'Подкатегория' },
  categories: { id: 'default', value: 'Категория' },
  age: { id: 'default', value: 'Возраст' },
}

export const TopSelectFilterForm = ({
  advertParams,
  setAdvertParams,
  setCurrentCity,
}: Props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
    setError,
  } = useForm()

  const { userInfo } = useAppSelector((state) => state.auth)
  const { cities } = useAppSelector((state) => state.cities)
  const { state } = useLocation()
  const [selectArrays, setSelectArrays] = useState<SelectArraysType>({
    categories: [],
    subcategories: [defaults['subcategories']],
    maritalStatus: [
      'Женат',
      'Не женат',
      'Замужем',
      'Не замужем',
      'Всё сложно',
      'В активном поиске',
    ],
    age: [
      { id: '18-24', value: '18-24' },
      { id: '25-34', value: '25-34' },
      { id: '35-44', value: '35-44' },
      { id: '45+', value: '45+' },
    ],
  })
  console.log('selectArrays', selectArrays)

  const [fetchedWithProSub, setFetchedWithProSub] = useState(false)

  const watchCategory = watch('categoryId')

  const [opened, setOpened] = useState(false)

  const getSub = async (value: string) => {
    if (!selectArrays.subcategories?.length) return
    const subcategories = (await getSubCategories({ categoryId: value }))?.data
    if (!subcategories?.length) return

    const newSubcategory = [
      ...subcategories?.map((item) => ({
        id: item.id,
        value: item.name,
      })),
    ]

    if (!fetchedWithProSub && state?.subcategoryId) {
      setValue('subcategoryId', state?.subcategoryId)
      setFetchedWithProSub(true)
    }
    // else {
    //   setValue('subcategoryId', newSubcategory[0].id)
    // }

    setSelectArrays((prev) => ({
      ...prev,
      subcategories: newSubcategory,
    }))
    return newSubcategory[0].id
  }

  useEffect(() => {
    if (state) {
      const newData: FieldValues = {}
      for (const key in state) {
        if (state[key] !== 'null' && state[key] !== 'null-null') {
          newData[key] = state[key]
        }
      }

      if (newData['age'] === '45-null') {
        const updatedString = newData['age'].replace('-null', '+')
        newData['age'] = updatedString
      }
      if (state['category']) {
        console.log(state['category'])
        getSub(state['category'])
      }
      submitHandler({ ...newData })
      reset({ ...newData })
    }
  }, [state])

  useEffect(() => {
    const cityName = userInfo?.cityName
    if (!cities.length && cityName && !state?.kladrName) {
      getAllCities(cityName)
    }
  }, [cities, userInfo?.cityName])

  const submitHandler = async (data: FieldValues) => {
    if (+data.budgetTo?.length && +data?.budgetFrom > +data?.budgetTo) {
      setError('budgetFrom', {
        type: 'custom',
        message: '"Бюджет от" не может быть меньше "Бюджет до"',
      })
    } else {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { cityId, cityName, ...otherFilterParams } =
          advertParams.filterParams
        const newData: FieldValues = {
          ...data,
          budgetFrom: !data.budgetFrom ? '0' : data.budgetFrom,
        }
        await getAllCities(data.cityName)
        const currCityData = cities.find((item) => item.value === data.cityName)
        if (cities.length && !currCityData && !state && !data.cityName) {
          newData['cityId'] = 'default'
          newData['cityName'] = 'default'
          setValue('cityId', '')
        } else if (currCityData || state?.kladrId) {
          newData['cityId'] = currCityData?.data.kladr_id || state?.kladrId
          newData['cityName'] = data.cityName
        }
        setCurrentCity(currCityData?.value || state?.cityName)
        // newData['category'] = 'default'
        const queryParams: Partial<FieldValues> = {
          ...otherFilterParams,
        }
        for (const key in newData) {
          if (newData[key] !== undefined && newData[key] !== 'default') {
            queryParams[key] = newData[key]
          } else if (newData[key] !== undefined && newData[key] === 'default') {
            queryParams[key] = ''
          }
        }

        if (queryParams.age === '45-null') {
          const updatedString = queryParams.age.replace('-null', '')
          queryParams['age'] = updatedString
        }
        setAdvertParams((prev) => ({
          ...prev,
          filterParams: { ...prev.filterParams, ...queryParams },
        }))
      } catch (error) {
        console.log(error)
      }
    }
  }
  useEffect(() => {
    if (state?.kladrName) {
      reset({ cityName: state?.kladrName })
      setValue('cityName', state?.kladrName)
    } else if (userInfo && !state) {
      reset({ cityName: userInfo.cityName })
      setValue('cityName', userInfo.cityName)
    }
  }, [userInfo])

  useEffect(() => {
    const settingSelectsData = async () => {
      try {
        const categories = (await getCategories())?.data

        if (!categories) return
        setSelectArrays(
          (prev) =>
            ({
              ...prev,
              categories: [
                ...categories?.map((item) => ({
                  id: item.id,
                  value: item.name,
                })),
              ],
            } as SelectArraysType)
        )
      } catch (e) {
        console.log(e)
      }
    }

    settingSelectsData().then(() => console.log(selectArrays))
  }, [])

  useEffect(() => {
    debouncedFetchCities(userInfo?.cityName)
  }, [])

  useEffect(() => {
    getSub(watchCategory)
  }, [watchCategory])

  const fetchCities = async (value: string) => {
    try {
      await getAllCities(value)
    } catch (e) {
      console.log(e)
    }
  }

  const debouncedFetchCities = debounce((value: string) => fetchCities(value))

  const changeOpen = () => {
    setOpened((prev) => !prev)
  }

  return (
    <div style={{ position: 'relative' }}>
      <img
        src={MobileSetting}
        className={styles.mobile_settings}
        onClick={changeOpen}
      />
      <div className={styles.top}>
        <form
          id={'top_form'}
          className={`${styles.form} ${opened ? '' : styles.form_overflow}`}
          onSubmit={handleSubmit(submitHandler)}
        >
          <div className={styles.form_inputs}>
            <div className={styles.form__top_row}>
              <div className={styles.big_select}>
                <Select
                  control={control}
                  items={selectArrays.categories || []}
                  className={styles.select}
                  placeholder="Категория"
                  {...register('categoryId')}
                />
              </div>
              <div className={styles.big_select}>
                <Select
                  control={control}
                  name={'subcategoryId'}
                  items={selectArrays.subcategories || []}
                  className={styles.select}
                  placeholder={'Подкатегория'}
                />
              </div>

              <div className={`${styles.big_select} ${styles.form_city}`}>
                <SearchInput
                  options={cities.map(({ value }) => value)}
                  control={control}
                  OnTextChange={(value) => {
                    setValue('cityName', value)
                    debouncedFetchCities(value)
                  }}
                  placeholder="Город"
                  defaultValue={userInfo?.cityName}
                  {...register('cityName')}
                />
              </div>

              <div className={styles.form_radio_group}>
                <GenderRadio
                  control={control}
                  className={styles.group}
                  defaultValue={dayjs()
                    .year(dayjs().year() - 18)
                    .format('DD.MM.YYYY')}
                  {...register('gender')}
                  selectsProps={{ className: styles.select_bg }}
                />
              </div>
              <div className={styles.big_select}>
                <Select
                  control={control}
                  name={'age'}
                  className={styles.ageSelect}
                  items={selectArrays.age || []}
                  placeholder={'Возраст'}
                />
              </div>
              <div className={styles.big_select}>
                <Select
                  control={control}
                  name={'maritalStatus'}
                  items={selectArrays.maritalStatus}
                  className={styles.select}
                  placeholder={'Семейное положение'}
                />
              </div>
              <div className={styles.mini_select}>
                <TextInput
                  control={control}
                  {...register('budgetFrom')}
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 0 },
                    placeholder: 'Бюджет от',
                  }}
                  className={styles.white_input}
                />
                <FormError
                  style={'absolute'}
                  errors={errors}
                  name="budgetFrom"
                />
              </div>
              <div className={styles.mini_select}>
                <TextInput
                  control={control}
                  name={'budgetTo'}
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 0 },
                    placeholder: 'Бюджет до',
                  }}
                  className={styles.white_input}
                />
              </div>
              <div className={styles.big_select}>
                <Checkbox
                  control={control}
                  name={'photo'}
                  label={'Только с фото'}
                />
              </div>
              {userInfo?.roleId === 3 && (
                <div className={styles.big_select} style={{ flex: '2 1%' }}>
                  <Checkbox
                    control={control}
                    name={'subscription'}
                    label={'Сохранить набор фильтров'}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.form_btns}>
            <GreenButton className={styles.form_btn} type={'submit'}>
              Поиск
            </GreenButton>
          </div>
          <br />
          <GreenButton
            className={styles.form_btn_reset_mobile}
            type={'submit'}
            onClick={() => {
              reset({
                categoryId: '',
                subcategoryId: '',
                gender: '',
                age: '',
                maritalStatus: '',
                budgetFrom: '',
                budgetTo: '',
                photo: '',
                cityName: '',
              })
            }}
            form={'top_form'}
          >
            Сбросить фильтр
          </GreenButton>
        </form>
      </div>

      <span onClick={changeOpen} className={styles.visibility_changing}>
        {opened ? (
          <p>
            Свернуть <Arrow />
          </p>
        ) : (
          <span>
            Расширенный фильтр <Arrow />
          </span>
        )}
      </span>
      <GreenButton
        className={styles.form_btn_reset}
        type={'submit'}
        onClick={() => {
          reset({
            categoryId: '',
            subcategoryId: '',
            gender: '',
            age: '',
            maritalStatus: '',
            budgetFrom: '',
            budgetTo: '',
            photo: '',
            cityName: '',
          })
        }}
        form={'top_form'}
      >
        Сбросить фильтр
      </GreenButton>
    </div>
  )
}
