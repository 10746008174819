import { ReactComponent as OrangeEllipse } from '@/assets/icons/orange_ellipse.svg'
import dayjs from 'dayjs'
import React from 'react'

import styles from './notifications-item.module.scss'

interface Props {
  title: string
  text: string[]
  date: string
  viewed?: boolean
  id: number
}

export function NotificationsItem({
  title,
  date,
  text,
  viewed = false,
  id,
}: Props) {
  return (
    <div
      data-isread={viewed}
      data-id={id}
      className={styles.notifications_item}
    >
      <div className={styles.notifications_item__header}>
        <h4 className={styles.header__title}>{title}</h4>
        <p className={styles.header__date}>
          {dayjs(date).format('DD MMM HH:mm')}
        </p>
      </div>

      <p className={styles.notifications_item__text}>
        {text?.map((text) => text)}
      </p>
      {!viewed && <OrangeEllipse className={styles.orange_ellipse} />}
    </div>
  )
}
