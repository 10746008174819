import { ActionButton, GreenButton } from '../../buttons'
import { addToFavorites, unFavorite } from '@/services/api/favorites'
import { advertImageType, user } from '@/types/Adverts'
import { archiveAdvert, deleteAdvertById } from '@services/api/adverts'
import { setAuthUserOnly, showModalStep } from '@/store/slices/auth'

import Divider from '@mui/material/Divider'
import { ReactComponent as HeartSVG } from '@assets/icons/heart.svg'
import { Link } from 'react-router-dom'
import { ReactComponent as MessageSVG } from '@assets/icons/message.svg'
import Modal from '@/components/modals/Modal/Modal'
import { ReactComponent as PenSVG } from '@assets/icons/pen.svg'
import ProfileShortInfo from '../../ProfileShortInfo'
import { SwitchButtons } from '@/components/pages/MyAnnouncements'
import { UserData } from '@/types/Auth'
import { backendUrl } from '@/config'
import classNames from 'classnames'
import styles from './Card.module.scss'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

// import { useAppSelector } from '@/helpers/hooks'

interface Props {
  id: number
  image?: advertImageType[]
  title: string
  desc: string
  liked?: boolean
  userId: number
  userInfo?: user
  myUserInfo?: UserData | null
  mode?: CardMode
  blockButtons?: boolean | UserData | null
  messageAllow?: boolean
  refreshCards?: () => void
  onFavoriteChange?: () => void
  type?: SwitchButtons
  onCardDelete?: (id: number, archive: boolean) => void
  customUrl?: string
  preview?: boolean
  highlight?: boolean
  topSign?: boolean
}

export type CardMode = 'default' | 'edit'

export const Card = ({
  id,
  image,
  title,
  desc,
  liked,
  userInfo,
  refreshCards,
  userId,
  mode = 'default',
  blockButtons,
  onFavoriteChange,
  myUserInfo,
  messageAllow,
  type,
  onCardDelete,
  customUrl,
  preview = false,
  topSign,
  highlight,
}: Props) => {
  const [isLiked, setIsLiked] = useState(liked)
  const [unauthhorizedModal, setUnauthorizedModal] = useState(false)
  const dispatch = useDispatch()
  const infoClass = classNames({
    [styles.card__info]: true,
    [styles.noImage]: !customUrl && !image?.length && true,
  })

  const likeClass = classNames({
    [styles.card__like]: true,
    [styles.card__liked]: isLiked,
  })

  const cardClass = classNames({
    [styles.cardContainer]: true,
    [styles.card]: true,
    [styles.topCardWithImage]: topSign && image?.length,
    [styles.noImage]: !image?.length,
    [styles.topCardWithoutImage]: topSign && !image?.length,
    [styles.highlightedCard]: highlight,
  })

  const handleUnFavorite = async () => {
    await unFavorite(id)
    setIsLiked(false)
    onFavoriteChange && onFavoriteChange()
  }

  const handleFavorite = async () => {
    await addToFavorites(id)
    setIsLiked(true)
    onFavoriteChange && onFavoriteChange()
  }

  const onDelete = () => {
    if (onCardDelete) {
      return onCardDelete(id, type !== 0)
    }
    if (type === 0) {
      archiveAdvert(id)
    } else {
      deleteAdvertById(id)
    }
    refreshCards && refreshCards()
  }

  console.log('myUserInfo', myUserInfo)

  return (
    <>
      <div className={cardClass}>
        <Link
          onClick={() => {
            !myUserInfo && !preview && dispatch(setAuthUserOnly(true))
          }}
          to={!preview && userInfo ? `/announcements/${id}` : ''}
          state={{ advertId: id }}
          target={id ? '_blank' : ''}
          className={styles.card__imageLink}
        >
          {topSign && image?.length && <div className={styles.TOP}>ТОП</div>}
          {(customUrl || image?.[0]?.name) && (
            <img
              className={styles.card__image}
              src={customUrl || `${backendUrl}files/${image?.[0]?.name}`}
            />
          )}
        </Link>
        <div className={infoClass}>
          <Link
            onClick={() => {
              !myUserInfo && !preview && dispatch(setAuthUserOnly(true))
            }}
            className={styles.card__titleContainer}
            to={id && userInfo ? `/announcements/${id}` : ''}
            state={{ advertId: id }}
            target={id ? '_blank' : ''}
          >
            <h4 className={styles.card__title}>
              {topSign && !image?.length && (
                <div className={styles.TOP}>ТОП</div>
              )}
              {title}
            </h4>
          </Link>
          <p className={styles.card__desc}>{desc}</p>
          {userInfo && (
            <>
              <Divider />
              <ProfileShortInfo
                userId={userId}
                name={userInfo?.firstName}
                age={userInfo?.age}
                rating={userInfo?.average}
                photo={userInfo.avatar}
                phone={userInfo?.phone}
                className={styles.card__profile}
              />
            </>
          )}
          <Divider />
          <div className={styles.card__buttons}>
            {mode === 'default' && (
              <>
                <HeartSVG
                  onClick={() => {
                    !myUserInfo && !blockButtons && setUnauthorizedModal(true)
                    blockButtons &&
                      (isLiked ? handleUnFavorite() : handleFavorite())
                  }}
                  className={likeClass}
                />
                {messageAllow && (
                  <Link
                    className={`${styles.card__link} ${styles.card__link__message_mobile}`}
                    state={{ mode: 'edit', id: id }}
                    to={id && userInfo ? `/profile/messages/${userId}` : ''}
                  >
                    <MessageSVG
                      onClick={() => {
                        !myUserInfo &&
                          !blockButtons &&
                          setUnauthorizedModal(true)
                      }}
                      className={styles.card__messageSVG}
                    />
                  </Link>
                )}
                {messageAllow && (
                  <Link
                    className={`${styles.card__link} ${styles.card__link__message}`}
                    state={{ mode: 'edit', id: id }}
                    to={id && userInfo ? `/profile/messages/${userId}` : ''}
                  >
                    <GreenButton
                      className={styles.card__button}
                      onClick={() => {
                        !myUserInfo &&
                          !blockButtons &&
                          setUnauthorizedModal(true)
                      }}
                    >
                      Написать
                    </GreenButton>
                  </Link>
                )}
              </>
            )}
            {mode === 'edit' && (
              <>
                <ActionButton
                  className={styles.card__extraButtons}
                  types="delete"
                  onClick={onDelete}
                >
                  <p>{type === 0 ? 'В архив' : 'Удалить'}</p>
                </ActionButton>
                <Link
                  className={styles.card__link}
                  state={{ mode: 'edit', id: id }}
                  to={'/profile/announcements/add'}
                >
                  <ActionButton
                    className={styles.card__extraButtons}
                    types="main"
                  >
                    <PenSVG className={styles.card__extraButtons__pen} />
                    <p>Редактировать</p>
                  </ActionButton>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        className={styles.modal}
        open={unauthhorizedModal}
        onClose={() => setUnauthorizedModal(false)}
      >
        <div>
          <span>
            Для взаимодействия с объявлениями на сайте нужно войти в аккаунт или{' '}
            <a
              className={styles.link}
              onClick={() => {
                setUnauthorizedModal(false)
                dispatch(showModalStep(2))
              }}
            >
              зарегистрироваться
            </a>{' '}
            на сайте{' '}
          </span>
        </div>
      </Modal>
    </>
  )
}
