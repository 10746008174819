import { AdminLayout, EveryoneLayout } from '@/components/layouts'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Suspense, lazy, useLayoutEffect } from 'react'

import AdvertModeration from './pages/AdvertModeration'
import Blog from '@/components/pages/Blog'
import { BlogEdit } from './pages/BlogEdit'
import BlogModeration from './pages/BlogModeration'
import Blogs from '@/components/pages/Blogs'
import FeedbackModeration from './pages/FeedbackModeration'
import ThemeModeration from './pages/ThemeModeration'
import { getCurrentUser } from '@/services/api/users'
import { useAppSelector } from '@/helpers/hooks'
import Messages from './pages/Messages'
import Favorites from './pages/Favorites'
import Notifications from './pages/Notifications'
import AddAnnouncement from './pages/AddAnnouncement'
import Announcement from './pages/Announcement'
import MyAnnouncements from './pages/MyAnnouncements'
import Profile from './pages/Profile'
import ProAccount from './pages/ProAccount'
import SettingsNotifications from './pages/Settings/SettingsNotifications'
import SettingsMain from './pages/Settings/SettingsMain'
import SettingsContacts from './pages/Settings/SettingsContacts'
import UsersModeration from './pages/UsersModeration'

const Home = lazy(() => import('@/components/pages/Home'))
// const SettingsMain = lazy(
//   () => import('@/components/pages/Settings/SettingsMain')
// )
// const SettingsContacts = lazy(
//   () => import('@/components/pages/Settings/SettingsContacts')
// )
// const SettingsNotifications = lazy(
//   () => import('@/components/pages/Settings/SettingsNotifications')
// )
// const Announcement = lazy(() => import('@/components/pages/Announcement'))
// const MyAnnouncements = lazy(() => import('@/components/pages/MyAnnouncements'))
// const AddAnnouncement = lazy(() => import('@/components/pages/AddAnnouncement'))

// const Favorites = lazy(() => import('@/components/pages/Favorites'))
// const Notifications = lazy(() => import('@/components/pages/Notifications'))

// const Profile = lazy(() => import('@/components/pages/Profile'))
// const ProAccount = lazy(() => import('@/components/pages/ProAccount'))

// const Messages = lazy(() => import('@/components/pages/Messages'))
const OrderCancellation = lazy(() => import('./pages/OrderCancellation'))
const PrivacyPolicy = lazy(() => import('@/components/pages/PrivacyPolicy'))
const SupportService = lazy(() => import('@/components/pages/SupportService'))
const Hobbies = lazy(() => import('@/components/pages/Hobbies'))
const ComplaintModeration = lazy(
  () => import('@/components/pages/ComplaintModeration')
)
const PublicOffer = lazy(() => import('@/components/pages/PublicOffer'))
function AppRouter() {
  const { isAdmin } = useAppSelector((state) => state.auth)
  useLayoutEffect(() => {
    const uid = localStorage.getItem('uid')
    getCurrentUser(uid)
  }, [])

  return (
    <BrowserRouter>
      <Suspense fallback={<EveryoneLayout />}>
        <Routes>
          {/* Роуты, доступные каждому */}
          <Route path="/" element={<EveryoneLayout />}>
            <Route index element={<Home />} />
            <Route path={'confirm-email/:confirmToken'} element={<Home />} />
            <Route path={'reset-password/:resetToken'} element={<Home />} />
            <Route path="/setting-main" element={<SettingsMain />} />
            <Route path={'/setting-contacts'} element={<SettingsContacts />} />
            <Route
              path={'/setting-notifications'}
              element={<SettingsNotifications />}
            />
            <Route path={'/blog'} element={<Blogs />} />
            <Route path={'/blog/:slug'} element={<Blog />} />
            <Route path="/order-cancellation" element={<OrderCancellation />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/support-service" element={<SupportService />} />
            <Route path="/public-offer" element={<PublicOffer />} />
          </Route>

          {/* Роуты, которые будут доступны только авторизованным */}
          <Route path={'/'} element={<EveryoneLayout withAuth />}>
            <Route path={'profile/:id'} element={<Profile />} />
            <Route path={'pro'} element={<ProAccount />} />
            <Route
              path="profile/:id/announcements/:id2"
              element={<Announcement />}
            />
            <Route
              path={'profile/announcements'}
              element={<MyAnnouncements />}
            />
            <Route path={'announcements/:id'} element={<Announcement />} />

            <Route
              path={'profile/announcements/add'}
              element={<AddAnnouncement />}
            />

            <Route path={'profile/messages'} element={<Messages />} />
            <Route path={'profile/messages/:id'} element={<Messages />} />

            <Route path={'favorites'} element={<Favorites />} />
            <Route path={'notifications'} element={<Notifications />} />
          </Route>

          {/* Админ-панель (отличие в layout-e) */}
          <Route
            path="/admin-panel"
            element={<AdminLayout isAllowed={isAdmin} />}
          >
            <Route
              path="feedback-moderation"
              element={<FeedbackModeration />}
            />
            <Route path="advert-moderation" element={<AdvertModeration />} />
            <Route path="hobbies" element={<Hobbies />} />
            <Route
              path="complaint-moderation"
              element={<ComplaintModeration />}
            />
            <Route path="users-moderation" element={<UsersModeration />} />
            <Route path="blog-moderation" element={<BlogModeration />} />
            <Route path="blog-moderation/add" element={<BlogEdit />} />
            <Route path="theme-moderation" element={<ThemeModeration />} />
          </Route>

          {/*<Route path="/account" element={<LoggedIn />}>*/}
          {/*    <Route index element={<Account />} />*/}
          {/*</Route>*/}
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default AppRouter
