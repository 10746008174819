import React, { memo, useEffect, useId, useRef } from 'react'

import styles from './ymap.module.scss'

interface Props {
  mapGeocode: string
  handleGeoClick?: (city: string, address: string) => void
  className?: string
}

function YMap({ mapGeocode, handleGeoClick, className }: Props) {
  const mapId = useId()
  const yMapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const ymapWindow = window as any
    const { ymaps } = ymapWindow
    ymaps.ready(async function (ymaps: any) {
      const myGeocoder = await ymaps.geocode(mapGeocode)

      const coordinates = myGeocoder.geoObjects.get(0).geometry.getCoordinates()

      const myMap = new ymaps.Map(
        mapId,
        {
          center: coordinates,
          zoom: 18,
        },
        {
          searchControlProvider: 'yandex#search',
        }
      )

      myMap.controls.remove('geolocationControl') // удаляем геолокацию
      myMap.controls.remove('searchControl') // удаляем поиск
      myMap.controls.remove('trafficControl') // удаляем контроль трафика
      myMap.controls.remove('typeSelector') // удаляем тип
      myMap.controls.remove('fullscreenControl') // удаляем кнопку перехода в полноэкранный режим
      myMap.controls.remove('zoomControl') // удаляем контрол зуммирования
      myMap.controls.remove('rulerControl') // удаляем контрол правил */

      if (handleGeoClick) {
        myMap.events.add('click', async (e: any) => {
          const coordinatesEvent = e.get('coords')
          const properties = (await ymaps.geocode(coordinatesEvent)).geoObjects
            .get(0)
            .properties.getAll()
          const address = properties.name
          const city =
            properties.metaDataProperty.GeocoderMetaData.Address.Components.find(
              (component: { kind: string; name: string }) =>
                component.kind == 'locality'
            ).name
          handleGeoClick(city, address)
        })
      }
      myMap.geoObjects.add(
        new ymaps.Placemark(coordinates, {
          preset: 'islands#greenStretchyIcon',
        })
      )
    })

    return () => {
      if (yMapRef && yMapRef.current && yMapRef.current) {
        yMapRef.current.innerHTML = ''
      }
    }
  }, [mapGeocode])

  return (
    <>
      <div
        ref={yMapRef}
        id={mapId}
        className={`${className} ${styles.map}`}
      ></div>
    </>
  )
}

export default memo(YMap)
