import { setAuthLoading, setCurrentUser, setIsAdmin } from '@/store/slices/auth'

import { axiosInstance } from '@/config'
import { store } from '@/store'
import QueryParamsParser from '@/helpers/queryParamsParser/queryParamsParser'
import { getUsersType } from './user/user.types'
import { AxiosError } from 'axios'

export const getUsers: getUsersType = async (params) => {
  const url = QueryParamsParser('users', { ...params })
  try {
    return axiosInstance.get(url)
  } catch (e: unknown) {
    if (e instanceof AxiosError) throw new Error(e.message)
  }
}

export function getUser(id: number) {
  return axiosInstance.get(`/users/${id}`)
}

export function buyProAccount() {
  return axiosInstance.post<{ redirectUrl: string }>(`/payments/pro`)
}

export async function getCurrentUser(id: number | string | null) {
  if (!id) return store.dispatch(setAuthLoading(false))

  store.dispatch(setAuthLoading(true))
  try {
    const currentUser = (await getUser(+id)).data
    store.dispatch(setCurrentUser(currentUser))
    store.dispatch(
      setIsAdmin(currentUser.roleId === 1 || currentUser.roleId === 4)
    )
  } catch (err: any) {
    if (err.response.status == 502) setTimeout(() => getCurrentUser(id), 1500)
  }

  store.dispatch(setAuthLoading(false))
}
