import React, { useEffect, useMemo, useState } from 'react'
import Modal from '@/components/modals/Modal/Modal'
import { ModalProps } from '@mui/material'
import { backendUrl } from '@/config'
import { advertImageType } from '@/types'
import styles from "./photo-slider.module.scss"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css/navigation';
// Import Swiper styles
import 'swiper/css';

interface Props extends Omit<ModalProps, "children"> {
  onClose: () => void
  Closeable?: boolean
  className?: string
  wrapClassName?: string
  images: advertImageType[],
  firstImageId: number
}

function PhotoSliderModal(props: Props) {
  const [images, setImages] = useState<advertImageType[]>([])

  useEffect(()=>{
    if(props.images.length){
      setImages(props.images.map(item=>({...item, name: `${backendUrl}files/${item.name}`})))
    }
  }, [props.images, props.firstImageId])

  console.log(images)

  const firstImageIndex = useMemo(()=>{
    const element = images.findIndex((el)=>el.id === props.firstImageId)
    console.log(element)
    return element
  }, [props.firstImageId])

  if(!props.images.length) return null

  return (
    <Modal {...props}  wrapClassName={styles.modal}>
      <div className={styles.photo_slider}>
        {/*<img src={images[0]} />*/}
        <Swiper
          modules={[Navigation]}
          navigation
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => console.log('slide change')}
          initialSlide={firstImageIndex}
        >
          {images?.map((photo)=>(
            <SwiperSlide key={photo.id}  className={styles.photo_slider__item}>
              <img src={photo.name} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Modal>
  )
}

export default PhotoSliderModal;