import { AdminSider, Header } from '@/components/layout-elements'
import { Outlet, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import styles from '../EveryoneLayout/everyone-layout.module.scss'
import { useAppSelector } from '@/helpers/hooks'

type AdminLayoutProps = {
  isAllowed?: boolean
}

export function AdminLayout({ isAllowed }: AdminLayoutProps) {
  const [title, setTitle] = React.useState('Здесь есть всё')
  const { authLoading, userInfo } = useAppSelector((state) => state.auth)
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Liluf  |  Админ панель'
  }, [title])

  useEffect(() => {
    if (!authLoading && !isAllowed && !userInfo) {
      navigate('/', { replace: true })
    }
  }, [authLoading])

  return (
    <>
      <div className={`container ${styles.layout}`}>
        <div className={styles.layout_content}>
          <div className={styles.leftCol}>
            <AdminSider />
          </div>
          <div className={styles.rightCol}>
            <div>
              <Header title={title} />
              <Outlet context={[setTitle]} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
