import { axiosInstance } from '@/config'
import QueryParamsParser from '@/helpers/queryParamsParser/queryParamsParser'
import { AxiosError } from 'axios'
import {
  deleteUserHobbyType,
  getHobbiesAdminType,
  getHobbiesType,
  createNewHobbyType,
} from './hobbies.types'

export const getHobbies: getHobbiesType = async (name: string) => {
  const url = QueryParamsParser('hobbies', { name })
  try {
    return axiosInstance.get(url)
  } catch (e: unknown) {
    if (e instanceof AxiosError) throw new Error(e.message)
  }
}

export const createNewHobbie = async (newHobby: string) => {
  try {
    return axiosInstance.post(`hobbies`, newHobby)
  } catch (e: unknown) {
    if (e instanceof AxiosError) throw new Error(e.message)
  }
}

export const deleteUserHobby: deleteUserHobbyType = async (hobbyId) => {
  try {
    return axiosInstance.delete(`hobbies/remove-user-hobby/${hobbyId}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) throw new Error(e.message)
  }
}


// only for ADMIN
export const deleteHobbyAdmin: deleteUserHobbyType = async (hobbyId) => {
  try {
    return axiosInstance.delete(`/hobbies/${hobbyId}`)
  } catch (error: unknown) {
    if (error instanceof AxiosError) throw new Error(error.message)
  }
}

export const getHobbiesAdmin: getHobbiesAdminType = async (qParams) => {
  const url = QueryParamsParser('hobbies/all', qParams)
  try {
    return axiosInstance.get(url)
  } catch (error: unknown) {
    if (error instanceof AxiosError) throw new Error(error.message)
  }
}

export const createNewHobby: createNewHobbyType = async (hobbyName) => {
  try {
    return axiosInstance.post('/hobbies', { name: hobbyName })
  } catch (error: unknown) {
    if (error instanceof AxiosError) throw new Error(error.message)
  }
}
