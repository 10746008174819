import React, { useState } from 'react'
import { useAppSelector } from '@helpers/hooks'
import UserAuthed from '@/components/layout-elements/sider/components/UserAuthed'
import NotAuthed from '@/components/layout-elements/sider/components/NotAuthed'
import styles from './sider.module.scss'
import Modal from '@/components/modals/Modal/Modal'
import ChangeUserCity from '@/components/forms/ChangeUserCity'

export function Sider() {
  const { userInfo, isAdmin, isPro } = useAppSelector((state) => state.auth)
  const [openCityModal, setOpenCityModal] = useState(false)
  const changeCityModal = () => {
    setOpenCityModal((prev) => !prev)
  }

  return (
    <>
      <div className={styles.sider}>
        {!userInfo && <NotAuthed />}

        {userInfo && !isAdmin && !isPro && (
          <UserAuthed changeCityModal={changeCityModal} />
        )}
      </div>
      <Modal onClose={changeCityModal} open={openCityModal}>
        <ChangeUserCity onFinish={changeCityModal} />
      </Modal>
    </>
  )
}
