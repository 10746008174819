import { Control, FieldValues } from 'react-hook-form'
import { FormControlProps, Radio as MuiRadio } from '@mui/material'
import React, { AllHTMLAttributes } from 'react'

import styles from './Radio.module.scss'
import { ReactComponent as Checkmark } from '@assets/icons/Checkmark.svg'

interface Props extends FormControlProps {
  name?: string
  label?: React.ReactNode
  control?: Control<FieldValues> | undefined
  defaultValue?: string
  selectsProps?: AllHTMLAttributes<HTMLLabelElement>
  value?: string | number
}

export const Radio = ({ className, value, id }: Props) => {
  return (
    <>
      <div className={`${className} ${styles.container}`}>
        <MuiRadio
          icon={<div className={`checkbox ${styles.checkbox}`} />}
          checkedIcon={
            <div className={`checkbox ${styles.checkbox}`}>
              <Checkmark className={styles.checked} />
            </div>
          }
          value={value}
          id={id}
          className={styles.input}
        />
        <label htmlFor={id} className={styles.label}>
          {id}
        </label>
      </div>
    </>
  )
}
