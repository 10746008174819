import { ActionButton, GreenButton } from '@/components/elements/buttons'
import { OutletContextType, adverts } from '@/types'
import {
  deleteAdvertById,
  getUserAdverts,
  getUserArchivedAdverts,
  getUserModeratedAdverts,
} from '@services/api/adverts'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'

import { CardsList } from '@/components/elements/Cards'
import { ReactComponent as GreenLoadingArrow } from '@assets/icons/green-loading-arrow.svg'
import Modal from '@/components/modals/Modal/Modal'
import { PaidModule } from '../AddAnnouncement/PaidModule/PaidModule'
import styles from './MyAnnouncements.module.scss'
import { useAppSelector } from '@helpers/hooks'
import { useMediaQuery } from '@mui/material'

export enum SwitchButtons {
  ARCHIVE,
  PUBLISHED,
  MODERATED,
}

const title = 'Мои объявления'

const MyAnnouncements = () => {
  const { userInfo } = useAppSelector((state) => state.auth)
  const [setTitle] = useOutletContext<OutletContextType>()
  const navigate = useNavigate()
  const [myAdverts, setMyAdverts] = useState<adverts>([])
  const [selected, setSelected] = useState<SwitchButtons | null>(
    SwitchButtons.PUBLISHED
  )
  const [selectedCardId, setSelectedCardId] = useState<number>(0)
  const [openPaidModal, setOpenPaidModal] = useState(false)

  const [canSeeMore, setCanSeeMore] = useState(false)
  const [page, setPage] = useState<number>(1)

  const DesktopMediaQuery = useMediaQuery('(max-width: 1336px)')
  const LaptopMediaQuery = useMediaQuery('(max-width: 1200px)')
  const LargeTabletMediaQuery = useMediaQuery('(max-width: 958px)')
  const SmallTabletMediaQuery = useMediaQuery('(max-width: 669px)')
  const PhoneMediaQuery = useMediaQuery('(max-width: 600px)')

  const { search } = useLocation()

  useEffect(() => {
    if (search === '?paymentSuccess=true') setOpenPaidModal(true)
  }, [search])

  const onFetchAdverts = async (type: string, refresh?: boolean) => {
    console.log(refresh)
    let response
    if (
      (refresh && selected === SwitchButtons.PUBLISHED) ||
      type === 'published'
    ) {
      response = await getUserAdverts(userInfo?.id as number, {
        page: page,
      })
    } else if (
      (refresh && selected === SwitchButtons.ARCHIVE) ||
      type === 'archived'
    ) {
      response = await getUserArchivedAdverts({ page: page })
    } else if (
      (refresh && selected === SwitchButtons.MODERATED) ||
      type === 'moderated'
    ) {
      response = await getUserModeratedAdverts({ page: page })
    }
    if (!response?.data.adverts.length) {
      setCanSeeMore(false)
      if (refresh) return setMyAdverts([])
      return setMyAdverts((prev) => prev)
    } else {
      setCanSeeMore(true)
    }
    if (
      myAdverts.length >= response.data.total ||
      response?.data.adverts.length < 15
    ) {
      setCanSeeMore(false)
    } else {
      setCanSeeMore(true)
    }
    setMyAdverts(response?.data?.adverts as adverts)
  }

  const onSwitch = (switchType: SwitchButtons) => {
    if (switchType === selected) {
      return onFetchAdverts('', true)
    }
    setMyAdverts([])
    setPage(1)
    setSelected(switchType)
  }

  useLayoutEffect(() => {
    setTitle(title)
  }, [])

  useEffect(() => {
    selected === SwitchButtons.ARCHIVE && onFetchAdverts('archived')
    selected === SwitchButtons.MODERATED && onFetchAdverts('moderated')
    selected === SwitchButtons.PUBLISHED && onFetchAdverts('published')
    console.log(userInfo)
  }, [selected, page, userInfo])

  const onAdd = () => {
    navigate('/profile/announcements/add')
  }

  const showMoreAdverts = () => {
    setPage((prev) => prev + 1)
  }

  const handleModal = (id: number, archive: boolean) => {
    archive && setSelectedCardId(id)
  }

  const handleCardRefresh = () => {
    selected === SwitchButtons.ARCHIVE && onFetchAdverts('archived', true)
    selected === SwitchButtons.MODERATED && onFetchAdverts('moderated', true)
    selected === SwitchButtons.PUBLISHED && onFetchAdverts('published', true)
  }

  const handleDeleteCard = async () => {
    if (selected === SwitchButtons.ARCHIVE) {
      await deleteAdvertById(selectedCardId)
      handleCardRefresh()
    }
    setSelectedCardId(0)
  }

  const closePaidModal = (addNew?: boolean) => {
    if (addNew) {
      navigate('/profile/announcements/add', { state: { reset: true } })
      setOpenPaidModal(false)
    } else {
      setOpenPaidModal(false)
    }
  }

  return (
    <div className={styles.container}>
      <PaidModule open={openPaidModal} onClose={closePaidModal} />
      <Modal
        onClose={() => setSelectedCardId(0)}
        className={styles.modal}
        open={!!selectedCardId}
      >
        <div className={styles.modalContainer}>
          <span>
            После совершения данного действия объявление будет полностью удалено
            с сайта. Желаете продолжить?
          </span>
          <div className={styles.modalButtons}>
            <GreenButton
              className={styles.deleteBtn}
              onClick={() => handleDeleteCard()}
            >
              Да
            </GreenButton>
            <GreenButton onClick={() => setSelectedCardId(0)}>Нет</GreenButton>
          </div>
        </div>
      </Modal>
      <div className={styles.announcementsNav}>
        <ActionButton
          types="switch"
          active={selected === SwitchButtons.PUBLISHED}
          onClick={() => onSwitch(SwitchButtons.PUBLISHED)}
        >
          Опубликованные
        </ActionButton>
        <ActionButton
          types="switch"
          active={selected === SwitchButtons.MODERATED}
          onClick={() => onSwitch(SwitchButtons.MODERATED)}
        >
          Неопубликованные
        </ActionButton>
        <ActionButton
          types="switch"
          active={selected === SwitchButtons.ARCHIVE}
          onClick={() => onSwitch(SwitchButtons.ARCHIVE)}
        >
          Архив
        </ActionButton>

        <ActionButton
          className={styles.addAnnouncement}
          types="add"
          onClick={onAdd}
          sx={{ marginLeft: 'auto' }}
        >
          <p>Добавить объявление</p>
        </ActionButton>
      </div>
      {myAdverts.length > 0 ? (
        <CardsList
          className={styles.cardsContainer}
          cardsList={
            selected === 2
              ? myAdverts.map((item) => ({
                  ...item,
                  serviceColorEndDate: '',
                  serviceTopEndDate: '',
                  serviceTopSignEndDate: '',
                }))
              : myAdverts
          }
          mode="edit"
          columns={
            PhoneMediaQuery
              ? 2
              : SmallTabletMediaQuery
              ? 1
              : LargeTabletMediaQuery
              ? 2
              : LaptopMediaQuery
              ? 3
              : DesktopMediaQuery
              ? 2
              : 3
          }
          refreshCards={handleCardRefresh}
          onDelete={
            selected === SwitchButtons.ARCHIVE
              ? (id: number, archive: boolean) => handleModal(id, archive)
              : undefined
          }
        />
      ) : (
        <span className={styles.noCardsFound}>
          Объявлений в данном статусе не найдено
        </span>
      )}
      {canSeeMore && (
        <button className={styles.see_more_btn} onClick={showMoreAdverts}>
          Загрузить еще <GreenLoadingArrow />
        </button>
      )}
    </div>
  )
}

export default MyAnnouncements
