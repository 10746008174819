import styles from './logoutButton.module.scss'
import { ReactComponent as LogoutSVG } from '@assets/icons/logout.svg'
import { logout } from '@/services/api/auth'
import { AxiosError } from 'axios'
import { setCurrentUser, setIsAdmin } from '@/store/slices/auth'
import Modal from '@/components/modals/Modal/Modal'
import { PropsWithChildren, useState } from 'react'
import { useAppDispatch } from '@/helpers/hooks'
import { useNavigate } from 'react-router-dom'

interface Props extends PropsWithChildren {
  btnStyles?: string | undefined
}

export const LogoutButton = ({ children, btnStyles }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      const response = await logout()
      handleIsOpen()
      dispatch(setCurrentUser(null))
      dispatch(setIsAdmin(false))
      navigate('/')
      return response
    } catch (error: unknown) {
      if (error instanceof AxiosError) console.log(error.message)
    }
  }
  const handleIsOpen = () => setIsOpen(false)
  const handleOpen = () => setIsOpen(true)

  return (
    <>
      <Modal open={isOpen} onClose={handleIsOpen}>
        <div className={styles.modalLogOut}>
          <h4>Выйти из аккаунта?</h4>
          <div>
            <button className={styles.cancel} onClick={() => setIsOpen(false)}>
              Отмена
            </button>
            <button className={styles.logout} onClick={handleLogout}>
              Выйти
            </button>
          </div>
        </div>
      </Modal>
      <button
        type="button"
        onClick={handleOpen}
        className={`${btnStyles} ${styles.button_logout}`}
      >
        {children || (
          <>
            Выйти из аккаунта
            <LogoutSVG />
          </>
        )}
      </button>
    </>
  )
}
