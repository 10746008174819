import { createSlice } from '@reduxjs/toolkit'

export interface NotificationsState {
  unreadMessages: number
  unreadNotifications: number
}

const initialState: NotificationsState = {
  unreadMessages: 0,
  unreadNotifications: 0,
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setUnreadMessages: (state, action) => {
      switch (action.payload.type) {
        case 'remove':
          state.unreadMessages -= 1
          break
        case 'add':
          state.unreadMessages += 1
          break
        case 'reset':
          state.unreadMessages = 0
          break
        case 'customRemove':
          state.unreadMessages -= action.payload.amount
          break
        case 'custom':
          state.unreadMessages = action.payload.ammount
          break
        default:
          state.unreadMessages
          break
      }
    },
    setUnreadNotifications: (state, action) => {
      switch (action.payload.type) {
        case 'remove':
          state.unreadNotifications -= 1
          break
        case 'add':
          state.unreadNotifications += 1
          break
        case 'reset':
          state.unreadNotifications = 0
          break
        case 'custom':
          state.unreadNotifications = action.payload.ammount
          break
        default:
          state.unreadNotifications
          break
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUnreadMessages, setUnreadNotifications } =
  notificationsSlice.actions

export default notificationsSlice.reducer
