import { Control, Controller, FieldValues } from 'react-hook-form'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FormControl, FormControlProps, TextFieldProps } from '@mui/material'
import { uploadUserImage } from '@/services/api/upload/user'

import Button from '@mui/material/Button'
import { ReactComponent as CameraSVG } from '@assets/icons/Camera.svg'
import CircularProgress from '@mui/material/CircularProgress'
import { ImagePreview } from '@/components/elements/ImagePreviews/ImagePreview'
import Modal from '@/components/modals/Modal/Modal'
import { backendUrl } from '@/config'
import classNames from 'classnames'
import styles from './Upload.module.scss'

interface Props extends FormControlProps {
  name: string
  label?: React.ReactNode
  control?: Control<FieldValues> | undefined
  defaultValue?: string
  type: 'advert' | 'avatar' | 'user' | 'none'
  inputProps?: TextFieldProps
  afterUpload?: Dispatch<
    SetStateAction<
      { id: number; image: FormData; src?: string; order?: number }[]
    >
  >
  src?: string
  photoId?: number
  isAvatar?: boolean
  order?: number
  customModal?: () => void
}

export const Upload = ({
  name,
  control,
  type,
  defaultValue,
  id,
  afterUpload,
  src,
  photoId,
  isAvatar,
  customModal,
  ...props
}: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [formatNotSupported, setFormatNotSupported] = useState<boolean>(false)
  const [uploadedFile, setUploadedFile] = useState<File | any>(src)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const buttonClasses = classNames({
    [styles.container]: true,
    [styles.uploaded]: uploadedFile ? true : false,
  })
  useEffect(() => {
    const fileSizeMB: number = +(uploadedFile?.size / 1048576).toFixed(2)
    if (!uploadedFile && type !== 'avatar') {
      setUploadedFile(src)
    } else if (!uploadedFile || fileSizeMB > 10) {
      setUploadedFile(src)
    }
  }, [src, uploadedFile])

  useEffect(() => {
    if (type === 'avatar') {
      setUploadedFile(src)
    }
  }, [src])

  const uploadFile = async (file: File) => {
    const Image = new FormData()
    Image.append('photo', file)
    const megaBytes: number = +(file.size / 1048576).toFixed(2)
    let uploadedImage
    if (type === 'avatar' && megaBytes < 10 && isAvatar) {
      setIsLoading(true)
      /* try {
        if (file) {
          uploadedImage = await uploadUserImage(Image, photoId).then(() => {
            const uid = localStorage.getItem('uid')
            getCurrentUser(uid)
          })
        } else {
          uploadedImage = src
          setUploadedFile(src)
        }
        setIsLoading(false)
      } catch (e) {
        console.error(e)
      } */
      afterUpload &&
        afterUpload((prev) => {
          const idNumber = Number(id)
          prev[idNumber] = { id: idNumber, image: Image }
          return prev
        })
      return uploadedImage
    } else if (type !== 'avatar') {
      if (type === 'advert' && megaBytes > 10) {
        setIsLoading(false)
        setOpen(true)
        setUploadedFile('')
        return
      }
      try {
        if (type === 'user') {
          uploadedImage = await uploadUserImage(Image, photoId)
        }
      } catch (e) {
        console.error(e)
      }
      afterUpload &&
        afterUpload((prev) => {
          const newArr = [...prev]
          const idNumber = Number(id)
          console.log({
            id: idNumber,
            image: Image,
            order: idNumber,
            src: uploadedFile,
          })
          newArr[idNumber] = {
            id: idNumber,
            image: Image,
            order: idNumber,
            src:
              typeof uploadedFile !== 'string'
                ? URL?.createObjectURL(uploadedFile)
                : uploadedFile || '',
          }
          return newArr
        })
      return uploadedImage
    } else if (!isAvatar && megaBytes < 10) {
      /* Image.append('order', String(order))
      setIsLoading(true)
      try {
        uploadedImage = await uploadFirstAvatar(Image)
        const uid = localStorage.getItem('uid')
        getCurrentUser(uid)
        setIsLoading(false)
      } catch (e) {
        console.error(e)
      } */
      afterUpload &&
        afterUpload((prev) => {
          let newImages = [...prev]
          const idNumber = Number(id)
          newImages = newImages.map((image) =>
            image.id == idNumber ? { id: idNumber, image: Image } : image
          )
          console.log(idNumber, Image, newImages)
          return newImages
        })
    } else {
      setIsLoading(false)
      if (customModal) {
        customModal()
      } else {
        setOpen(true)
      }
      setUploadedFile('')
    }
  }

  const onClose = () => {
    setOpen(false)
  }

  const onCloseFormatNotSupported = () => {
    setFormatNotSupported(false)
  }

  return (
    <>
      <FormControl className={'UploadInput'} {...props}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <Button
                className={buttonClasses}
                variant="contained"
                component="label"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="success" />
                ) : uploadedFile ? (
                  <ImagePreview
                    customURL={true}
                    className={styles.uploadedImage}
                    src={
                      typeof uploadedFile !== 'string'
                        ? URL.createObjectURL(uploadedFile)
                        : `${backendUrl}files/${uploadedFile}`
                    }
                  />
                ) : (
                  <CameraSVG className={styles.cameraIcon} />
                )}
                <input
                  onChange={async (event) => {
                    if (!event.target.files && !event?.target?.files?.[0])
                      return
                    const file = event.target.files[0]
                    if (
                      file.type !== 'image/jpeg' &&
                      file.type !== 'image/png' &&
                      file.type !== 'image/heic'
                    )
                      return setFormatNotSupported(true)
                    const uploadedImage = await uploadFile(file)
                    onChange(uploadedImage)
                    setUploadedFile(file)
                  }}
                  onBlur={onBlur}
                  className={styles.uploadInput}
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                />
              </Button>
            )
          }}
        />
      </FormControl>
      <Modal open={formatNotSupported} onClose={onCloseFormatNotSupported}>
        <p>
          Данный формат фото не поддерживается, загрузите изображение в формате
          .jpeg, .jpg, .png, .heic (heif)
        </p>
      </Modal>
      <Modal open={open} onClose={onClose}>
        <p>
          Загружаемое изображение должно быть меньше 10Мб.
          <br />
          Повторите попытку снова
        </p>
      </Modal>
    </>
  )
}
