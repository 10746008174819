import { backendUrl } from '@/config'
import { Box, SxProps } from '@mui/system'
import React from 'react'

import styles from './ImagePreview.module.scss'

interface Props {
  src: string | undefined
  sx?: SxProps | undefined
  className?: string
  customURL?: boolean
  onClick?: (args: any)=>void
}

export const ImagePreview = ({ src, sx, className, customURL, onClick }: Props) => {
  const isWithApi = src?.includes(`${backendUrl}`)
  return (
    <Box sx={sx} className={`${className} ${styles.imagePreview}`}>
      <img
        className={styles.image}
        src={customURL ? `${src}` : isWithApi ? `${src}` : `${backendUrl}files/${src}`}
        onClick={onClick}
        style={{cursor: onClick ? "pointer" : "default"}}
      />
    </Box>
  )
}
