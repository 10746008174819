import Modal from '@/components/modals/Modal/Modal'
import { useState } from 'react'

interface IProps {
  len: number
  children: string
}

const EllipsisText: React.FC<IProps> = ({ len, children = '' }) => {
  const ellipsis = children.length > len
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleClose = () => setIsOpen(false)
  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <p>{children}</p>
      </Modal>
      <p style={{ wordBreak: 'break-word' }}>
        {ellipsis && (
          <>
            {`${children.slice(0, len)}... `}
            <span
              style={{ color: '#1890ff', fontSize: 12, cursor: 'pointer' }}
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              Развернуть
            </span>
          </>
        )}
        {!ellipsis && children}
      </p>
    </>
  )
}

export default EllipsisText
