import { Link, useLocation, useParams } from 'react-router-dom'
import { addToFavorites, unFavorite } from '@/services/api/favorites'
import { getAdvertById, getRecommendedAdverts } from '@/services/api/adverts'
import { useEffect, useLayoutEffect, useState } from 'react'

import { CardsList } from '@/components/elements/Cards'
import { GoBackTitle } from '@/components/elements/GoBackTitle'
import { GreenButton } from '@/components/elements/buttons'
import { ReactComponent as HeartIcon } from '@assets/icons/heart.svg'
import { ImagePreviewsList } from '@/components/elements/ImagePreviews'
import { ReactComponent as MessageSVG } from '@assets/icons/message.svg'
import PhotoSliderModal from '@/components/modals/PhotoSliderModal'
import ProfileShortInfo from '@/components/elements/ProfileShortInfo'
import ShowPhoneNumber from '@/components/pages/Announcement/ui/ShowPhoneNumber'
import YMap from '@/components/elements/YMap'
import { advertsById } from '@/types/Adverts'
import classNames from 'classnames'
import { getUserInfo } from '@/services/api/user'
import styles from './announcements.module.scss'
import { useImageModal } from '@helpers/hooks/useImageModal'
import { userInfoType } from '@/types/user'

function Announcement() {
  const imageList = [
    {
      name: 'https://storge.pic2.me/upload/814/60620d7eec9867.54755502.jpg',
      id: 0,
    },
    {
      name: 'https://storge.pic2.me/upload/814/60620d7eec9867.54755502.jpg',
      id: 3,
    },

    {
      name: 'https://storge.pic2.me/upload/814/60620d7eec9867.54755502.jpg',
      id: 5,
    },
    ,
  ]
  const { id, id2 } = useParams()
  const { state } = useLocation()
  const [open, firstImageId, toggleModal, onImageClick] = useImageModal()

  console.log('id:', id)
  console.log('id2:', id2)

  const userid = Number(id)
  const [adverts, setAdverts] = useState<advertsById | any>({
    adverts: [],
    total: 0,
  })
  const [advertById, setAdvertById] = useState<advertsById | any>()
  const [isLiked, setIsLiked] = useState<boolean | undefined>(
    advertById?.userFavorite
  )
  const [userInfoByID, setUserInfoByID] = useState<userInfoType | any>()
  const fetchUserAdvertsById = async () => {
    const response = await getRecommendedAdverts(+advertById?.id)
    setAdverts({ adverts: response?.data })
  }
  const fetchAdvertById = async () => {
    const response = await getAdvertById(id)
    setAdvertById(response?.data)
  }
  const getUserInfoById = async () => {
    const response = await getUserInfo(advertById?.userId)
    setUserInfoByID(response?.data)
  }

  useEffect(() => {
    setIsLiked(advertById?.userFavorite)
  }, [advertById?.userFavorite])

  useEffect(() => {
    getUserInfoById()
  }, [userid, advertById?.userId])

  useLayoutEffect(() => {
    if (advertById?.userId) fetchUserAdvertsById()
    fetchAdvertById()

    const body = document.querySelector('#root')
    body?.scrollIntoView({
      behavior: 'smooth',
    })
  }, [state?.advertId, advertById?.userId])

  const handleAddToFavorite = async () => {
    await addToFavorites(advertById.id)
    setIsLiked(true)
  }
  const handleRemoveFavoriteAdvert = async () => {
    await unFavorite(advertById.id)
    setIsLiked(false)
  }
  const likeClass = classNames({
    [styles.like]: true,
    [styles.liked]: isLiked,
  })

  const messageBtnClasses = classNames({
    [styles.messagesBlocked]: !advertById?.messageAllow,
  })

  return (
    <div className={styles.announcements}>
      <GoBackTitle route="/" text={'Вернуться в поиск объявлений'} />
      <br />
      <ImagePreviewsList
        imageList={advertById?.photo || imageList}
        onClick={onImageClick}
      />

      <div className={`${styles.page_content} ${!adverts?.adverts.length && styles.page_content__without_bottom_margin}`}>
        <div className={styles.page_content__info}>
          <div className={styles.info__block}>
            <h3 className={styles.infoTitle}>{advertById?.name}</h3>
            <p className={styles.infoDesc}>{advertById?.info}</p>
            {advertById?.budget && (
              <p className={styles.infoBudget}>
                Бюджет: {advertById?.budget} руб.
              </p>
            )}
          </div>

          <div className={styles.info__block}>
            <p className={styles.geolocation}>
              Адрес: {advertById?.cityName}, {advertById?.address}{' '}
            </p>{' '}
            <YMap
              mapGeocode={`${advertById?.cityName} ${advertById?.address}`}
            />{' '}
          </div>
        </div>

        <div className={styles.page_content__contacts}>
          <div
            className={`${styles.contacts__block} ${styles.contacts__profile}`}
          >
            <ProfileShortInfo
              name={advertById?.user?.firstName}
              age={advertById?.user?.age}
              rating={advertById?.user?.average}
              userId={advertById?.userId}
              photo={userInfoByID?.avatar}
            />
          </div>
          <div className={styles.contacts__block}>
            <div className={styles.block__controlls}>
              <Link to={advertById?.messageAllow ? '/profile/messages' : ''}>
                <MessageSVG
                  className={`${styles.messageSVG} ${messageBtnClasses}`}
                />
                <GreenButton className={messageBtnClasses}>
                  Написать
                </GreenButton>
              </Link>

              <div
                onClick={() => {
                  isLiked ? handleRemoveFavoriteAdvert() : handleAddToFavorite()
                }}
                className={styles.contacts__favorite}
              >
                <HeartIcon className={likeClass} />
                <p>В избранное</p>
              </div>
            </div>
            {advertById?.showPhone && (
              <ShowPhoneNumber userId={advertById?.userId} />
            )}
          </div>
        </div>
      </div>

      {!!adverts?.adverts.length && (
        <div className={styles.otherCards}>
          <h3 className={styles.otherCards__title}>Похожие объявления</h3>
          <CardsList sx={{ maxWidth: '950px' }} cardsList={adverts?.adverts} />
        </div>
      )}

      <PhotoSliderModal
        open={open}
        onClose={toggleModal}
        firstImageId={firstImageId}
        images={advertById?.photo || []}
      />
    </div>
  )
}

export default Announcement
