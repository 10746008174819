import {
  changeUserPasswordType,
  getUserInfoType,
  unblockUserType,
  updateUserCityType,
  userContactsSettingsType,
  userInfoUpdateByIdType,
  getUserPhoneNumberType,
} from './user.types'
import { axiosInstance } from '@/config/index'
import { AxiosError } from 'axios'
import { getCurrentUser } from '@services/api/users'

export const getUserInfo: getUserInfoType = async (userId: number) => {
  try {
    return axiosInstance.get(`users/${userId}`)
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const getUserPhoneNumber: getUserPhoneNumberType = async (userId) => {
  try {
    return axiosInstance.get(`show-phone/${userId}`)
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const userInfoUpdateById: userInfoUpdateByIdType = async (
  userId,
  params
) => {
  try {
    return axiosInstance.patch(`users/${userId}`, { ...params })
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const userContactsSettings: userContactsSettingsType = async (
  userId,
  params
) => {
  try {
    return axiosInstance.patch(`users/settings/${userId}`, { ...params })
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const updateUserCity: updateUserCityType = async ({
  userId,
  cityName,
  cityKladrId,
}) => {
  try {
    const setNewCity = await axiosInstance.patch(`users/city/${userId}`, {
      cityName,
      cityKladrId,
    })
    await getCurrentUser(userId)

    return setNewCity
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const changeUserPassword: changeUserPasswordType = async (params) => {
  try {
    return axiosInstance.post(`users/change-password`, { ...params })
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const unblockUser: unblockUserType = async (id) => {
  try {
    return axiosInstance.delete(`user-blocks/${id}`)
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}
