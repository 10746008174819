import {
  Autocomplete,
  FormControl,
  FormControlProps,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { Control, Controller, FieldValues } from 'react-hook-form'

import InputLabel from '@mui/material/InputLabel'
import { ReactComponent as SearchSVG } from '@assets/icons/Search.svg'
import styles from './Search.module.scss'

interface Props extends FormControlProps {
  name: string
  label?: string
  placeholder?: string
  control?: Control<FieldValues> | undefined
  defaultValue?: string
  options: Array<string | number>
  inputProps?: TextFieldProps
  OnTextChange?: (value: string) => void
}

export const SearchInput = ({
  name,
  control,
  defaultValue,
  options,
  label,
  placeholder,
  className,
  OnTextChange,
  ...props
}: Props) => {
  return (
    <div className={`${className} ${styles.container} autoComplete-container`}>
      {label && <InputLabel className={styles.label}>{label}:</InputLabel>}
      <FormControl {...props}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value, ...rest } }) => (
            <Autocomplete
              onChange={(e, value) => onChange(value)}
              value={value ? value : ''}
              onBlur={onBlur}
              className={styles.w}
              id="search-select"
              freeSolo
              disableClearable
              options={options}
              {...rest}
              defaultValue={defaultValue}
              renderInput={(params) => (
                <>
                  <TextField
                    onChange={(e) =>
                      OnTextChange && OnTextChange(e.target.value)
                    }
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: placeholder || label,
                      defaultValue: defaultValue
                    }}
                    defaultValue={defaultValue}
                  />
                  <SearchSVG className={'icon'} />
                </>
              )}
            />
          )}
        ></Controller>
      </FormControl>
    </div>
  )
}
