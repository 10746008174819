import { axiosInstance } from '@/config';
import { AxiosError } from 'axios';

import { getMessagesType, getUserDialogsType, joinToDialogType } from './dialogs.types';

export const getUserDialogs: getUserDialogsType = async () => {
  try {
    return axiosInstance.get(`dialogue/rooms`)
  } catch (error: unknown) {
    if (error instanceof AxiosError) throw new Error(error.message)
  }
}

export const joinToDialog: joinToDialogType = async (userId) => {
  try {
    return axiosInstance.get(`dialogue/room`, { params: { userId } })
  } catch (error: unknown) {
    if (error instanceof AxiosError) throw new Error(error.message)
  }
}

export const getMessages: getMessagesType = async (roomId, limit, offset) => {
  try {
    return axiosInstance.get(
      `dialogue/messages/room/${roomId}?limit=${limit}&offset=${offset}`
    )
  } catch (error: unknown) {
    if (error instanceof AxiosError) throw new Error(error.message)
  }
}
