import { FieldValues, useForm } from 'react-hook-form'
import React, { useState } from 'react'

import ConfirmMail from './ConfirmMail'
import FormError from '@/components/forms/elements/FormError/FormError'
import { GreenButton } from '@/components/elements/buttons'
import { TextInput } from '@/components/forms/elements/inputs/TextInput'
import { signup } from '@/services/api/auth'
import styles from '../auth-form.module.scss'
import { trimField } from '@/helpers/form/trimField'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface Props {
  stepFunc: (step: number) => void
}

export function RegisterForm({ stepFunc }: Props) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    getValues,
  } = useForm()
  const [confirmEmail, setConfirmEmail] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const submitHandler = async (data: FieldValues) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('login')

    console.log(token.length)

    try {
      if (data.passwordd !== data.confirm_passwordd) {
        return setError('confirm_passwordd', {
          type: 'custom',
          message: 'Пароль в обоих полях должен совпадать',
        })
      }

      await signup({
        email: data.email as string,
        password: data.passwordd as string,
        recaptchaValue: token,
      })
      setConfirmEmail(true)
    } catch (error: any) {
      console.log(error.message)
      if (error.message === 'string.email') {
        setError('email', {
          type: 'custom',
          message: 'Введён невалидный email',
        })
      }
      if (error.message === 'USER_ALREADY_EXIST') {
        setError('confirm_passwordd', {
          type: 'custom',
          message: 'Аккаунт с такими данными уже существует',
        })
      }
      setConfirmEmail(false)
    }
  }

  if (confirmEmail) {
    return <ConfirmMail mail={getValues('email')} />
  }

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
        <h3 className={styles.form_title}>Регистрация</h3>

        <TextInput
          control={control}
          className={styles.form_input}
          {...register('email', {
            required: 'Обязательное поле',
            ...trimField({ name: 'email', setValue }),
          })}
          inputProps={{ type: 'email', placeholder: 'Email' }}
        />

        <FormError errors={errors} name="email" />

        <TextInput
          control={control}
          className={`${styles.form_input} ${styles.form_pass_input}`}
          {...register('passwordd', {
            required: 'Обязательное поле',
            minLength: {
              value: 6,
              message: 'минимальная длина пароля - 6 символов',
            },
            maxLength: {
              value: 40,
              message: 'максимальная длина пароля - 40 символов',
            },
            ...trimField({ name: 'passwordd', setValue }),
          })}
          inputProps={{
            placeholder: 'Пароль',
            autoComplete: 'off',
          }}
        />

        <FormError errors={errors} name="passwordd" />

        <TextInput
          control={control}
          className={`${styles.form_input} ${styles.form_pass_input}`}
          {...register('confirm_passwordd', {
            required: 'Обязательное поле',
            minLength: {
              value: 6,
              message: 'минимальная длина пароля - 6 символов',
            },
            maxLength: {
              value: 40,
              message: 'максимальная длина пароля - 40 символов',
            },
            ...trimField({ name: 'confirm_passwordd', setValue }),
          })}
          inputProps={{
            placeholder: 'Повторите пароль',
            autoComplete: 'off',
          }}
        />

        <FormError errors={errors} name="confirm_passwordd" />
        <GreenButton className={styles.form_btn} type={'submit'}>
          Регистрация
        </GreenButton>
        <div className={styles.controls}>
          <p className={styles.controls__item}>
            Есть аккаунт?{' '}
            <span className={styles.green} onClick={() => stepFunc(0)}>
              Вход
            </span>
          </p>
        </div>
      </form>
    </>
  )
}
