import { UserData } from '@/types/Auth'
import { createSlice } from '@reduxjs/toolkit'

export interface AuthState {
  authLoading: boolean
  userInfo: UserData | null
  isAdmin: boolean
  isPro: boolean
  modalStep: number
  authUserOnly: boolean
}

const initialState: AuthState = {
  authLoading: true,
  userInfo: null,
  isAdmin: false,
  isPro: false,
  modalStep: 0,
  authUserOnly: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    showModalStep: (state, action) => {
      state.modalStep = action.payload
    },
    setAuthLoading: (state, action) => {
      state.authLoading = action.payload
    },
    setCurrentUser: (state, action) => {
      state.userInfo = action.payload
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload
    },
    setAuthUserOnly: (state, action) => {
      state.authUserOnly = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setCurrentUser,
  setAuthLoading,
  showModalStep,
  setAuthUserOnly,
  setIsAdmin,
} = authSlice.actions

export default authSlice.reducer
