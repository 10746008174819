import { store } from '@/store'
import { getReviews } from './reviews'
import { setUserAverage, setUserReviews } from '@/store/slices/reviews'

export const getUserReviews = async (userId: number) => {
  const response = await getReviews(userId)
  const { average } = response!.data.averageRating
  const reviews = response!.data.reviews

  store.dispatch(setUserReviews(reviews))
  store.dispatch(setUserAverage(average))
}
