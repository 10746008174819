import { moderationType } from '@/services/api/moderations/moderations.types'
import { createSlice } from '@reduxjs/toolkit'

export interface ModerationState {
  allModeration: moderationType
}

const initialState: ModerationState = {
  allModeration: {
    items: [],
    total: 0,
  },
}

export const moderationSlice = createSlice({
  name: 'moderation',
  initialState,
  reducers: {
    setAllModerations: (state, action) => {
      state.allModeration = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAllModerations } = moderationSlice.actions
export default moderationSlice.reducer
