import { getCategoriesType, getSubCategoriesType } from './categories.types'

import { AxiosError } from 'axios'
import { axiosInstance } from '@/config'

export const getCategories: getCategoriesType = async () => {
  try {
    return await axiosInstance.get('/categories')
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const getSubCategories: getSubCategoriesType = async (params) => {
  try {
    return await axiosInstance.get('/subcategories', { params })
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}
