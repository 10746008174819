import {
  BlogNews,
  BlogTheme,
  GetBlogPostsByThemeType,
  GetBlogThemesType,
} from '@services/api/blog'
import {
  GetBlogPostsByIdType,
  addBlogNewsByIdType,
  deleteBlogNewsByIdType,
  updateBlogNewsByIdType,
} from '@services/api/blog/blog.types'

import { AxiosError } from 'axios'
import QueryParamsParser from '@/helpers/queryParamsParser/queryParamsParser'
import { axiosInstance } from '@/config'

export * from '@services/api/blog/blog.types'

export const getBlogThemes: GetBlogThemesType = async () => {
  try {
    const themes = await axiosInstance.get<{
      total: number
      items: BlogTheme[]
    }>('/blog/themes')
    return themes
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      if (e.code === 'ERR_BAD_REQUEST') {
        throw new Error(e!.response!.data.errors[0].type)
      }
      throw new Error(e!.response!.data.message)
    }
  }
}

export const getBlogNewsByTheme: GetBlogPostsByThemeType = async (params) => {
  const url = QueryParamsParser('blog/news', {
    ...params,
    page: params.page,
  })

  try {
    const news = await axiosInstance.get<{ total: number; items: BlogNews[] }>(
      url
    )
    return news
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      if (e.code === 'ERR_BAD_REQUEST') {
        throw new Error(e!.response!.data.errors[0].type)
      }
      throw new Error(e!.response!.data.message)
    }
  }
}

export const getBlogNewsById: GetBlogPostsByIdType = async (id) => {
  try {
    const blog = axiosInstance.get(`/blog/news/${id}`)
    return blog
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      if (e.code === 'ERR_BAD_REQUEST') {
        throw new Error(e!.response!.data.errors[0].type)
      }
      throw new Error(e!.response!.data.message)
    }
  }
}

export const deleteBlogNewsById: deleteBlogNewsByIdType = async (id) => {
  try {
    const news = await axiosInstance.delete(`/blog/news/${id}`)
    return news
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      if (e.code === 'ERR_BAD_REQUEST') {
        throw new Error(e!.response!.data.errors[0].type)
      }
      throw new Error(e!.response!.data.message)
    }
  }
}

export const addBlogNewsById: addBlogNewsByIdType = async (params) => {
  try {
    const news = await axiosInstance.post(`/blog/news/`, params)
    return news
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      if (e.code === 'ERR_BAD_REQUEST') {
        throw new Error(e!.response!.data.errors[0].type)
      }
      throw new Error(e!.response!.data.message)
    }
  }
}

export const updateBlogNewsById: updateBlogNewsByIdType = async (
  id,
  params
) => {
  try {
    const news = await axiosInstance.put(`/blog/news/${id}`, params)
    return news
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      if (e.code === 'ERR_BAD_REQUEST') {
        throw new Error(e!.response!.data.errors[0].type)
      }
      throw new Error(e!.response!.data.message)
    }
  }
}
