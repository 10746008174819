import { LeaveAReview, ReportComplaint } from '@/components/forms'
import { addFavoriteUser, unFavoriteUser } from '@/services/api/favorites'
import { userInfoType } from '@/types/user'
import { ReactComponent as HeartSVG } from '@assets/icons/heart.svg'
import { ReactComponent as LocationSVG } from '@assets/icons/location_pin.svg'
import { ReactComponent as MessageSVG } from '@assets/icons/message.svg'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { GreenButton, InfoButton } from '../buttons'
import { Reviews } from '../Reviews'
import styles from './ProfileLongInfo.module.scss'
import { getUserReviews } from '@/services/api/review'
import { useAppSelector } from '@/helpers/hooks'
import Modal from '@/components/modals/Modal/Modal'
import { AxiosError } from 'axios'

interface Props {
  userInfo: userInfoType
  userid: number
  className?: string
  forbidden?: boolean
}

export const ProfileLongInfo = ({
  userInfo,
  userid,
  className,
  forbidden,
}: Props) => {
  const [openLeaveAReview, setOpenLeaveAReview] = useState(false)
  const [openReviews, setOpenReviews] = useState(false)
  const [openComplaint, setOpenComplaint] = useState(false)
  const auth = useAppSelector((state) => state.auth)
  const { userReviews } = useAppSelector((state) => state.reviews)
  const { userAverageRating } = useAppSelector((state) => state.reviews)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const [isLiked, setIsLiked] = useState(
    userInfo?.isFavorite && userInfo.isFavorite
  )

  const toggleReviews = () => {
    setOpenReviews((prev) => (prev ? false : true))
  }

  const toggleLeaveAReview = () => {
    if (openReviews) setOpenReviews(false)

    setOpenLeaveAReview((prev) => (prev ? false : true))
  }

  const toggleComplaint = () => {
    setOpenComplaint((prev) => (prev ? false : true))
  }

  // склонения для возраста. Например: 54 года, 38 лет или 41 год
  const formatter = new Intl.NumberFormat('ru', {
    style: 'unit',
    unit: 'year',
    unitDisplay: 'long',
  })

  // склонения для слова "отзыв". Например: 23 отзыва, 118 отзывов или 41 отзыв
  function reviewsValidate(numb: number | undefined) {
    const number = Number(numb)
    const n = number % 10
    if (number === 0) {
      return 'отзывов'
    }
    if (number >= 11 && number <= 20) {
      return 'отзывов'
    }
    if (n === 1) {
      return 'отзыв'
    }
    if (n >= 2 && n <= 4) {
      return 'отзыва'
    }
    if ((n >= 5 && n <= 9) || n === 0) {
      return 'отзывов'
    }
  }

  useEffect(() => {
    if (!forbidden) getUserReviews(userid)
  }, [userid, forbidden])

  useEffect(() => {
    setIsLiked(userInfo?.isFavorite)
  }, [userInfo?.isFavorite])

  const likeClass = classNames({
    [styles.like]: true,
    [styles.liked]: isLiked,
  })

  const handleAddToFavorite = async () => {
    try {
      await addFavoriteUser(userid)
      setIsLiked(true)
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setIsOpen(true)
        switch (error.response?.data.message) {
          case 'USER_CANNOT_BE_YOU':
            setModalMessage('Нельзя добавить свой профиль в избранное')
            break
          default:
            setModalMessage('Нельзя добавить более 5 пользователей в избранное')
            break
        }
      }
    }
  }
  const handleUnFavoriteUser = async () => {
    await unFavoriteUser(userid)
    setIsLiked(false)
  }

  const handleClose = () => setIsOpen(false)

  useEffect(() => {
    setIsLiked(userInfo?.isFavorite && userInfo.isFavorite)
  }, [userInfo?.isFavorite])

  return (
    <div className={`${styles.container} ${className}`}>
      <Modal open={isOpen} onClose={handleClose}>
        <p>{modalMessage}</p>
      </Modal>
      <LeaveAReview open={openLeaveAReview} onClose={toggleLeaveAReview} />
      <Reviews
        userid={userid}
        open={openReviews}
        onClose={toggleReviews}
        forbidden={forbidden}
        average={userAverageRating}
      />
      <ReportComplaint
        open={openComplaint}
        onClose={toggleComplaint}
        id={userid}
        userInfo={userInfo}
      />
      <div className={styles.details}>
        <h4>
          {!forbidden &&
            `${userInfo?.firstName} ${userInfo?.lastName},
          ${userInfo && formatter.format(userInfo?.age)}`}
        </h4>
        <p className={styles.location}>
          {!forbidden && (
            <>
              <LocationSVG />
              {userInfo?.cityName}
            </>
          )}
        </p>
      </div>
      <div className={styles.about}>
        <Link
          className={styles.noDecoration}
          to={`/profile/messages/${userid}`}
        >
          <MessageSVG className={styles.messageSVG} />
          <GreenButton className={styles.button}>Написать</GreenButton>
        </Link>
        <HeartSVG
          className={likeClass}
          onClick={() => {
            isLiked ? handleUnFavoriteUser() : handleAddToFavorite()
          }}
        />
        <div className={styles.reviews}>
          <p
            className={`${styles.green} ${styles.bold}`}
            onClick={() => toggleReviews()}
          >
            {userAverageRating || '0.0'}{' '}
            <span
              className={`${styles.allReviews} ${styles.light} ${styles.green}`}
            >
              ({userReviews.length} {reviewsValidate(userReviews.length)})
            </span>
          </p>
          {auth.userInfo && auth.userInfo.id !== +userid && (
            <InfoButton
              className={styles.complaintsButton}
              onClick={() => toggleComplaint()}
              text={'Пожаловаться на пользователя'}
            />
          )}
        </div>
      </div>
    </div>
  )
}
