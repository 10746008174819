import { dialogMessageEntity, dialogType } from '@/types/Dialogs'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { DialogsList } from './ui/DialogsList'
import { MessageWindow } from './ui/MessageWindow/index'
import { OutletContextType } from '@/types'
import { getUserDialogs } from '@services/api/dialogs'
import { socket } from '@/config'
import styles from './Messages.module.scss'
import { useAppSelector } from '@/helpers/hooks'

function Messages() {
  const [setTitle] = useOutletContext<OutletContextType>()
  const { id } = useParams()
  const { userInfo } = useAppSelector((state) => state.auth)
  const [dialogs, setDialogs] = useState<
    Map<number, dialogType[]> | Map<number, unknown>
  >(new Map([[0, {}]]))
  useEffect(() => {
    setTitle('Сообщения')
  }, [])
  const [dialogsUpdate, setDialogsUpdate] = useState(false)
  const [refetchDialogs, setRefetchDialogs] = useState(true)

  useEffect(() => {
    if (!refetchDialogs) return
    async function getDialogs() {
      try {
        const res = (await getUserDialogs())?.data
        console.log(res, refetchDialogs, dialogs)
        res &&
          setDialogs(new Map(new Map(res.map((dialog) => [dialog.id, dialog]))))
      } catch (e) {
        console.log(e)
      }
      setRefetchDialogs(false)
    }
    getDialogs()
  }, [id, userInfo, refetchDialogs])

  useEffect(() => {
    socket.on('notification', (entity: dialogMessageEntity) => {
      setDialogs((prev) => {
        const dialogs = new Map(prev) as Map<number, dialogType>
        const selectedDialogId = Number(entity?.info?.roomId) || 0
        const selectedDialog = dialogs.get(selectedDialogId) as dialogType
        const unreadMessagesCount = Number(entity?.info?.count?.unreadRoom) || 0
        const { createdAt, id, isRead, message, sender } = entity?.info

        dialogs.set(selectedDialogId, {
          ...selectedDialog,
          unreadMessagesCount,
          messages: [
            {
              createdAt,
              id,
              isRead,
              sender,
              messages: message,
            },
          ],
        })
        return dialogs
      })
      setDialogsUpdate((previousUpdate) => (previousUpdate ? false : true))
    })
  }, [userInfo, id])

  useEffect(() => {
    console.log(dialogsUpdate)
  }, [dialogsUpdate])

  return (
    <section className={styles.wrapper}>
      <DialogsList
        id={id}
        dialogsAmount={dialogs.size}
        inDialogWithUser={!!id}
        userId={userInfo?.id}
        selectedDialog={id}
        rooms={dialogs}
        dialogsUpdated={dialogsUpdate}
      />
      <MessageWindow
        setDialogs={setDialogs}
        dialogsAmount={dialogs.size}
        id={id}
        setDialogsUpdate={setDialogsUpdate}
        setRefetchDialogs={setRefetchDialogs}
      />
    </section>
  )
}

export default Messages
