import { ReactComponent as InfoSVG } from '@assets/icons/info.svg'
import React from 'react'

import styles from './InfoButton.module.scss'

interface Props {
  onClick?: () => void
  text: string
  className?: string
}

export const InfoButton = ({ onClick, text, className }: Props) => {
  return (
    <p
      className={`${className} ${styles.infoButton}`}
      onClick={() => onClick && onClick()}
    >
      <InfoSVG />
      <span className="textInfo">{text}</span>
    </p>
  )
}
