import { suggestions } from '@/types/getCity'
import { createSlice } from '@reduxjs/toolkit'

interface initialStateType {
  cities: suggestions
}

const filterCities = (cities: suggestions) => {
  const onlyCities = cities.filter(
    (suggestion) => Number(suggestion.data.fias_level) <= 4
  )

  const filterOnlyCitites = onlyCities.filter(({ value }) => {
    return (
      !value.includes('шоссе') &&
      !value.includes('р-н') &&
      !value.includes('поселок') &&
      !value.includes('метро')
    )
  })
  return filterOnlyCitites
}

const initialState: initialStateType = {
  cities: [
    {
      value: '',
      unrestricted_value: '',
      data: {
        kladr_id: '',
        city: '',
        region: '',
        region_type_full: '',
        fias_level: '',
      },
    },
  ],
}

export const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setAllCities: (state, action) => {
      state.cities = filterCities(action.payload)
    },
  },
})

export const { setAllCities } = citiesSlice.actions
export default citiesSlice.reducer
