import {
  BlogNews,
  BlogTheme,
  getBlogNewsById,
  getBlogNewsByTheme,
  getBlogThemes,
} from '@services/api/blog'
import { useEffect, useState } from 'react'

import DOMPurify from 'dompurify'
import { blog } from '@/types/blog'

interface Props {
  blogId?: number | string
}

const useBlogData = ({ blogId }: Props) => {
  const [blogNews, setBlogItems] = useState<BlogNews[]>([])
  const [blog, setBlog] = useState<blog>({
    createdAt: '',
    title: '',
    id: 0,
    image: '',
    text: '',
    themes: [],
    views: 0,
    updatedAt: '',
  })
  const [themes, setThemes] = useState<BlogTheme[]>([])
  const [currTheme, setCurrTheme] = useState(1)
  const [canSeeMore, setCanSeeMore] = useState(true)

  const [page, setPage] = useState(1)

  const getData = async () => {
    try {
      if (blogId) {
        const blogById = (await getBlogNewsById(blogId))?.data
        blogById &&
          setBlog({ ...blogById, text: DOMPurify.sanitize(blogById.text) })
      } else {
        const blogRes = await getBlogNewsByTheme({
          themeId: currTheme,
          page: page,
          limit: 10,
        })
        const blogItems = blogRes?.data.items

        if (!themes.length) {
          const themeRes = await getBlogThemes()
          setThemes(themeRes?.data.items || [])
        }
        if (!blogItems?.length) {
          setCanSeeMore(false)
          return []
        }
        if (
          (blogRes && blogNews.length >= blogRes?.data.total) ||
          blogItems.length < 10
        ) {
          setCanSeeMore(false)
        }
        if (page === 1) {
          return setBlogItems(blogItems || [])
        }

        return setBlogItems((prev) => [...prev, ...(blogItems || [])])
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getData()
  }, [page, currTheme])

  const changePage = (num?: number) => {
    if (num) {
      return setPage(num)
    } else {
      return setPage((prev) => prev + 1)
    }
  }

  const changeCurrTheme = (themeID: number) => {
    setCurrTheme(themeID)
    setPage(1)
  }

  function addIdToTags(htmlString: string) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const tags = doc.querySelectorAll('*')

    tags.forEach((tag: any) => {
      const contentWords = tag.textContent.trim().split(' ')
      const firstThreeWords = contentWords
        .slice(0, 3)
        .map((word: string) => word.toLowerCase())
      const combinedWords = firstThreeWords.join('_')
      tag.setAttribute('id', combinedWords)
    })

    return doc.body.innerHTML
  }

  const modifiedHtml = addIdToTags(blog.text)

  return {
    blogNews,
    themes,
    currTheme,
    changePage,
    changeCurrTheme,
    blog,
    canSeeMore,
    modifiedHtml,
  }
}

export default useBlogData
