import React, { useState } from 'react'
import Modal from '@/components/modals/Modal/Modal'
import { ReactComponent as PhoneIcon } from '@assets/icons/phone.svg'
import { ReactComponent as CopyIcon } from '@assets/icons/copy.svg'
import { GreenButton } from '@/components/elements/buttons'

import styles from './show-phone-number.module.scss'
import { AxiosError } from 'axios'
import { getUserPhoneNumber } from '@/services/api/user'

interface Props {
  userId: number
}

function ShowPhoneNumber({ userId }: Props) {
  const [open, setOpen] = useState(false)
  const [phoneNum, setPhoneNum] = useState<string>('')
  const [successCopy, setSuccessCopy] = useState<string>('')
  const [accessToShow, setAccessToShow] = useState<string>('')

  const changeModalOpen = async () => {
    try {
      const response = await getUserPhoneNumber(userId)
      const { phone } = response?.data
      console.log("phone", response?.data)
      setPhoneNum(phone)

      setOpen((prev) => !prev)
      setAccessToShow('')
      if (!open) setSuccessCopy('')
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setOpen((prev) => !prev)
        switch (error?.response?.data.message) {
          case 'VIEW_LIMIT':
            setAccessToShow(
              'Вы превысили ограничение по просмотру номера телефона'
            )
            break
          case 'NOT_FOUND_PHONE':
            setAccessToShow('Номер телефона не найден')
            break
          case 'FORBIDDEN':
            setAccessToShow('Номер телефона не найден')
            break
        }
      }
    }
  }

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe)
      setSuccessCopy('Скопировано')
    } catch (err) {
      setSuccessCopy('Не скопировалось. Повторите попытку')
    }
  }

  return (
    <>
      <GreenButton className={styles.block__phone} onClick={changeModalOpen}>
        <PhoneIcon />
        <p>
          <span className={styles.isPhoneScreen}>Показать </span>номер телефона
        </p>
      </GreenButton>

      <Modal onClose={changeModalOpen} open={open}>
        {!accessToShow ? (
          <>
            <div className={styles.modal_content}>
              <h2 className={styles.modal_content__title}>{phoneNum}</h2>
              <span
                className={styles.modal_content__copy}
                onClick={() => copyToClipBoard(phoneNum)}
              >
                {successCopy || 'Скопировать'} <CopyIcon />
              </span>
            </div>
          </>
        ) : (
          <p>{accessToShow}</p>
        )}
      </Modal>
    </>
  )
}

export default ShowPhoneNumber
