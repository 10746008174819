import { hobbiesType } from '@/types/Hobbies'
import { Tag } from '../Tag'
import styles from './Tags.module.scss'

interface Props {
  tagsList: Array<{ id: number; name: string; enabled?: boolean }>
  handleTagClick?: (hobby: hobbiesType) => void
  handleTagDelete?: (hobbyId: number) => void
}

export const TagsList = ({
  tagsList,
  handleTagClick,
  handleTagDelete,
}: Props) => {
  return (
    <div className={styles.tagsContainer}>
      {tagsList?.map(
        (tag, index) =>
          tag && (
            <Tag
              key={tag?.id || index}
              label={tag?.name || ''}
              enabled={tag.enabled}
              handleClick={() => {
                !tag.enabled && handleTagClick && handleTagClick(tag)
              }}
              handleDelete={() => {
                tag.enabled && handleTagDelete && handleTagDelete(tag.id)
              }}
            />
          )
      )}
    </div>
  )
}
