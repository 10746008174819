import { ButtonProps, CircularProgress } from '@mui/material'

import { LoadingButton } from '@mui/lab'
import classNames from 'classnames'
import styles from './green-button.module.scss'

// import { LoadingButton } from "@mui/lab"

interface Props extends ButtonProps {
  loading?: boolean
}

export const GreenButton = ({
  loading,
  className,
  children,
  onClick,
  ...props
}: Props) => {
  const btnClass = classNames(
    {
      [styles.green_button]: !loading && true,
      [styles.green_button_loading]: loading && true,
    },
    className
  )

  return (
    <LoadingButton
      {...props}
      onClick={loading ? () => ({}) : onClick}
      className={btnClass}
    >
      {loading ? (
        <CircularProgress style={{ height: 21, width: 21 }} color="success" />
      ) : (
        children
      )}
    </LoadingButton>
  )
}
