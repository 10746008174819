import React, { useEffect } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import DOMPurify from 'dompurify'
import { GoBackTitle } from '@/components/elements/GoBackTitle'
import { OutletContextType } from '@/types'
import parse from 'html-react-parser'
import styles from './Blogs.module.scss'
import useBlogData from '../Blogs/hooks/useBlogData'

const Blog = () => {
  const [setTitle] = useOutletContext<OutletContextType>()

  const { id, slug } = useParams()
  const { blog, modifiedHtml } = useBlogData({ blogId: slug || Number(id) })

  useEffect(() => {
    setTitle('Здесь есть всё')
  }, [])

  return (
    <>
      <div className={styles.container}>
        <GoBackTitle route="/blog" text="Вернуться" />
        <article className={styles.blog}>
          <h1>{blog.title}</h1>
          <div>{parse(DOMPurify.sanitize(modifiedHtml))}</div>
        </article>
      </div>
    </>
  )
}

export default Blog
