import { setAllCities } from '@/store/slices/cities'
import { getCity, getCityByKladrId } from './getCity'
import { store } from '@/store'

export const getAllCities = async (city: string) => {
  const response = await getCity(city)
  store.dispatch(setAllCities(response?.suggestions))
}

export const getAllCitiesByKladrId = async (kladrId: string) => {
  const response = (await getCityByKladrId(kladrId))?.suggestions
  store.dispatch(setAllCities(response))

}


