import { ActionButton, GreenButton } from '@/components/elements/buttons'
import { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { SubscriptionType } from '@/types/subscription'
import { TextInput } from '@/components/forms/elements/inputs'
import styles from './SubscriptionForm.module.scss'
import { useForm } from 'react-hook-form'
import Modal from '@/components/modals/Modal/Modal'

interface Props {
  subscription: SubscriptionType
  deleteSubscription: (subscriptionId: number) => void
}

export const SubscriptionForm = ({
  subscription,
  deleteSubscription,
}: Props) => {
  const [filters, setFilters] = useState<any>()
  const { control, register, reset } = useForm()
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (subscription) {
      const budget = `${
        subscription.budgetMin && subscription.budgetMax
          ? `${subscription.budgetMin}-${subscription.budgetMax}`
          : subscription.budgetMin
          ? subscription.budgetMin
          : subscription.budgetMax || ''
      }`
      const age =
        subscription.ageMin && subscription.ageMax
          ? `${subscription.ageMin}-${subscription.ageMax}`
          : subscription.ageMin
          ? `${subscription.ageMin}+`
          : subscription.ageMax
          ? `${subscription.ageMax}`
          : ``

      reset({
        ...subscription,
        category: subscription?.category?.name,
        subCategory: subscription?.subcategory?.name,
        age: age,
        budget: budget,
        gender: subscription?.gender
          ? subscription?.gender === 'f'
            ? 'Ж'
            : 'М'
          : null,
        photo: subscription?.withPhoto ? 'Да' : 'Нет',
      })
    }
  }, [subscription])

  useEffect(() => {
    const newObject: {
      [key: string]: string | number | boolean
    } = {
      category: subscription?.subcategory?.categoryId,
      cityName: subscription?.kladrName,
      kladrId: subscription?.kladrId,
      subcategoryId: subscription?.subcategoryId,
      gender: subscription?.gender,
      age: `${subscription?.ageMin}-${subscription?.ageMax}`,
      budgetFrom: `${subscription?.budgetMin}`,
      budgetTo: `${subscription?.budgetMax}`,
      photo: subscription?.withPhoto,
      maritalStatus: subscription?.maritalStatus,
      subscription: true,
    }
    for (const key in newObject) {
      if (!newObject[key]) {
        delete newObject[key]
      }
    }
    setFilters(newObject)
  }, [subscription])

  const handleCloseConfirm = () => setConfirmModalOpen((prev) => !prev)

  return (
    <>
      <div className={styles.container}>
        <Modal open={confirmModalOpen} onClose={handleCloseConfirm}>
          <>
            <div className={styles.confirmModal}>
              <p>Вы уверены, что хотите удалить набор фильтров?</p>
              <div className={styles.btns}>
                <GreenButton
                  className={styles.deleteBtn}
                  onClick={() => {
                    deleteSubscription(subscription?.id)
                    handleCloseConfirm()
                  }}
                >
                  Да
                </GreenButton>
                <GreenButton onClick={handleCloseConfirm}>Нет</GreenButton>
              </div>
            </div>
          </>
        </Modal>
        <div className={styles.inputs}>
          <TextInput
            label="Категория"
            control={control}
            placeholder="Категория"
            className={styles.input}
            {...register('category')}
            disabled
            inputProps={{ disabled: true }}
          />
          <TextInput
            label="Подкатегория"
            control={control}
            className={styles.input}
            placeholder={'Подкатегория'}
            {...register('subCategory')}
            inputProps={{ disabled: true }}
          />
          <TextInput
            label="Город"
            control={control}
            className={styles.input}
            placeholder={'Город'}
            {...register('kladrName')}
            inputProps={{ disabled: true }}
          />
          <TextInput
            label="Возраст"
            control={control}
            className={styles.input}
            placeholder={'Возраст'}
            {...register('age')}
            inputProps={{ disabled: true }}
          />
          <TextInput
            label="Пол"
            control={control}
            className={styles.input}
            placeholder={'Пол'}
            {...register('gender')}
            inputProps={{ disabled: true }}
          />
          <TextInput
            label="Семейное положение"
            control={control}
            className={styles.input}
            placeholder={'Семейное положение'}
            {...register('maritalStatus')}
            inputProps={{ disabled: true }}
          />
          <TextInput
            label="Бюджет"
            control={control}
            className={styles.input}
            placeholder={'Бюджет'}
            {...register('budget')}
            inputProps={{ disabled: true }}
          />
          <TextInput
            label="Только с фото"
            control={control}
            className={styles.input}
            placeholder={'Только с фото'}
            {...register('photo')}
            inputProps={{ disabled: true }}
          />
        </div>
        <div className={styles.actions}>
          <ActionButton
            onClick={() => setConfirmModalOpen(true)}
            types="delete"
          >
            Удалить
          </ActionButton>
          <GreenButton>
            <Link
              to={'/?isSubsribed=true'}
              state={{ ...filters }}
              style={{
                textDecoration: 'none',
                color: 'white',
              }}
            >
              Посмотреть объявления
            </Link>
          </GreenButton>
        </div>
      </div>
    </>
  )
}
