import './styles/global.scss'

import App from './App'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Provider } from 'react-redux'
import React from 'react'
import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { store } from './store'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}`}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <App />
    </GoogleReCaptchaProvider>
  </Provider>
  // </React.StrictMode>
)

reportWebVitals()
