import { Control, Controller, FieldValues } from 'react-hook-form'
import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  SelectProps,
} from '@mui/material'

import { ReactComponent as Arrow } from '@assets/icons/Arrow.svg'
import { MenuStyle } from './Select.style'
import MuiSelect from '@mui/material/Select'
import React, { useState } from 'react'
import styles from './Select.module.scss'

interface Props extends FormControlProps {
  name: string
  label?: React.ReactNode
  control?: Control<FieldValues> | undefined
  defaultValue?: string | number
  items: Array<{ id: number | string; value: string } | string>
  inputProps?: SelectProps
}

export const Select = ({
  name,
  control,
  label,
  className,
  placeholder,
  items,
  defaultValue,
  inputProps,
  ...props
}: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div className={`${className} ${styles.container} select-container`}>
      {label && <InputLabel className={styles.label}>{label}:</InputLabel>}
      <FormControl {...props}>
        {placeholder && (
          <InputLabel className={styles.label_fs14}>{placeholder}</InputLabel>
        )}
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue ? defaultValue : null}
          render={({ field: { onChange, onBlur, value } }) => (
            <MuiSelect
              className={styles.select}
              onClick={(e) =>
                e.target instanceof HTMLElement &&
                e.target?.dataset.value == value &&
                onChange(null)
              }
              onBlur={onBlur}
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
              label={placeholder}
              value={value || ''}
              IconComponent={() => (
                <Arrow
                  className={'arrow'}
                  width="15"
                  height={'15'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpen((prev) => !prev)}
                />
              )}
              MenuProps={{ sx: MenuStyle }}
              onChange={(e, child) => onChange(e, child)}
              {...inputProps}
            >
              {items.map((item, index) => (
                <MenuItem
                  key={`${item}_${index}`}
                  value={typeof item !== 'string' ? item.id : item}
                >
                  {typeof item !== 'string' ? item.value : item}
                  <div className={'circle'} />
                </MenuItem>
              ))}
            </MuiSelect>
          )}
        ></Controller>
      </FormControl>
    </div>
  )
}
