import Modal from '@/components/modals/Modal/Modal'
import { useAppDispatch, useAppSelector } from '@/helpers/hooks'
import { setAuthUserOnly, showModalStep } from '@/store/slices/auth'

import styles from './AuthorizedUsersOnlyModal.module.scss'

export const AuthorizedUsersOnlyModal = () => {
  const { authUserOnly } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  const closeModal = () => {
    dispatch(setAuthUserOnly(false))
  }

  return (
    <Modal
      className={styles.modal}
      open={authUserOnly}
      onClose={() => closeModal()}
    >
      <div>
        <span>
          Просмотр объявлений доступен только авторизованным пользователям.
          Пожалуйста, войдите в свой аккаунт или{' '}
          <a
            className={styles.link}
            onClick={() => {
              closeModal()
              dispatch(showModalStep(2))
            }}
          >
            зарегистрируйтесь
          </a>{' '}
          на сайте для продолжения просмотра{' '}
        </span>
      </div>
    </Modal>
  )
}
