import { InfoButton } from '@/components/elements/buttons'
import { CardsList } from '@/components/elements/Cards'
import { GoBackTitle } from '@/components/elements/GoBackTitle'
import { ImagePreviewsList } from '@/components/elements/ImagePreviews'
import { ProfileLongInfo } from '@/components/elements/ProfileLongInfo'
import { Reviews } from '@/components/elements/Reviews'
import { TagsList } from '@/components/elements/Tags'
import Modal from '@/components/modals/Modal/Modal'
import { getUserInfo } from '@/services/api/user'
import { advertsById } from '@/types/Adverts'
import { userInfoType } from '@/types/user'
import { getUserAdverts } from '@services/api/adverts'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import styles from './Profile.module.scss'
import { useImageModal } from '@helpers/hooks/useImageModal'
import PhotoSliderModal from '@/components/modals/PhotoSliderModal'

function Profile() {
  const [openTutorial, setOpenTutorial] = useState(false)
  const [openReviews, setOpenReviews] = useState(false)
  const [forbidden, setForbidden] = useState<boolean>(false)
  const [adverts, setAdverts] = useState<advertsById | any>({
    adverts: [],
    total: 0,
  })
  const [userInfo, setUserInfo] = useState<userInfoType | any>()
  const [open, firstImageId, toggleModal, onImageClick] = useImageModal()
  const { id } = useParams()
  const userId = Number(id)

  const toggleReviews = () => {
    setOpenReviews((prev) => (prev ? false : true))
  }

  const fetchUserAdvertsById = async () => {
    const response = await getUserAdverts(userId)
    setAdverts(response?.data)
  }

  const getUserInfoById = async () => {
    const response = await getUserInfo(userId)
    setUserInfo(response?.data)
  }

  useEffect(() => {
    fetchUserAdvertsById()
    getUserInfoById()
  }, [id, userId])

  useEffect(() => {
    setForbidden(userInfo?.forbidden)
  }, [userInfo?.forbidden])

  const ToggleTutorial = () => {
    setOpenTutorial((prev) => (prev ? false : true))
  }

  return (
    <div className={styles.container}>
      <Modal open={openTutorial} onClose={ToggleTutorial}>
        <div className={styles.modalWrap}>
          <span className={styles.modalText}>
            Добавить интересы и предпочтения Вы можете на странице
            <Link className={styles.modalLink} to="/setting-main">
              {' '}
              настройки{' '}
            </Link>
            вашего аккаунта!
          </span>
        </div>
      </Modal>

      <Reviews
        userid={userId}
        open={openReviews}
        onClose={toggleReviews}
        forbidden={forbidden}
      />
      <GoBackTitle
        className={styles.goBackTitle}
        route="/"
        text={'Вернуться в поиск объявлений'}
      />
      <ImagePreviewsList
        imageSx={{ width: '312px', height: '208px' }}
        imageList={!forbidden ? userInfo?.photo : []}
        onClick={onImageClick}
      />
      <ProfileLongInfo
        userInfo={userInfo}
        userid={userId}
        forbidden={forbidden}
      />
      <main className={styles.mainContainer}>
        <ul className={styles.infoContainer}>
          <li className={styles.infoDetail}>
            <p className={styles.infoTitle}>Семейное положение:</p>
            <p className={styles.infoStatus}>
              {forbidden ? '—' : userInfo?.maritalStatus || '—'}
            </p>
          </li>
          <li className={styles.infoDetail}>
            <p className={styles.infoTitle}>Дети:</p>
            <p className={styles.infoStatus}>
              {forbidden ? '—' : userInfo?.children ? 'Есть' : 'Нет'}
            </p>
          </li>
          <li className={styles.infoDetail}>
            <p className={styles.infoTitle}>Образование:</p>
            <p className={styles.infoStatus}>
              {forbidden ? '—' : userInfo?.education || '—'}
            </p>
          </li>
          <li className={styles.infoDetail}>
            <p className={styles.infoTitle}>Вредные привычки:</p>
            <p className={styles.infoStatus}>
              {forbidden ? '—' : userInfo?.addiction ? 'Да' : 'Нет'}
            </p>
          </li>
        </ul>
        <aside className={styles.tagsContainer}>
          <div className={styles.tagsContainer__list}>
            <TagsList tagsList={forbidden ? [] : userInfo?.hobbyUser} />
          </div>
          <InfoButton
            className={styles.infoButton}
            onClick={() => ToggleTutorial()}
            text={'Как добавить интересы?'}
          />
        </aside>
      </main>
      <div className={styles.otherCards}>
        <h3>Объявления пользователя</h3>
        <CardsList
          sx={{ maxWidth: '950px' }}
          cardsList={forbidden ? [] : adverts?.adverts}
        />
      </div>

      <PhotoSliderModal open={open} onClose={toggleModal} firstImageId={firstImageId} images={!forbidden ? (userInfo?.photo || []) : []} />
    </div>
  )
}

export default Profile
