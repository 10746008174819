import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import {
  FormControl,
  FormControlProps,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  TextFieldProps,
} from '@mui/material'
import React, { useState } from 'react'

import { ReactComponent as Visibility } from '@assets/icons/eye.svg'
import classNames from 'classnames'
import styles from './text-input.module.scss'

interface Props extends FormControlProps {
  name: string
  label?: React.ReactNode
  control?: Control<FieldValues> | undefined
  defaultValue?: string
  rules?: RegisterOptions
  inputProps?: TextFieldProps
  onTextChange?: (value: any) => any
}

export const TextInput = React.forwardRef(
  (
    {
      name,
      control,
      defaultValue,
      inputProps,
      label,
      rules,
      onTextChange,
      ...props
    }: Props,
    ref
  ) => {
    const [inputType, setInputType] = useState(
      inputProps && inputProps.type ? inputProps.type : 'text'
    )
    const textFieldClasses = classNames(
      {
        [styles.textField]: true,
      },
      props.className
    )

    function showPassword() {
      if (inputType !== 'text') {
        setInputType('text')
      } else {
        setInputType('password')
      }
    }

    return (
      <div className={`${styles.container} inputContainer`}>
        {label && <InputLabel className={styles.label}>{label}:</InputLabel>}
        <FormControl {...props}>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextField
                  {...inputProps}
                  className={textFieldClasses}
                  onChange={(e) =>
                    onChange(onTextChange ? onTextChange(e.target.value) : e)
                  }
                  value={value || ''}
                  onBlur={onBlur}
                  inputRef={ref}
                  InputProps={{
                    ref: ref,
                    type: inputType,
                    className: styles.input,
                    endAdornment: inputProps &&
                      inputProps.type === 'password' && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={showPassword}
                          >
                            <Visibility />
                          </IconButton>
                        </InputAdornment>
                      ),
                  }}
                />
              )
            }}
          />
        </FormControl>
      </div>
    )
  }
)
