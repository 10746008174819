import React from 'react'
import styles from '../auth-form.module.scss'

interface Props {
  mail?: string
  text?: string
  title: string
}
//
//
function ForgotPasswordText({ mail, title, text }: Props) {
  return (
    <div className={styles.form}>
      <h3 className={styles.form_title}>{title}</h3>
      {text && (
        <p className={`${styles.form__text} ${!mail && styles.form__text_m0}`}>
          {text}
        </p>
      )}
      <div className={`${styles.form__text} ${styles.form__text_m0}`}>
        {mail && <span className={styles.green}>{mail}</span>}
      </div>
    </div>
  )
}

export default ForgotPasswordText
