import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'

import auth from './slices/auth'
import hobbies from './slices/hobbies'
import moderation from './slices/moderations'
import notifications from './slices/notifications'
import cities from './slices/cities'
import complaints from './slices/complaints'
import reviews from './slices/reviews'
import subscription from './slices/subscription'
import users from './slices/users'

export const store = configureStore({
  reducer: {
    auth,
    hobbies,
    moderation,
    notifications,
    cities,
    complaints,
    reviews,
    subscription,
    users,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
