import 'react-quill/dist/quill.snow.css'

import { Controller, useForm } from 'react-hook-form'
import React, { useEffect, useRef, useState } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import {
  addBlogNewsById,
  getBlogNewsById,
  updateBlogNewsById,
} from '@/services/api/blog'
import { useLocation, useNavigate } from 'react-router-dom'

import { Autocomplete } from '@mui/lab'
import { AxiosError } from 'axios'
import FormError from '@/components/forms/elements/FormError/FormError'
import { GoBackTitle } from '@/components/elements/GoBackTitle'
import { GreenButton } from '@/components/elements/buttons'
import ReactQuill, { Quill } from 'react-quill'
import { TextInput } from '@/components/forms/elements/inputs'
import { Upload } from '@/components/forms/elements/uploads'
import { advertImageType } from '@/types'
import { backendUrl } from '@/config'
import { getThemes } from '@/services/api/themes'
import styles from './BlogEdit.module.scss'
import { themeType } from '@/types/theme'
import { uploadFile } from '@/services/api/upload/file'

export const BlogEdit = () => {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm()
  const { search, state } = useLocation()
  const blogId = Number(search?.split('=')?.[1])
  const [themes, setThemes] = useState<themeType[]>([])
  const [defaultImage, setDefaultImage] = useState<advertImageType>({
    id: 0,
    name: '',
  })
  console.log(state)

  const navigate = useNavigate()
  const [images, setImages] = useState<{ id: number; image: FormData }[]>([])
  function replaceNewlinesWithBr(input: string): string {
    return input.replace(/\n/g, '<br />')
  }

  useEffect(() => {
    const getAllThemes = async () => {
      const allThemes = (await getThemes({ page: 1, limit: 50 }))?.data.items
      allThemes && setThemes(allThemes)
    }
    getAllThemes()

    const getBlogData = async () => {
      let blogById
      if (state) {
        blogById = (await getBlogNewsById(state))?.data
      }
      if (blogById) {
        setDefaultImage({ id: 0, name: blogById.image })
        reset({
          image: blogById.image,
          text: replaceNewlinesWithBr(blogById.text),
          themes: blogById.themes,
          title: blogById.title,
        })
      }
    }
    getBlogData()
  }, [])

  const handleAddBlog = async (data: any) => {
    try {
      if (images[0]?.image) {
        const image = images[0]?.image.get('photo') || ''
        const blogImage = new FormData()
        blogImage.append('file', image)
        const uploadedImage = (await uploadFile(blogImage))?.data
        data.image = uploadedImage.photo.filename || data.image
      }

      data.themes = data.themes.map((theme: themeType) => theme.id)

      if (blogId) {
        await updateBlogNewsById(blogId, data)
      } else {
        await addBlogNewsById({ ...data })
      }
      navigate('/admin-panel/blog-moderation')
    } catch (err) {
      switch (
        (err as AxiosError<{ err: { code: string } }>)?.response?.data?.err
          ?.code
      ) {
        case 'LIMIT_UNEXPECTED_FILE':
          return setError('image', { type: 'custom', message: 'лимит 2мб' })
        default:
          break
      }
    }
  }

  const reactQuillRef: any = useRef(null)
  const imageHandler = async () => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.setAttribute('multiple', 'multiple')
    input.click()
    input.onchange = async () => {
      if (!input.files) return
      Array.from(input.files).forEach((item) => {
        const formData = new FormData()
        formData.append('file', item)
        uploadFile(formData).then((data) => {
          const fileURL = `${backendUrl}files/${data?.data.photo.filename}`
          const quill = reactQuillRef?.current?.getEditor()
          const cursorPosition = quill.getSelection().index
          quill.insertEmbed(cursorPosition, 'image', fileURL)
          quill.setSelection(cursorPosition + 1)
        })
      })
    }
  }

  const Size = Quill.import('attributors/style/size')
  Size.whitelist = ['10px', '12px', '14px', '16px', '20px', '24px', '36px']
  Quill.register(Size, true)

  const SizeStyle = Quill.import('attributors/style/size')
  Quill.register(SizeStyle, true)

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          [{ size: ['10px', '12px', '14px', '16px', '20px', '24px', '36px'] }],
          [
            // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            { header: [1, 2, 3, 4, 5, 6, false] },
          ],
          ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image'],
          [{ color: [] }, { background: [] }, { align: [] }],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      keyboard: {
        bindings: {
          shiftEnter: {
            key: 13, // Enter key code
            shiftKey: true,
            handler: function (range: any, context: any) {
              const [line, offset] = context.getLine(range.index)
              const lineText = line?.domNode.textContent || ''

              // Check if the cursor is at the end of a list item
              if (lineText.trim() === '' && offset === lineText.length) {
                // Insert an empty line after the list item
                context.insertText(range.index, '\n', 'user')
                context.setSelection(range.index + 1, 'silent')
                context.scrollIntoView()
              } else {
                // Insert a line break within the list item
                context.insertText(range.index, '\n', 'user')
                context.setSelection(range.index + 1, 'silent')
                context.scrollIntoView()
              }
            },
          },
        },
      },
    }),
    []
  )

  useEffect(() => {
    const pickerItems = document.querySelectorAll(
      '.ql-snow .ql-picker.ql-size .ql-picker-item'
    )

    pickerItems.forEach((item: any) => {
      const fontSize = item.getAttribute('data-value')
      if (fontSize) {
        item.style.setProperty('--before-content', `"${fontSize}"`)
        item.style.setProperty('--before-font-size', `${fontSize}`)
      }
    })
  }, [modules])

  return (
    <div className={styles.container}>
      <GoBackTitle route="/admin-panel/blog-moderation" text="Вернуться" />
      <form
        className={styles.formContainer}
        onSubmit={handleSubmit(handleAddBlog)}
      >
        <Upload
          className={styles.blogImage}
          key={`${defaultImage.id}-${defaultImage.name}`}
          id={`${defaultImage.id}`}
          src={defaultImage.name || ''}
          afterUpload={setImages}
          type="none"
          name="image"
          control={control}
        />
        <FormError errors={errors} name="image" />
        <h3>Заголовок</h3>
        <TextInput
          className={styles.titleInput}
          name="title"
          rules={{ required: { value: true, message: 'Обязательное поле' } }}
          control={control}
        />
        <FormError errors={errors} name="title" />

        <h3>Описание</h3>
        <Controller
          name={'text'}
          control={control}
          defaultValue={''}
          rules={{
            required: { value: true, message: 'w' },
            validate: (value) => !!value.replace(/<\/?[^>]+(>|$)/g, ''),
          }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <ReactQuill
                ref={reactQuillRef}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className={styles.rte}
                theme="snow"
                scrollingContainer="html"
                modules={modules}
                formats={[
                  'header',
                  'bold',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                  'image',
                  'size',
                  'align',
                ]}
              />
            )
          }}
        />
        <FormError errors={errors} name="text" />
        <Controller
          name={'themes'}
          control={control}
          defaultValue={''}
          rules={{ required: { value: true, message: 'Обязательное поле' } }}
          render={({ field: { onChange, onBlur, value } }) => {
            const fallbackValue = value ? value : []
            return (
              <Autocomplete
                multiple
                className={styles.autoComplete}
                options={themes}
                value={fallbackValue}
                onChange={(e, newValue) => {
                  onChange(newValue)
                }}
                onBlur={onBlur}
                getOptionLabel={(option: themeType) => option.name}
                defaultValue={themes}
                renderInput={(
                  params: JSX.IntrinsicAttributes & TextFieldProps
                ) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Multiple values"
                    placeholder="Favorites"
                  />
                )}
              />
            )
          }}
        />
        <FormError errors={errors} name="themes" />
        <GreenButton type="submit" className={styles.submitButton}>
          Отправить
        </GreenButton>
      </form>
    </div>
  )
}
