import { Box, SxProps } from '@mui/material'
import { ImagePreview } from '../ImagePreview'

interface Props {
  imageList: { name: string; id: number }[]
  sx?: SxProps
  imageSx?: SxProps
  onClick?: (args: any) => void
}

export const ImagePreviewsList = ({
  imageList,
  sx,
  imageSx,
  onClick,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        borderRadius: '20px',
        overflow: 'hidden',
        width: '100%',
        maxWidth: '920px',
        ...sx,
      }}
    >
      {imageList?.map((image, index) => (
        <ImagePreview
          key={index}
          sx={imageSx}
          src={image.name}
          onClick={() => (onClick ? onClick(image.id) : null)}
        />
      ))}
    </Box>
  )
}
