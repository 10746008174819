import { Chip, TextFieldProps } from '@mui/material'
import React, { KeyboardEvent, useMemo } from 'react'

import { TagsList } from '@/components/elements/Tags'
import { hobbiesType } from '@/types/Hobbies'
import styles from './InputTags.module.scss'
import { useAppSelector } from '@/helpers/hooks'

interface Props {
  name: string
  label?: string
  inputProps?: TextFieldProps
  OnTextChange: (value: string) => void
  handleTagClick: (hobby: hobbiesType) => void
  handleTagDelete: (hobbyId?: number) => void
  tags: Array<hobbiesType> | []
  value: string
  values: Array<hobbiesType> | []
  enabledHobbies: hobbiesType[]
}

export const InputTags = ({
  tags,
  values,
  label,
  value,
  handleTagClick,
  OnTextChange,
  handleTagDelete,
  enabledHobbies,
  ...props
}: Props) => {
  const { allHobbies } = useAppSelector((state) => state.hobbies)
  const nonEnabledTags = useMemo(
    () =>
      tags.filter(
        (tag) => !enabledHobbies?.find((value) => value.id === tag.id)
      ),
    [enabledHobbies, tags]
  )

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Backspace' && value.length === 0) {
      return handleTagDelete()
    }
    if (event.code === 'Enter' && value.length > 0 && allHobbies.length === 0)
      return handleTagClick(allHobbies[0])
  }

  return (
    <div className={styles.inputContainer}>
      <div className={styles.tagInputContainer}>
        {enabledHobbies?.map((value) => (
          <Chip
            className={styles.inputTag}
            label={value.name}
            key={value.name}
          />
        ))}
        <div className={styles.textInputContainer}>
          <input
            multiple
            onKeyDown={handleKeyDown}
            onChange={(e) => OnTextChange(e.target.value)}
            className={styles.textInput}
            value={value}
            {...props}
            placeholder={!values?.length ? label : label}
          />
        </div>
      </div>
      {!!enabledHobbies.length && (
        <TagsList
          handleTagDelete={handleTagDelete}
          handleTagClick={handleTagClick}
          tagsList={enabledHobbies}
        />
      )}
      <TagsList
        handleTagDelete={handleTagDelete}
        handleTagClick={handleTagClick}
        tagsList={nonEnabledTags}
      />
    </div>
  )
}
