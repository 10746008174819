import { Box, useMediaQuery } from '@mui/material'
import { Card, CardMode } from '..'

import Masonry from '@mui/lab/Masonry'
import { SxProps } from '@mui/material/styles'
import { advertsById } from '@/types/Adverts'
import { useAppSelector } from '@/helpers/hooks'

interface Props {
  cardsList: Array<advertsById>
  sx?: SxProps
  MasonrySX?: SxProps
  spacing?: number
  className?: string
  columns?: number
  mode?: CardMode
  blockButtons?: boolean
  refreshCards?: () => void
  onFavoriteChange?: () => void
  onDelete?: (id: number, archive: boolean) => void
}

export const CardsList = ({
  cardsList,
  sx,
  MasonrySX,
  spacing,
  className,
  columns,
  mode,
  blockButtons = false,
  refreshCards,
  onFavoriteChange,
  onDelete,
}: Props) => {
  const { userInfo } = useAppSelector((state) => state.auth)
  const LaptopSizeScreen = !columns && useMediaQuery('(max-width: 984px)')
  const TabletSizeScreen = !columns && useMediaQuery('(max-width: 670px)')
  const PhoneSizeScreen = !columns && useMediaQuery('(max-width: 600px)')

  return (
    <Box sx={sx} className={className}>
      <Masonry
        defaultColumns={3}
        columns={
          columns
            ? columns
            : PhoneSizeScreen
            ? 2
            : TabletSizeScreen
            ? 1
            : LaptopSizeScreen
            ? 2
            : 3
        }
        sx={MasonrySX}
        spacing={spacing ? spacing : 2.5}
      >
        {cardsList?.map((card) => (
          <div className={'card'} key={card.id}>

            <Card
              refreshCards={refreshCards}
              userId={card.userId}
              id={card.id}
              title={`${card.name}`}
              desc={card.info}
              liked={card.liked || card.userFavorite}
              image={card.photo}
              userInfo={card.user}
              myUserInfo={userInfo}
              mode={mode}
              messageAllow={card.messageAllow && userInfo?.id !== card.userId}
              onFavoriteChange={onFavoriteChange}
              blockButtons={blockButtons || userInfo}
              type={card.archive}
              onCardDelete={onDelete}
              highlight={!!card.serviceColorEndDate}
              topSign={!!card.serviceTopSignEndDate}
            />
          </div>
        ))}
      </Masonry>
    </Box>
  )
}
