import React, { useEffect, useState } from 'react'
import { DatePickerField } from '@/components/forms/elements/date-pickers'
import { GreenButton } from '@/components/elements/buttons'
import { GreenSelect } from '@/components/forms/elements/selects/GreenSelect/GreenSelect'
import { InputTags } from '@/components/forms/elements/inputs/'
import { TextInput } from '@/components/forms/elements/inputs'
import { Upload } from '@/components/forms/elements/uploads'
import settingsStyles from '../Settings.module.scss'
import styles from './SettingsMain.module.scss'
import { useForm } from 'react-hook-form'
import { useAppSelector } from '@/helpers/hooks'
import { userInfoUpdateById } from '@/services/api/user'
import { getAllHobbies } from '@/services/api/hobbies'
import { hobbiesType } from '@/types/Hobbies'
import Modal from '@/components/modals/Modal/Modal'
import success from '@assets/images/success_buble.png'
import SettingsContacts from '../SettingsContacts'
import SettingsNotifications from '../SettingsNotifications'
import { useOutletContext } from 'react-router-dom'
import { OutletContextType } from '@/types'
import dayjs from 'dayjs'
import { getCurrentUser } from '@/services/api/users'
import { Button, Tooltip, useMediaQuery } from '@mui/material'
import FormError from '@/components/forms/elements/FormError/FormError'
import {
  deleteUserImage,
  setUserAvatar,
  uploadFirstAvatar,
} from '@/services/api/upload/user'

const mockGenders = ['Мужской', 'Женский']

const mockEducation = [
  'Основное общее',
  'Среднее общее',
  'Среднее профессиональное',
  'Высшее бакалавриат',
  'Высшее специалитет',
  'Высшее магистратура',
  'Высшее аспирантура',
  'Высшее ординатура',
]

const mockMaritalStatus = [
  'Женат',
  'Не женат',
  'Замужем',
  'Не замужем',
  'Всё сложно',
  'В активном поиске',
]

const mockYesNo = ['Да', 'Нет']

export type userAvatar = {
  id: number
  image: FormData
  order?: number
  src?: string
  photoId?: number
  isMainAvatar?: boolean
}

interface defaultValuesType {
  firstName: string
  lastName: string
  gender: string | undefined | null
  birthdate: string | undefined
  maritalStatus: string
  children: 'Есть' | 'Нет' | null
  addictions: 'Да' | 'Нет' | null
  education: string | null
}

function SettingsMain() {
  const [setTitle] = useOutletContext<OutletContextType>()
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm()
  const [tag, setTag] = useState<string>('')
  const { userInfo } = useAppSelector((state) => state.auth)
  const { allHobbies } = useAppSelector((state) => state.hobbies)
  const [hobbies, setHobbies] = useState<hobbiesType[] | []>([])
  const [enabledHobbies, setEnabledHobbies] = useState<hobbiesType[] | []>([])
  const [open, setIsOpen] = useState<boolean>(false)
  const [tooltipOpen, setTooltipOpen] = useState<any>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [over20MB, setOver20MB] = useState<boolean>(false)
  const [images, setImages] = useState<userAvatar[]>([
    { id: 1, image: new FormData() },
    { id: 2, image: new FormData() },
    { id: 3, image: new FormData() },
  ])
  const [loading, setLoading] = useState(false)
  const birthdate = watch('birthdate')
  const isAddictions = watch('addictions')
  const isChildren = watch('children')
  const isGender = watch('gender')
  const isMaritalStatus = watch('maritalStatus')
  const isEducation = watch('education')
  const isFirstName = watch('firstName')
  const isLastName = watch('lastName')
  const isBirthDate = watch('birthdate')

  const PhoneSizeScreen = useMediaQuery('(max-width: 600px)')
  const uid = localStorage.getItem('uid')

  useEffect(() => {
    const currentYear = dayjs(dayjs().format('YYYY-MM-DD'))
    const userDate = dayjs(birthdate).format('YYYY-MM-DD')
    const yearsDifference = currentYear.diff(userDate, 'y')
    if (yearsDifference < 18) {
      setError('birthdate', {
        type: 'custom',
        message: 'Ваш возраст должен быть старше 18 лет',
      })
    } else if (yearsDifference === 18) {
      clearErrors('birthdate')
    }
  }, [birthdate])

  const submitHandler = async (data: any) => {
    setLoading(true)
    const hobbies: Array<number> | [] = enabledHobbies.map((hobby) => hobby.id)
    delete data.images
    const params = {
      ...data,
      firstName:
        data.firstName.charAt(0).toLocaleUpperCase() + data.firstName.slice(1),
      lastName:
        data.lastName.charAt(0).toLocaleUpperCase() + data.lastName.slice(1),
      gender: data.gender === 'Мужской' ? 'm' : 'f',
      children: data.children === 'Есть' ? true : false,
      addictions: data.addictions === 'Да' ? true : false,
      hobbies: hobbies,
      education: data?.education || null,
      birthdate: dayjs(data.birthdate).format('YYYY-MM-DD'),
      maritalStatus: data.maritalStatus || null,
    }
    try {
      const response = await userInfoUpdateById(userInfo?.id, params)
      await Promise.all(
        images.map(async (image) => {
          const imageFormDataExists = image.image.getAll('photo')[0]
          console.log(image, imageFormDataExists)
          if (imageFormDataExists && typeof image.image !== 'string') {
            image.image.append('order', String(image.id))
            const uploadedAvatar = await uploadFirstAvatar(image.image)
            console.log(image, uploadedAvatar?.data)
            if (image.isMainAvatar) {
              await setUserAvatar(uploadedAvatar?.data.photoId)
            }
          } else if (image.isMainAvatar && image?.photoId) {
            await setUserAvatar(image.photoId)
          } else if (image?.photoId && image?.photoId > 0 && !image.src) {
            await deleteUserImage(image?.photoId)
          }
        })
      )

      if (response?.status === 200) {
        getCurrentUser(uid)
        setIsSuccess(true)
        setTag('')
      }
    } catch (error: any) {
      setIsSuccess(false)
      console.error(error)
    }

    setLoading(false)
    setIsOpen(true)
  }

  useEffect(() => {
    setTitle('Настройки')
  }, [])

  useEffect(() => {
    if (tag.length > 1) {
      getAllHobbies(tag)
    } else if (!allHobbies.length || tag.length <= 0) {
      getAllHobbies()
    }
  }, [tag])

  useEffect(() => {
    const enabled = userInfo?.hobbyUser.map((item) => ({
      ...item,
      enabled: true,
    }))
    if (userInfo) {
      setHobbies(userInfo.hobbyUser)
      setEnabledHobbies(enabled || [])
      userInfo.photo.map((photo: { id: number; name: string; order: number }) =>
        setImages((images) => {
          return images.map((image) =>
            image.id == photo.order
              ? {
                  ...photo,
                  id: photo?.order,
                  image: new FormData(),
                  src: photo?.name,
                  photoId: photo.id,
                }
              : image
          )
        })
      )
      const defaultValues: defaultValuesType = {
        firstName: '',
        lastName: '',
        gender: '',
        birthdate: '',
        maritalStatus: '',
        children: 'Есть' || 'Нет',
        addictions: 'Да' || 'Нет',
        education: userInfo.education,
      }
      defaultValues.firstName = isFirstName || userInfo.firstName
      defaultValues.lastName = isLastName || userInfo.lastName
      defaultValues.gender =
        isGender ||
        (userInfo.gender
          ? userInfo.gender === 'f'
            ? 'Женский'
            : 'Мужской'
          : null)
      defaultValues.birthdate = isBirthDate || userInfo.birthdate
      defaultValues.maritalStatus = isMaritalStatus || userInfo.maritalStatus
      defaultValues.children =
        isChildren ||
        (userInfo.children ? (userInfo.children ? 'Есть' : 'Нет') : null)
      defaultValues.addictions =
        isAddictions ||
        (userInfo.addictions ? (userInfo.addictions ? 'Да' : 'Нет') : null)
      defaultValues.education = isEducation || userInfo.education || ''
      reset({ ...defaultValues })
    }
  }, [
    userInfo,
    isAddictions,
    isChildren,
    isEducation,
    isGender,
    isMaritalStatus,
  ])

  const handleDeleteTag = (hobbyId?: number) => {
    if (!hobbyId) {
      setEnabledHobbies((prev) =>
        prev.filter((_, index) => index !== prev.length - 1)
      )
    } else {
      setEnabledHobbies((prev) => prev.filter((tag) => tag.id !== hobbyId))
    }
  }

  const handleAddTag = (hobby: hobbiesType) => {
    if (!hobby) return
    setEnabledHobbies((prev) => [...prev, { ...hobby, enabled: true }])
    setTag('')
  }

  const setAvatar = async (image: userAvatar) => {
    setImages((images) => {
      const newImages = images.map((img) => {
        if (img.id === image.id) return { ...img, isMainAvatar: true }
        return { ...img, isMainAvatar: false }
      })
      return newImages
    })
  }

  const deleteImage = async (order: number, photoId: number) => {
    setImages((images) => {
      const newImages = images.map((img) =>
        img.id == order
          ? {
              id: order,
              image: new FormData(),
              photoId,
              src: '',
              isMainAvatar: false,
            }
          : img
      )
      return newImages
    })
  }

  const getPhotoByOrder = (order: number | null) => {
    const photo = images.find((item) => item.id == order)
    return photo
  }

  const toggleTooltip = (
    event: React.MouseEvent<HTMLElement> | any,
    index: number | null
  ) => {
    const isThereImage = Boolean(getPhotoByOrder(index)?.src)
    if (isThereImage && index) {
      event.stopPropagation()
      event.preventDefault()
      setTooltipOpen(index)
    } else {
      setTooltipOpen(null)
    }
  }

  const renderUploads = () =>
    images.map((image) => {
      const photo = image.image.getAll('photo')[0]
      const imageExists = !!photo || !!image.src
      return (
        <>
          <Tooltip
            key={image?.id}
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'white',
                  '& .MuiTooltip-arrow': {
                    color: 'common.black',
                  },
                },
              },
            }}
            title={
              <>
                <Button
                  disabled={!imageExists}
                  onClick={() => setAvatar(image)}
                  sx={{ color: '#33A03E' }}
                >
                  Сделать аватаром
                </Button>
                <Button
                  disabled={!imageExists}
                  onClick={() => {
                    const order = image?.id
                    const photoId = image?.photoId
                    console.log()
                    order && photoId && deleteImage(order, photoId)
                  }}
                  sx={{ color: '#33A03E' }}
                >
                  Удалить
                </Button>
              </>
            }
            {...(PhoneSizeScreen
              ? {
                  onClose: (event) => toggleTooltip(event, null),
                  open: tooltipOpen === image?.id,
                  disableFocusListener: true,
                  disableHoverListener: true,
                  disableTouchListener: true,
                }
              : {})}
          >
            <div style={{ maxWidth: 165 }}>
              <Upload
                key={image?.id}
                id={`${image?.id}`}
                type="avatar"
                name="images"
                src={image?.src}
                control={control}
                order={image?.id}
                customModal={() => setOver20MB(true)}
                {...(PhoneSizeScreen
                  ? { onClick: (event) => toggleTooltip(event, image?.id) }
                  : {})}
                afterUpload={setImages}
              />
            </div>
          </Tooltip>
          <Modal
            rootClassName={styles.ModalOver2MB}
            open={over20MB}
            onClose={() => setOver20MB(false)}
          >
            <p>
              Загружаемое изображение должно быть меньше 10Мб.
              <br />
              Повторите попытку снова
            </p>
          </Modal>
        </>
      )
    })

  console.log(images)

  const onTextChange = (value: string) => setTag(value)
  const onClose = () => setIsOpen(false)

  return (
    <>
      <p className={styles.tabletTitle}>Основные</p>
      <form onSubmit={handleSubmit(submitHandler)} className={styles.container}>
        <div className={styles.uploadContainer}>{renderUploads()}</div>
        <div className={styles.inputsContainer}>
          <TextInput
            label="Фамилия"
            {...register('lastName', {
              required: 'Укажите фамилию',
            })}
            onTextChange={(value) => {
              if (value[0] == value.charAt(0).toLocaleUpperCase()) return value
              let newValue = `${value}`
              newValue = value.charAt(0).toLocaleUpperCase() + value.slice(1)
              return newValue
            }}
            control={control}
          />
          <FormError errors={errors} name="lastName" />

          <TextInput
            label="Имя"
            {...register('firstName', {
              required: 'Укажите имя',
            })}
            onTextChange={(value) => {
              if (value[0] == value.charAt(0).toLocaleUpperCase()) return value
              let newValue = `${value}`
              newValue = value.charAt(0).toLocaleUpperCase() + value.slice(1)
              return newValue
            }}
            control={control}
          />
          <FormError errors={errors} name="firstName" />

          <GreenSelect
            items={mockGenders}
            label="Пол"
            {...register('gender')}
            control={control}
          />
          <DatePickerField
            label="Дата рождения"
            htmlLabel="Дата рождения"
            {...register('birthdate')}
            control={control}
          />
          <FormError errors={errors} name="birthdate" />
          <GreenSelect
            items={mockMaritalStatus}
            label="Семейное положение"
            {...register('maritalStatus')}
            control={control}
          />
          <div className={styles.education}>
            <GreenSelect
              items={mockEducation}
              label="Образование"
              {...register('education')}
              control={control}
            />
          </div>
          <GreenSelect
            items={['Есть', 'Нет']}
            label="Дети"
            {...register('children')}
            control={control}
          />
          <GreenSelect
            items={mockYesNo}
            label="Вредные привычки"
            {...register('addictions')}
            control={control}
          />
        </div>
        <div className={styles.tagsContainer}>
          Ваши интересы:
          <div className={styles.tags}>
            <InputTags
              name="tags"
              values={hobbies}
              value={tag}
              OnTextChange={onTextChange}
              handleTagDelete={handleDeleteTag}
              handleTagClick={handleAddTag}
              tags={allHobbies}
              enabledHobbies={enabledHobbies}
              label="Начните вводить интерес"
            />
          </div>
        </div>
        <GreenButton
          className={settingsStyles.button}
          sx={{
            mt: '8px',
          }}
          type="submit"
          loading={loading}
        >
          Сохранить изменения
        </GreenButton>
      </form>
      <div className={styles.isTablet}>
        <SettingsContacts />
        <SettingsNotifications />
      </div>
      <Modal open={open} onClose={onClose}>
        <div className={styles.modal}>
          {isSuccess ? (
            <div className={styles.modal__success}>
              <img src={success} alt="success" />
              Изменения настроек успешно сохранены
            </div>
          ) : (
            <>
              <p>Упс!</p>
              <span>Произошла ошибка, повторите попытку еще раз</span>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export default SettingsMain
