import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  getMainNotifications,
  readMainNotifications,
} from '@/services/api/notifications'

import { DefineNotificationType } from './util/DefineNotificationType'
import { GreenButton } from '@/components/elements/buttons'
import { NotificationsItem } from './ui'
import { OutletContextType } from '@/types'
import Pagination from '@mui/material/Pagination'
import { mainNotificationType } from '@/types/Notifications/Notifications'
import { setUnreadNotifications } from '@/store/slices/notifications'
import styles from './notifications.module.scss'
import { useAppSelector } from '@/helpers/hooks'
import { useDispatch } from 'react-redux'
import { useOutletContext } from 'react-router-dom'

function Notifications() {
  const [setTitle] = useOutletContext<OutletContextType>()
  const { userInfo } = useAppSelector((state) => state.auth)

  const [allNotifications, setAllNotifictions] = useState([])
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  })
  const [totalPages, setTotalPages] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    setTitle('Уведомления')
  }, [])

  const getAllNotifications = async () => {
    const notifications = (
      await getMainNotifications({
        page: params.page,
        limit: params.limit,
      })
    )?.data
    setTotalPages(Math.ceil(notifications.total / params.limit))
    notifications.items?.length > 0 && setAllNotifictions(notifications.items)
  }

  useEffect(() => {
    getAllNotifications()
  }, [params])

  const ref = useRef(null as HTMLDivElement | null)
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries) => {
          entries.forEach((ent) => {
            if (ent.isIntersecting) {
              const id = Number(ent.target.getAttribute('data-id'))
              readMainNotifications([id])
              dispatch(setUnreadNotifications({ type: 'remove' }))
              observer.unobserve(ent.target)
            }
          })
        },
        { threshold: 1, root: ref.current, rootMargin: '24px' }
      ),
    []
  )

  useEffect(() => {
    ref?.current?.querySelectorAll("[data-isread='false']").forEach((el) => {
      observer.observe(el)
    })
    return () => {
      observer.disconnect()
    }
  }, [ref, observer, allNotifications])

  const allNotificationItems = useMemo(
    () =>
      allNotifications?.map((notification) => {
        if (!userInfo) return
        const { title, description, createdAt, isRead, id } =
          DefineNotificationType(notification, userInfo) as mainNotificationType
        return (
          <NotificationsItem
            key={id}
            title={title}
            id={id}
            date={createdAt}
            viewed={!!isRead}
            text={description}
          />
        )
      }),
    [allNotifications]
  )

  const handleChange = (pageNumber: number) => {
    setParams((prev) => ({
      ...prev,
      page: pageNumber,
    }))
  }

  return (
    <div className={styles.notifications}>
      <div className={styles.notifications__controlls}>
        <GreenButton
          onClick={async () => {
            await readMainNotifications()
            getAllNotifications()
            dispatch(setUnreadNotifications({ type: 'reset' }))
          }}
        >
          Отметить все как прочитанное
        </GreenButton>
      </div>
      <div className={styles.notifications__list} ref={ref}>
        {allNotificationItems}
      </div>
      {totalPages > 0 && (
        <Pagination
          className={styles.pagination}
          count={totalPages}
          page={params.page}
          onChange={(e, pageNumber) => handleChange(pageNumber)}
        />
      )}
    </div>
  )
}

export default Notifications
