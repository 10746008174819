import {
  archiveAdvertType,
  createAdvertType,
  deleteAdvertByIdType,
  getAdvertType,
  getAdvertsType,
  getUserAdvertsType,
  getUserArchivedAdvertsType,
  updateAdvertType, getRecommendedAdvertsType,
} from './adverts.types'

import { AxiosError } from 'axios'
import { axiosInstance } from '@/config'

export const getAdverts: getAdvertsType = async (params) => {
  try {
    return axiosInstance.get('/adverts', { params })
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const getAdvertById: getAdvertType = async (advertId) => {
  try {
    return axiosInstance.get(`/adverts/${advertId}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const getRecommendedAdverts: getRecommendedAdvertsType = async (advertId) =>{
  try {
    return axiosInstance.get(`/recommendations/adverts/${advertId}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const getUserAdverts: getUserAdvertsType = async (userId, params) => {
  try {
    return axiosInstance.get(`/adverts/user/${userId}`, { params })
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const getUserArchivedAdverts: getUserArchivedAdvertsType = async (
  params
) => {
  try {
    return axiosInstance.get('/adverts/archive', { params })
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const getUserModeratedAdverts: getUserArchivedAdvertsType = async (
  params
) => {
  try {
    return axiosInstance.get('/adverts/moderations', { params })
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const updateAdvert: updateAdvertType = async (id, params) => {
  try {
    return axiosInstance.patch(`/adverts/${id}`, params)
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const archiveAdvert: archiveAdvertType = async (id) => {
  try {
    return axiosInstance.patch(`/adverts/archive/${id}`)
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const createAdvert: createAdvertType = async (params) => {
  try {
    return axiosInstance.post(`/adverts`, params)
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}

export const deleteAdvertById: deleteAdvertByIdType = async (advertId) => {
  try {
    return axiosInstance.delete(`/adverts/${advertId}`)
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      throw new Error(error.message)
    }
  }
}
