import { axiosInstance } from '@/config'
import { AxiosError } from 'axios'
import {
  getAllComplaintType,
  getComplaintCategoriesType,
  postComplaintType,
  updateComplaintStatusType,
} from './complaint.types'
import QueryParamsParser from '@/helpers/queryParamsParser/queryParamsParser'

export const postComplaint: postComplaintType = async (params) => {
  try {
    return axiosInstance.post(`complaints`, params)
  } catch (error: unknown) {
    if (error instanceof AxiosError) throw new Error(error.message)
  }
}

export const getComplaintCategories: getComplaintCategoriesType = async () => {
  try {
    return axiosInstance.get(`category-complaints`)
  } catch (error: unknown) {
    if (error instanceof AxiosError) throw new Error(error.message)
  }
}

export const getAllComplaint: getAllComplaintType = async (params) => {
  const url = QueryParamsParser('complaints', { ...params })
  try {
    return axiosInstance.get(url)
  } catch (e: unknown) {
    if (e instanceof AxiosError) throw new Error(e.message)
  }
}

export const updateComplaintStatus: updateComplaintStatusType = async (
  id,
  status
) => {
  try {
    return axiosInstance.put(`complaints/${id}`, { ...status })
  } catch (e: unknown) {
    if (e instanceof AxiosError) throw new Error(e.message)
  }
}
