import { FieldValues, useForm } from 'react-hook-form'
import {
  ProceedRegistrationParams,
  proceedRegistration,
} from '@/services/api/auth'

import { DatePickerField } from '@/components/forms/elements/date-pickers'
import FormError from '@/components/forms/elements/FormError/FormError'
import { GenderRadio } from '@/components/forms/elements/radio/GenderRadio'
import { GreenButton } from '@/components/elements/buttons'
import GreenHeart from '@assets/icons/green-heart.svg'
import PartyingSmile from '@assets/icons/emojis/partying-face.svg'
import { SearchInput } from '@/components/forms/elements/inputs'
import { TextInput } from '@/components/forms/elements/inputs/TextInput'
import dayjs from 'dayjs'
import { debounce } from '@helpers/decorators/debounce'
import styles from '../auth-form.module.scss'
import { trimField } from '@/helpers/form/trimField'
import { getAllCities } from '@/services/api/cities'
import { useAppSelector } from '@/helpers/hooks'
import { useEffect } from 'react'

export function RegisterFormSecondStep() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    // getValues,
    setValue,
    setError,
  } = useForm()

  const { cities } = useAppSelector((state) => state.cities)

  const submitHandler = (data: FieldValues) => {
    console.log(data.birthdate.toDate())
    const numReg = /[0-9]+/g
    if (numReg.test(data.lastName)) {
      return setError('lastName', {
        type: 'custom',
        message: 'Фамилия не должна содержать числа',
      })
    }
    if (numReg.test(data.firstName)) {
      return setError('firstName', {
        type: 'custom',
        message: 'Имя не должно содержать числа',
      })
    }
    if (
      !data.birthdate.$D ||
      dayjs().diff(data.birthdate.toDate(), 'years') > 110 || // если старше 110 лет
      dayjs().diff(data.birthdate.toDate(), 'years') < 18 // если ещё не родился
    ) {
      return setError('birthdate', {
        type: 'custom',
        message: 'Некорректная дата',
      })
    }
    const currCityData = cities.find((item) => item.value === data.cityName)
    if (!currCityData) {
      return setError('cityName', {
        type: 'custom',
        message: 'Выберите город из представленного списка',
      })
    }

    const { kladr_id } = currCityData.data

    return proceedRegistration({
      ...(data as ProceedRegistrationParams),
      cityKladrId: kladr_id,
      birthdate: data.birthdate.toString() as string,
    })
  }

  const fetchCities = async (value: string) => {
    try {
      await getAllCities(value)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setValue('birthdate', `${dayjs().year(dayjs().year() - 18)}`)
  }, [])

  const debouncedFetchCities = debounce((value: string) => fetchCities(value))

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
        <h3 className={styles.form_title}>
          Поздравляем! <img src={GreenHeart} alt="" />{' '}
          <img src={PartyingSmile} alt="" />
        </h3>
        <p className={styles.form__text}>
          Для завершения регистрации, укажите следующие данные о себе:
        </p>
        <TextInput
          control={control}
          className={styles.form_input}
          {...register('lastName', {
            required: 'Обязательное поле',
            ...trimField({ name: 'lastName', setValue }),
          })}
          inputProps={{ placeholder: 'Фамилия' }}
        />
        <FormError errors={errors} name="lastName" />

        <TextInput
          control={control}
          className={styles.form_input}
          {...register('firstName', {
            required: 'Обязательное поле',
            ...trimField({ name: 'firstName', setValue }),
          })}
          inputProps={{ placeholder: 'Имя' }}
        />
        <FormError errors={errors} name="firstName" />

        <DatePickerField
          control={control}
          className={styles.form_input}
          {...register('birthdate', {
            required: 'Некорректная дата',
          })}
          label="Дата рождения"
        />
        <FormError errors={errors} name="birthdate" />

        <div className={styles.form_radio_group}>
          <span className={styles.group_span}>Пол:</span>
          <GenderRadio
            control={control}
            className={styles.group}
            {...register('gender', {
              required: 'Укажите пол',
            })}
            selectsProps={{ className: styles.select_bg }}
          />
        </div>

        <FormError errors={errors} name="gender" />

        <div className={styles.form_city}>
          <SearchInput
            options={cities.map(({ value }) => value)}
            control={control}
            className={styles.search_input}
            OnTextChange={(value) => {
              setValue('cityName', value)
              debouncedFetchCities(value)
            }}
            placeholder="Город"
            inputProps={{
              placeholder: 'Город',
            }}
            {...register('cityName', {
              required: 'Укажите город',
            })}
          />
        </div>

        <FormError errors={errors} name="cityName" />

        <GreenButton className={styles.form_btn} type={'submit'}>
          Далее
        </GreenButton>
      </form>
    </>
  )
}
