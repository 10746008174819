import { complaintsType } from '@/types/complaints'
import { createSlice } from '@reduxjs/toolkit'

interface initialStateType {
  allComplaints: complaintsType
}

const initialState: initialStateType = {
  allComplaints: {
    items: [],
    total: 0,
  },
}

export const complaintSlice = createSlice({
  name: 'complaints',
  initialState,
  reducers: {
    setAllComplaints: (state, action) => {
      state.allComplaints = action.payload
    },
  },
})

export const { setAllComplaints } = complaintSlice.actions
export default complaintSlice.reducer
