import { axiosInstance } from '@/config'
import { AxiosError } from 'axios'
import { uploadFuncType } from '../upload.types'

export const uploadFile: uploadFuncType = async (file) => {
  try {
    return axiosInstance.post(`upload/file`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}
