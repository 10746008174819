import 'dayjs/locale/fr'
import 'dayjs/locale/ru'
import 'dayjs/locale/de'
import 'dayjs/locale/ar-sa'

import * as React from 'react'

import { Control, Controller, FieldValues } from 'react-hook-form'
import {
  FormControl,
  FormControlProps,
  TextFieldProps,
  ThemeProvider,
} from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import styles from './date-picker.module.scss'
import themeDatePicker from '@/components/forms/elements/date-pickers/DatePickerField/model/theme'
import { useState } from 'react'
import classNames from 'classnames'

interface Props extends FormControlProps {
  name: string
  label?: string
  htmlLabel?: string
  control: Control<FieldValues> | undefined
  defaultValue?: string
  inputProps?: TextFieldProps
}

export function DatePickerField({
  name,
  label,
  htmlLabel,
  control,
  defaultValue,
  ...props
}: Props) {
  const [locale] = React.useState<string>('ru')
  const [open, setOpen] = useState(false)

  const datePickerClasses = classNames(
    {
      [styles.formControl]: true,
    },
    props.className
  )

  return (
    <FormControl {...props} className={datePickerClasses}>
      {htmlLabel && <label>{htmlLabel}:</label>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <ThemeProvider theme={themeDatePicker}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={locale}
            >
              <Stack spacing={3}>
                <DatePicker
                  className={styles.datePicker}
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  value={value}
                  onChange={onChange}
                  disableOpenPicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={styles.textField}
                      defaultValue={defaultValue}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: label,
                      }}
                      onClick={() => setOpen(true)}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </ThemeProvider>
        )}
      />
    </FormControl>
  )
}
