import { FieldValues, useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@helpers/hooks'

import { ForgotPasswordForm } from '@/components/forms/AuthForm/ForgotPasswordForm'
import FormError from '@/components/forms/elements/FormError/FormError'
import { GreenButton } from '@/components/elements/buttons'
import Modal from '@/components/modals/Modal/Modal'
import { RegisterForm } from '@/components/forms/AuthForm/RegisterForm'
import { TextInput } from '@/components/forms/elements/inputs/TextInput'
import { login } from '@/services/api/auth'
import { showModalStep } from '@store/slices/auth'
import styles from './auth-form.module.scss'
import { trimField } from '@/helpers/form/trimField'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'

export function AuthForm({ initialStep = 0 }: { initialStep?: number }) {
  const [formStep, setFormStep] = useState(initialStep)

  const { modalStep } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  // 0 - авторизация
  // 1 - забыли пароль
  // 2 - регистрация
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
  } = useForm()
  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    setFormStep(modalStep)
  }, [dispatch, modalStep])

  const submitHandler = async (data: FieldValues) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('login')

    try {
      const res = await login({
        email: data.email as string,
        password: data.password as string,
        recaptchaValue: token,
      })
      if (res?.data.id === 1) {
        navigate('/admin-panel')
      }

      return res
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (
          error.message ===
            "Cannot read properties of undefined (reading 'accessToken')" ||
          error.message ===
            "undefined is not an object (evaluating 'loginRes.data.accessToken')" ||
          error.message ===
            "undefined is not an object (evaluating 'n.data.accessToken')"
        ) {
          setError('password', {
            type: 'custom',
            message: 'Неверный email или пароль',
          })
        }
      }
    }
  }

  const handleCloseModal = () => {
    setFormStep(0)
    dispatch(showModalStep(0))
  }

  const stepFunc = (step: number) => {
    setFormStep(step)
    dispatch(showModalStep(0))
  }

  const renderModalForms = () => {
    if (formStep === 1) {
      return (
        <div className={styles.modal_form_container}>
          <ForgotPasswordForm />
        </div>
      )
    }
    if (formStep === 2) {
      return (
        <div className={styles.modal_form_container}>
          <RegisterForm stepFunc={stepFunc} />
        </div>
      )
    }
  }

  return (
    <>
      <div className={styles.white_container}>
        <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
          <h3 className={styles.form_title}>Авторизация</h3>

          <TextInput
            control={control}
            className={styles.form_input}
            {...register('email', {
              required: 'Обязательное поле',
              ...trimField({ name: 'email', setValue }),
            })}
            inputProps={{ type: 'email', placeholder: 'Email' }}
          />

          <FormError errors={errors} name="email" />

          <TextInput
            control={control}
            className={styles.form_input}
            {...register('password', {
              required: 'Обязательное поле',
              minLength: {
                value: 6,
                message: 'минимальная длина пароля - 6 символов',
              },
              maxLength: {
                value: 40,
                message: 'максимальная длина пароля - 40 символов',
              },
              ...trimField({ name: 'password', setValue }),
            })}
            inputProps={{ type: 'password', placeholder: 'Пароль' }}
          />

          {/* просто напишу сейчас хелпер для проверки на свойство с .trim() */}

          <FormError errors={errors} name="password" />

          <GreenButton className={styles.form_btn} type={'submit'}>
            Вход
          </GreenButton>
        </form>

        <div className={styles.controls}>
          <p
            className={`${styles.controls__item} ${styles.controls__item_clickable}`}
            onClick={() => stepFunc(1)}
          >
            Забыли пароль?
          </p>
          <p className={styles.controls__item}>
            Нет аккаунта?{' '}
            <span className={styles.green} onClick={() => stepFunc(2)}>
              Зарегистрируйтесь
            </span>
          </p>
        </div>
      </div>
      <Modal onClose={handleCloseModal} open={!!formStep} className={'sad'}>
        <>{renderModalForms()}</>
      </Modal>
    </>
  )
}
