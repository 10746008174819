import { dialogMessageType, dialogType } from '@/types/Dialogs'
import { getMessages, joinToDialog } from '@/services/api/dialogs'
import { useAppDispatch, useAppSelector } from '@/helpers/hooks'
import { useEffect, useRef, useState } from 'react'

import { File } from '../File'
import { Header } from './ui/Header'
import { Message } from '../Message'
import { TextInput } from '@/components/forms/elements/inputs'
import classNames from 'classnames'
import filesIcon from '@assets/icons/files.svg'
import send from '@assets/icons/send.svg'
import { setUnreadMessages } from '@/store/slices/notifications'
import { socket } from '@/config'
import styles from './MessageWindow.module.scss'
import { unblockUser } from '@/services/api/user'
import { uploadFile } from '@/services/api/upload/file'
import { useForm } from 'react-hook-form'

interface Props {
  id?: string
  dialogsAmount: number
  setDialogs: React.Dispatch<
    React.SetStateAction<Map<number, dialogType[]> | Map<number, unknown>>
  >
  setRefetchDialogs: (state: boolean) => void
  setDialogsUpdate: React.Dispatch<React.SetStateAction<boolean>>
}

export const MessageWindow = ({
  id,
  dialogsAmount,
  setDialogs,
  setDialogsUpdate,
  setRefetchDialogs,
}: Props) => {
  const { register, control, reset, handleSubmit } = useForm()
  const [messages, setMessages] = useState<dialogMessageType[] | []>([])
  const [fetchOffset, setFetchOffset] = useState(0)
  const { userInfo } = useAppSelector((state) => state.auth)
  const [roomId, setRoomId] = useState(0)
  const [files, setFiles] = useState<
    { name: string; id: string; originalName: string; type: string }[] | []
  >([])
  const [firstElement, setFirstElement] = useState<null | HTMLDivElement>(null)
  const [totalMessagesCount, setTotalMessagesCount] = useState(0)
  const [otherUserInfo, setOtherUserInfo] = useState<{
    avatar: string
    firstName: string
    forbidden: number
  } | null>(null)
  const [fetchingMessages, setFetchingMessages] = useState(true)
  const [scrollToBottom, setScrollToBottom] = useState(true)
  const [heightBeforeNewMessages, setHeightBeforeNewMessages] = useState(0)
  const messageEndRef = useRef(null as HTMLDivElement | null)
  const dialogsWindowRef = useRef(null as HTMLDivElement | null)
  const dispatch = useAppDispatch()

  const wrapperClassNames = classNames({
    [styles.wrapper]: true,
    [styles.notInDialog]: !id,
  })

  useEffect(() => {
    setFirstElement(null)
    setScrollToBottom(true)
  }, [id, roomId])

  useEffect(() => {
    // функция входа в чат
    async function joinRoom() {
      try {
        if (id) {
          await joinToDialog(id).then((res) => {
            if (res) {
              setRoomId(res?.data.id)
              setFetchOffset(0)
              setFetchingMessages(true)
              setOtherUserInfo(
                res.data.user1Id === userInfo?.id
                  ? res.data.user2
                  : res.data.user1
              )
              socket.emit('joinRoom', {
                roomId: res?.data.id,
              })
            }
          })
        } else {
          setOtherUserInfo(null)
        }
      } catch (e) {
        console.log(e)
      }
    }

    joinRoom()
  }, [id, userInfo])

  useEffect(() => {
    return () => {
      // выйти из диалога, когда пользователь покидает страницу
      socket.emit('closeConnection', {
        roomId: roomId,
        userName: userInfo?.email,
      })
    }
  }, [roomId])

  const getChatMessages = async () => {
    if (!roomId) return
    if (!fetchingMessages) return
    if (!dialogsWindowRef.current) return
    try {
      setHeightBeforeNewMessages(dialogsWindowRef.current.scrollHeight)
      await getMessages(roomId, 20, fetchOffset)
        .then((res) => {
          if (!res) return
          setMessages((prev) => {
            return fetchOffset === 0
              ? res.data.items
              : [...res.data.items, ...prev]
          })
          setTotalMessagesCount(res?.data.total || 0)
        })
        .finally(() => {
          setFetchingMessages(false)
        })
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    getChatMessages()
  }, [fetchingMessages, roomId])

  useEffect(() => {
    if (!id) {
      setMessages([])
    }
  }, [id])

  useEffect(() => {
    console.log('add')
    socket.on('message', (message: dialogMessageType) => {
      if (
        dialogsWindowRef.current &&
        (dialogsWindowRef.current.scrollTop +
          dialogsWindowRef.current.clientHeight ===
          dialogsWindowRef.current.scrollHeight ||
          dialogsWindowRef.current.scrollHeight -
            (dialogsWindowRef.current.scrollTop +
              dialogsWindowRef.current.clientHeight) <=
            50)
      ) {
        setScrollToBottom(true)
      }
      setFirstElement(null)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setMessages((prev) => [
        ...prev,
        {
          ...message,
          isRead: false,
        },
      ])
    })
    socket.on('setMessageIsRead', (message) => {
      setMessages((prev) => {
        console.log(prev)
        return prev.map((el) =>
          el.id === message.id ? { ...el, isRead: true } : el
        )
      })
    })

    return () => {
      socket.off('setMessageIsRead')
      socket.off('message')
    }
  }, [roomId])

  useEffect(() => {
    if (!dialogsWindowRef.current || !messageEndRef) return
    console.log('calculation', firstElement, scrollToBottom)
    if (messages.length && scrollToBottom) {
      console.log('bottom')
      messageEndRef.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      })
      setScrollToBottom(false)
    } else if (firstElement) {
      // расчет для сохранения положения прокрутки
      dialogsWindowRef.current.scrollTop =
        dialogsWindowRef.current.scrollHeight - heightBeforeNewMessages
    }
  }, [dialogsWindowRef, messages, scrollToBottom])

  useEffect(() => {
    if (!dialogsWindowRef.current) return
    const observer = new IntersectionObserver(
      (entries) => {
        let amountOfReadMessage = 0
        entries.forEach((ent) => {
          // только читать, если это в ViewPort
          if (ent.isIntersecting) {
            const author = ent.target.getAttribute('data-author')
            // только читать текст другого пользователя
            if (author !== userInfo?.email) {
              const id = Number(ent.target.getAttribute('data-id'))
              socket.emit('readMessage', {
                messageId: id,
                roomId: `${roomId}`,
              })
              amountOfReadMessage++
            }
            observer.unobserve(ent.target)
          }
        })
        setDialogs((prev) => {
          const dialogs = prev as Map<number, dialogType>
          const currentDialog = dialogs.get(roomId) as dialogType
          dialogs.set(roomId, {
            ...currentDialog,
            unreadMessagesCount:
              currentDialog?.unreadMessagesCount - amountOfReadMessage,
          })
          return dialogs
        })
        dispatch(
          setUnreadMessages({
            type: 'customRemove',
            ammount: amountOfReadMessage,
          })
        )
        setDialogsUpdate((prev) => (prev ? false : true))
      },
      { threshold: 1, root: dialogsWindowRef.current, rootMargin: '24px' }
    )
    dialogsWindowRef.current
      ?.querySelectorAll("[data-isread='false']")
      .forEach((el) => {
        observer.observe(el)
      })
    return () => {
      observer.disconnect()
    }
  }, [scrollToBottom, messages, dialogsWindowRef?.current])

  const dialogsScroll = async (e: any) => {
    if (e.target.scrollTop === 0 && messages.length < totalMessagesCount) {
      const element = e.target.querySelector('div')
      setFirstElement(element)
      setFetchingMessages(true)
    }
  }

  useEffect(() => {
    if (!dialogsWindowRef.current) return
    dialogsWindowRef.current.addEventListener('scroll', dialogsScroll)
    setFetchOffset(messages.length)
    return () => {
      if (!dialogsWindowRef.current) return
      dialogsWindowRef.current.removeEventListener('scroll', dialogsScroll)
    }
  }, [messages])

  const sendMessage = ({ message }: { message?: string }) => {
    if (message && message?.trim().length) {
      socket.emit('chatMessage', {
        messages: message,
        sender: userInfo?.email,
        roomId: `${roomId}`,
      })
      reset({})
    }
    setRefetchDialogs(true)

    if (files.length > 0) {
      // перевести файлы в текст
      const fileList = files.map(
        (file) => `EXTF:${file.name}:${file.originalName}::IMG`
      )

      // затем отправляем каждый текст отдельно
      fileList.map((file) => {
        if (file) {
          socket.emit('chatMessage', {
            messages: file,
            sender: userInfo?.email,
            roomId: `${roomId}`,
          })
        }
      })
      reset({})
      setFiles([])
    }
    setFirstElement(null)
    setScrollToBottom(true)
    reset({})
  }

  const onFileDelete = (id: string) => {
    setFiles((prev) => prev.filter((file) => file.id !== id))
  }

  const uploadMessageFile = async (file: File) => {
    if (files.length > 4) return
    const image = new FormData()
    image.append('file', file)
    const res = (await uploadFile(image, undefined))?.data?.photo
    setFiles((prev) => [
      {
        name: res.filename,
        originalName: file.name,
        id: res.filename,
        type: res.mimetype,
      },
      ...prev,
    ])
  }

  const unblockUserDialog = async (userId: string) => {
    await unblockUser(userId).then((res) => {
      res && setOtherUserInfo((prev: any) => ({ ...prev, forbidden: 0 }))
    })
  }

  const handleImageLoadScroll = () => {
    if (messages.length <= 20) {
      setScrollToBottom(true)
    }
  }

  return (
    <form className={wrapperClassNames} onSubmit={handleSubmit(sendMessage)}>
      {otherUserInfo && <Header userInfo={otherUserInfo} />}
      <section ref={dialogsWindowRef} className={styles.wrapper_dialogWindow}>
        {/* тут будут отсвечиваться сообщения */}
        {messages.map((message, index) => (
          <Message
            finalMessage={messages.length - 1 === index}
            handleImageLoadScroll={handleImageLoadScroll}
            userInfoEmail={userInfo?.email}
            key={index}
            messageInfo={message}
          />
        ))}
        {dialogsAmount === 0 ? (
          <p className={styles.chooseDialog}>
            Пока у вас нет активных диалогов
          </p>
        ) : (
          !id && <p className={styles.chooseDialog}>Выберите диалог</p>
        )}
        <div ref={messageEndRef} style={{ height: 0 }} />
      </section>

      <section className={styles.sendMessageContainer}>
        <div className={styles.sendMessageContainer_files}>
          {files.map((file) => (
            <File
              key={file.id}
              name={file.originalName}
              id={file.id}
              onDelete={onFileDelete}
            />
          ))}
        </div>
        {id &&
          (!!otherUserInfo?.forbidden ? (
            <div
              onClick={() => id && unblockUserDialog(id)}
              className={styles.sendMessageContainer_unblock}
            >
              <p className={styles.sendMessageContainer_unblockText}>
                РАЗБЛОКИРОВАТЬ
              </p>
            </div>
          ) : (
            <div className={styles.sendMessageContainer_sendMessage}>
              <input
                onChange={(event) => {
                  if (event?.target?.files?.[0]) {
                    uploadMessageFile(event?.target?.files?.[0])
                  }
                  return (event.currentTarget.value = '')
                }}
                id="file"
                hidden
                accept="image/*"
                multiple
                type="file"
              />
              <label
                htmlFor="file"
                className={styles.sendMessageContainer_sendMessage_sendButton}
              >
                <img src={filesIcon} alt="upload" />
              </label>
              <TextInput
                control={control}
                className={styles.sendMessageContainer_sendMessage_input}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e
                    e.preventDefault()
                    handleSubmit(sendMessage)()
                  }
                  return ''
                }}
                {...register('message')}
                inputProps={{
                  multiline: true,
                  minRows: 1,
                  type: 'message',
                  placeholder: 'Напишите сообщение...',
                }}
              />
              <button
                className={styles.sendMessageContainer_sendMessage_sendButton}
                type="submit"
              >
                <img src={send} alt="send" />
              </button>
            </div>
          ))}
      </section>
    </form>
  )
}
