import { ReactComponent as AddSVG } from '@assets/icons/CircleCross.svg'
import { GreenButton } from '@/components/elements/buttons'
import Modal from '@/components/modals/Modal/Modal'
import styles from './PaidModule.module.scss'
import successBubble from '@assets/images/success_buble.png'

interface Props {
  open: boolean
  onClose: (addNew?: boolean) => void,
  text?: string,
  footer?: React.ReactNode
}

export const PaidModule = ({ open, onClose, text, footer }: Props) => {
  return (
    <Modal open={open} onClose={() => onClose()}>
      <div className={styles.container}>
        <img className={styles.successIMG} src={successBubble} />
        <div>{text || "Объявление будет опубликовано после прохождения модерации."}</div>
        {
          footer || (
            <GreenButton onClick={() => onClose(true)} className={styles.addNewBtn}>
              <AddSVG />
              Добавить еще
            </GreenButton>
          )
        }
      </div>
    </Modal>
  )
}
