import { useAppSelector } from '@/helpers/hooks'
import { blogCardType } from '@/types/Adverts'
import Masonry from '@mui/lab/Masonry'
import { Box, useMediaQuery } from '@mui/material'
import { SxProps } from '@mui/material/styles'

import { BlogCard, CardMode } from '..'

interface Props {
  cardsList: Array<blogCardType>
  sx?: SxProps
  MasonrySX?: SxProps
  spacing?: number
  className?: string
  columns?: number
  mode?: CardMode
  blockButtons?: boolean
  refreshCards?: () => void
  onFavoriteChange?: () => void
  onDelete?: (id: number, archive: boolean) => void
}

export const BlogCardsList = ({
  cardsList,
  sx,
  MasonrySX,
  spacing,
  className,
  columns,
}: Props) => {
  const { userInfo } = useAppSelector((state) => state.auth)
  const LaptopSizeScreen = !columns && useMediaQuery('(max-width: 984px)')
  const TabletSizeScreen = !columns && useMediaQuery('(max-width: 670px)')
  const PhoneSizeScreen = !columns && useMediaQuery('(max-width: 600px)')

  console.log('v', cardsList)

  return (
    <Box sx={sx} className={className}>
      <Masonry
        defaultColumns={3}
        columns={
          columns
            ? columns
            : PhoneSizeScreen
            ? 2
            : TabletSizeScreen
            ? 1
            : LaptopSizeScreen
            ? 2
            : 3
        }
        sx={MasonrySX}
        spacing={spacing ? spacing : 2.5}
      >
        {cardsList?.map((card) => (
          <div className={'card'} key={card.id}>
            <BlogCard myUserInfo={userInfo} {...card} />
          </div>
        ))}
      </Masonry>
    </Box>
  )
}
