import {
  deleteFuncType,
  updateFuncType,
  uploadFuncType,
  uploadAvatarType,
  setAvatarType,
} from '../upload.types'

import { AxiosError } from 'axios'
import { axiosInstance } from '@/config'

export const uploadUserImage: uploadFuncType = async (file, photoId) => {
  try {
    return axiosInstance.patch(`upload/user/${photoId}`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const setUserAvatar: setAvatarType = async (photoId) => {
  try {
    return axiosInstance.patch(`users/avatar/${photoId}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const uploadFirstAvatar: uploadAvatarType = async (file) => {
  try {
    return axiosInstance.post(`upload/user`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e: unknown) {
    if (e instanceof AxiosError) throw new Error(e.message)
  }
}

export const deleteUserImage: deleteFuncType = async (id) => {
  try {
    return axiosInstance.delete(`upload/user/${id}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const updateUserImage: updateFuncType = async (id) => {
  try {
    return axiosInstance.patch(`upload/user/${id}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}
