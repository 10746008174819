import { Select } from '../Select'
import { styled } from '@mui/material/styles'

export const GreenSelect = styled(Select)`
  .MuiInputBase-root {
    background-color: rgba(128, 156, 129, 0.1) !important;
    color: #809c81;
  }
  .MuiSelect-select {
    font-family: 'Raleway', sans-serif !important;
  }
`
