import React, { useState, useEffect } from 'react'

import { GreenButton } from '@/components/elements/buttons'
import { GreenCheckBox } from '@/components/forms/elements/checkboxes/GreenCheckBox/GreenCheckBox'
import Modal from '@/components/modals/Modal/Modal'
import { TextInput } from '@/components/forms/elements/inputs'
import { ThanksModal } from './ThanksModal'
import { getComplaintCategories, postComplaint } from '@/services/api/complaint'
import styles from './ReportComplaint.module.scss'
import { Controller, useForm } from 'react-hook-form'
import { userInfoType } from '@/types/user'
import { RadioGroup } from '@mui/material'
import { GreenRadio } from '../elements/radio/GreenRadio'
import { categories } from '@/types/categories'
import FormError from '../elements/FormError/FormError'
import classNames from 'classnames'

interface Props {
  open: boolean
  onClose: () => void
  id: number
  userInfo: userInfoType
}

export const ReportComplaint = ({ open, onClose, id, userInfo }: Props) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const [openThanksModal, setOpenThanksModal] = useState(false)
  const [complaintCategories, setComplaintCategories] = useState<categories>([])

  const toggleThanksModal = () => {
    setOpenThanksModal((prev) => {
      if (prev) {
        onClose()
        return false
      }
      return true
    })
    reset()
  }

  const submitHandler = async (data: any) => {
    const params = {
      message: data?.comment,
      targetId: id,
      block: !!data?.banTarget,
      categoryComplaintsId: data?.categoryComplaintsId,
    }
    const response = await postComplaint(params)
    if (response.status === 200) toggleThanksModal()
  }

  useEffect(() => {
    const fetchCategories = async () => {
      const categories = (await getComplaintCategories())?.data
      setComplaintCategories(categories)
    }

    fetchCategories()
  }, [])

  const radioGroupClasses = classNames({
    [styles.checkboxes]: true,
    [styles.error]: !!errors.categoryComplaintsId,
  })

  return (
    <Modal
      className={styles.container}
      wrapClassName={styles.ModalWrap}
      open={open}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(submitHandler)} className={styles.form}>
        <ThanksModal open={openThanksModal} onClose={toggleThanksModal} />
        <h3>Оставить жалобу</h3>
        <p className={styles.desc}>
          Пожалуйста, <span className={'green'}>укажите причину</span> по
          которой хотите пожаловаться на пользователя:
        </p>

        <Controller
          render={({ field }) => (
            <RadioGroup className={radioGroupClasses} {...field}>
              {complaintCategories.map((category) => (
                <GreenRadio id={category.name} value={category.id} />
              ))}
            </RadioGroup>
          )}
          name="categoryComplaintsId"
          control={control}
          rules={{ required: 'Обязательное поле' }}
        />
        <div className={styles.textArea}>
          Комментарий:
          <TextInput
            inputProps={{
              placeholder: 'В тексте не должно содержаться оскорблений и мата',
              multiline: true,
            }}
            {...register('comment', { required: 'Обязательное поле' })}
            control={control}
          />
          <FormError errors={errors} name="comment" />
        </div>
        <div className={styles.submitWrap}>
          <div className={styles.agreement}>
            <GreenCheckBox {...register('banTarget')} control={control} />
            <p>
              Закрыть <span className={'green'}>{userInfo?.firstName}</span>{' '}
              доступ к моей странице и объявлениям
            </p>
          </div>
          <GreenButton type={'submit'}>Оставить жалобу</GreenButton>
        </div>
      </form>
    </Modal>
  )
}
