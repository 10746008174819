import React, { Dispatch, SetStateAction } from 'react'
import { TableCell, TableRow } from '@mui/material'
import useRejectModal from '@/components/elements/RejectModal'
import { userInfoType } from '@/types/user'
interface IProps extends userInfoType {
  setSubmitted: Dispatch<SetStateAction<boolean>>
  handleOpenFailedModal: () => void
  handleOpenSuccessModal: () => void
}

const UsersTable: React.FC<IProps> = ({
  firstName,
  lastName,
  phone,
  email,
  id,
  setSubmitted,
  handleOpenFailedModal,
  handleOpenSuccessModal,
}) => {
  const { RejectModal } = useRejectModal()
  return (
    <>
      <RejectModal
        type="объявление"
        id={id}
        setSubmitted={setSubmitted}
        handleOpenSuccessModal={handleOpenSuccessModal}
        handleOpenFailedModal={handleOpenFailedModal}
      />
      <TableRow>
        <TableCell align="left">
          {firstName || lastName ? `${firstName || ''} ${lastName || ''}` : '-'}
        </TableCell>
        <TableCell>{email || '-'}</TableCell>
        <TableCell>{phone || '-'}</TableCell>
        {/* <TableCell align="center">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
          >
            {reason ? (
              <p>{reason}</p>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={statusId !== 1}
                  onClick={handleAcceptModeration}
                >
                  Принять
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  disabled={statusId !== 1}
                  onClick={handleOpen}
                >
                  Отклонить
                </Button>
              </>
            )}
          </div>
        </TableCell> */}
      </TableRow>
    </>
  )
}

export default UsersTable
