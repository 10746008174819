import { useEffect, useState } from 'react'
import {
  activateNotifications,
  getUserNotification,
} from '@/services/api/notifications'

import { GreenButton, LogoutButton } from '@/components/elements/buttons'
import { Switch } from '@/components/forms/elements/checkboxes'
import settingsStyles from '../Settings.module.scss'
import styles from './SettingsNotifications.module.scss'
import { FieldValues, useForm } from 'react-hook-form'
import Modal from '@/components/modals/Modal/Modal'
import success from '@assets/images/success_buble.png'
import { OutletContextType } from '@/types'
import { useOutletContext } from 'react-router-dom'

type defaultValuesType = {
  1?: boolean
  2?: boolean
  3?: boolean
  4?: boolean
  5?: boolean
  6?: boolean
}

function SettingsNotifications() {
  const [setTitle] = useOutletContext<OutletContextType>()
  const { register, control, handleSubmit, reset } = useForm()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const submitHandler = async (data: FieldValues) => {
    const activeNotifications: number[] = []
    Object.keys(data).forEach(
      (item) => data[item] && activeNotifications.push(Number(item))
    )
    const response = await activateNotifications(activeNotifications)
    if (response?.status === 200) {
      setIsSuccess(true)
    } else {
      setIsSuccess(false)
    }
    setIsOpen(true)
  }

  useEffect(() => {
    setTitle('Настройки')
  }, [])

  useEffect(() => {
    const getAllNotifications = async () => {
      let defaultValues: defaultValuesType = {}
      const notifications = (await getUserNotification())?.data
      notifications?.map((notification) => {
        defaultValues = {
          ...defaultValues,
          [notification.notificationId]: notification.active,
        }
      })
      reset({ ...defaultValues })
    }
    getAllNotifications()
  }, [])

  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <div className={styles.modal}>
          {isSuccess ? (
            <div className={styles.modal__success}>
              <img src={success} alt="success" />
              Настройки уведомлений успешно сохранены
            </div>
          ) : (
            <>
              <p>Упс!</p>
              <span>Произошла ошибка, повторите попытку еще раз</span>
            </>
          )}
        </div>
      </Modal>
      <div className={styles.container}>
        <p className={styles.tabletTitle}>Уведомления</p>
        <form onSubmit={handleSubmit(submitHandler)} className={styles.form}>
          <div className={styles.switchGroup}>
            <Switch
              title={'Платные услуги'}
              desc={'Изменения статусов подключённых услуг'}
              {...register('1')}
              control={control}
            />
            <Switch
              title={'Акции'}
              desc={'Индивидуальные скидки и специальные предложения'}
              {...register('2')}
              control={control}
            />
            <Switch
              title={'Сообщения'}
              desc={'Уведомления о новых сообщениях в мессенджере'}
              {...register('3')}
              control={control}
            />
            <Switch
              title={'Объявления'}
              desc={'Уведомления об одобрении/отклонении объявления'}
              {...register('5')}
              control={control}
            />
            <Switch
              title={'Отзывы'}
              desc={'Уведомления о новых отзывах'}
              {...register('4')}
              control={control}
            />
            <Switch
              title={'Жалобы'}
              desc={'Уведомления об одобрении/отклонении жалоб'}
              {...register('6')}
              control={control}
            />
          </div>
          <div className={styles.buttons}>
            <GreenButton type="submit" className={settingsStyles.button}>
              Сохранить изменения
            </GreenButton>
            <div className={styles.isTabletBtn}>
              <LogoutButton />
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default SettingsNotifications
