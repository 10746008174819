import React, { useEffect, useMemo, useState } from 'react'
import { getFavoriteAdverts, getFavoriteUsers } from '@/services/api/favorites'

import { ButtonRadio } from '@/components/forms/elements/radio'
import { CardsList } from '@/components/elements/Cards'
import { OutletContextType } from '@/types'
import ProfileShortInfo from '@/components/elements/ProfileShortInfo'
import { RadioGroup } from '@mui/material'
import { advertsById } from '@/types/Adverts'
import styles from './Favorties.module.scss'
import { useOutletContext } from 'react-router-dom'
import { userInfoType } from '@/types/user'

function Favorites() {
  const [setTitle] = useOutletContext<OutletContextType>()
  const [selectedFavorite, setSelectedFavorite] = useState('Объявления')
  const [cards, setCards] = useState<advertsById | any>([])
  const [favoriteUsers, setFavoriteUsers] = useState<userInfoType[] | any>([])

  useEffect(() => {
    setTitle('Избранное')
  }, [])

  const getFavoriteCards = async () => {
    const response = await getFavoriteAdverts()

    setCards(response?.data)
  }

  const getAllFavoriteUsers = async () => {
    const response = await getFavoriteUsers()

    setFavoriteUsers(response?.data)
  }

  useEffect(() => {
    if (selectedFavorite === 'Объявления') {
      getFavoriteCards()
    } else {
      getAllFavoriteUsers()
    }
  }, [selectedFavorite])

  const favoriteCards = useMemo(
    () =>
      cards.map((card: advertsById) => ({
        ...card,
        liked: true,
      })),
    [cards]
  )

  const favoriteProfiles = useMemo(
    () =>
      favoriteUsers.map((user: userInfoType) => ({
        ...user,
        isLiked: true,
      })),
    [favoriteUsers]
  )

  return (
    <div className={styles.container}>
      <RadioGroup
        className={styles.radioGroup}
        onChange={(e) => setSelectedFavorite(e.target.value)}
        value={selectedFavorite}
      >
        <ButtonRadio id="Объявления" value={'Объявления'} />
        <ButtonRadio id="Пользователи" value={'Пользователи'} />
      </RadioGroup>
      {selectedFavorite === 'Объявления' ? (
        !!favoriteCards?.length ? (
          <CardsList
            className={styles.cardsContainer}
            cardsList={favoriteCards}
          />
        ) : (
          <h3 className={styles.empty_title}>Здесь пока ничего нет</h3>
        )
      ) : !!favoriteProfiles?.length ? (
        <div className={styles.favoriteUsersContainer}>
          {favoriteProfiles.map((user: userInfoType) => (
            <div className={styles.contacts__block} key={user.id}>
              <ProfileShortInfo
                onFavoriteChange={getAllFavoriteUsers}
                age={user.age}
                name={`${user.firstName}`}
                rating={user.average}
                forceLike={true}
                isLiked={user.isLiked}
                canFavorite={true}
                userId={user.id}
                photo={user.avatar}
                forbidden={!!user.forbidden}
              />
            </div>
          ))}
        </div>
      ) : (
        <h3 className={styles.empty_title}>Здесь пока ничего нет</h3>
      )}
    </div>
  )
}

export default Favorites
