import React, { AllHTMLAttributes, useId } from 'react'
import { FormControl, FormControlProps, Radio, RadioGroup } from '@mui/material'
import { Control, FieldValues, Controller } from 'react-hook-form'
import { ReactComponent as FemaleIcon } from '@assets/icons/female.svg'
import { ReactComponent as MaleIcon } from '@assets/icons/male.svg'
import styles from './gender-radio.module.scss'

interface Props extends FormControlProps {
  name: string
  label?: React.ReactNode
  control?: Control<FieldValues> | undefined
  defaultValue?: string
  selectsProps?: AllHTMLAttributes<HTMLLabelElement>
}

export function GenderRadio({
  name,
  // label,
  control,
  defaultValue,
  selectsProps,
  ...props
}: Props) {
  const maleId = useId()
  const femaleId = useId()
  return (
    <FormControl {...props}>
      {/*<InputLabel id={labelId}>{label}</InputLabel>*/}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            onChange={onChange}
            value={value}
            className={styles.radio_group}
          >
            <Radio value={'f'} id={femaleId} className={styles.radio} />
            <label
              htmlFor={femaleId}
              {...selectsProps}
              className={`${styles.label}${
                value == 'f' ? ' ' + styles.female_check : ''
              }${selectsProps ? ' ' + selectsProps?.className : ''}`}
            >
              <FemaleIcon />
            </label>

            <Radio value={'m'} id={maleId} className={styles.radio} />
            <label
              htmlFor={maleId}
              {...selectsProps}
              className={`${styles.label}${
                value == 'm' ? ' ' + styles.male_check : ''
              }${selectsProps ? ' ' + selectsProps?.className : ''}`}
            >
              <MaleIcon />
            </label>
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}
