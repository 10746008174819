/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from 'react'

import { AxiosError } from 'axios'
import { GreenButton } from '@/components/elements/buttons'
import { ReactComponent as GreenLoadingArrow } from '@assets/icons/green-loading-arrow.svg'
import Modal from '@/components/modals/Modal/Modal'
import { Pagination } from '@mui/material'
import { SubscriptionForm } from './SubscriptionForm'
import { buyProAccount } from '@/services/api/users'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { deleteSubscribeAdverts } from '@/services/api/subscriptionAdverts'
import { getSubscriptions } from '@/services/api/subscription'
import styles from './renew_pro.module.scss'
import { useAppSelector } from '@/helpers/hooks'

interface Props {
  disabled?: boolean
}

export function RenewPro(props: Props) {
  const { disabled } = props
  const { userInfo } = useAppSelector((state) => state.auth)
  const { subscriptions } = useAppSelector((state) => state.subscription)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState(0)
  const handleRenewProAccount = async () => {
    try {
      const redirect = await buyProAccount()

      window.open(redirect.data.redirectUrl, '_blank')
    } catch (error) {
      if (error instanceof AxiosError) {
        setModalOpen(true)
        setModalMessage('Произошла ошибка. Повторите попытку еще раз')
      }
    }
  }
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  })
  const handleModalClose = () => {
    setModalOpen((prev) => !prev)
  }

  useEffect(() => {
    getSubscriptions({ ...params, page: params.page - 1 })
  }, [params, submitted])

  useEffect(() => {
    setTotalPages(Math.ceil(subscriptions.total / params.limit))
  }, [subscriptions])

  const deleteSubscription = async (subscriptionId: number) => {
    await deleteSubscribeAdverts(subscriptionId)
    setSubmitted((prev) => !prev)
  }

  const cardCls = classNames(`${styles.renew_pro}`, {
    [styles.renew_pro_disabled]: disabled,
  })

  const handleChange = (pageNumber: number) => {
    setParams((prev) => ({
      ...prev,
      page: pageNumber,
    }))
  }

  return (
    <>
      <div className={cardCls}>
        <h4 className={styles.renew_pro__title}>PRO-аккаунт подключен</h4>
        <p className={styles.renew_pro__date}>
          до {dayjs(userInfo?.proDate).format('DD.MM.YYYY')}
        </p>
        <p className={styles.renew_pro__price}>Оплачено: 200 руб</p>
        <GreenButton
          className={styles.renew_pro__btn}
          onClick={() => handleRenewProAccount()}
        >
          Продлить
        </GreenButton>
        <Modal open={modalOpen} onClose={handleModalClose}>
          <p>{modalMessage}</p>
        </Modal>
      </div>
      <h2 style={{ margin: '30px 0px 15px 0px' }}>Ваши подписки</h2>
      <div className={styles.subscriptionsContainer}>
        {subscriptions.items?.map((subscription) => (
          <SubscriptionForm
            deleteSubscription={deleteSubscription}
            subscription={subscription}
          />
        ))}
      </div>
      {totalPages > 0 && (
        <Pagination
          className={styles.pagination}
          count={totalPages}
          page={params.page}
          onChange={(e, pageNumber) => handleChange(pageNumber)}
        />
      )}
    </>
  )
}
