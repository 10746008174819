import React, { Dispatch, SetStateAction } from 'react'
import { Button, TableCell, TableRow } from '@mui/material'
import EllipsisText from '@/components/elements/EllipsisText'
import useRejectModal from '@/components/elements/RejectModal'
import { moderationItemType } from '@/services/api/moderations/moderations.types'
import { updateAdminAnswer } from '@/services/api/moderations'
import { AxiosError } from 'axios'
import { getDateWithTime } from '@/helpers/DateFormatters'

interface IProps extends moderationItemType {
  setSubmitted: Dispatch<SetStateAction<boolean>>
  handleOpenFailedModal: () => void
  handleOpenSuccessModal: () => void
}

const FeedbackTable: React.FC<IProps> = ({
  id,
  entity,
  user,
  createdAt,
  statusId,
  reason,
  setSubmitted,
  handleOpenFailedModal,
  handleOpenSuccessModal,
}) => {
  const { handleOpen, RejectModal } = useRejectModal()
  const handleAcceptModeration = async () => {
    const answerData = {
      statusId: 2,
    }
    try {
      await updateAdminAnswer(answerData, id)
      setSubmitted((prev) => !prev)
      handleOpenSuccessModal()
    } catch (error) {
      if (error instanceof AxiosError) {
        handleOpenFailedModal()
        throw new Error(error.message)
      }
    }
  }
  const moderationStatus =
    statusId === 3
      ? 'Не принято'
      : statusId === 2
      ? 'Одобрено'
      : 'Не обработано'

  return (
    <>
      <RejectModal
        type="отзыв"
        id={id}
        setSubmitted={setSubmitted}
        handleOpenSuccessModal={handleOpenSuccessModal}
        handleOpenFailedModal={handleOpenFailedModal}
      />
      <TableRow>
        <TableCell component="th" scope="row">
          {id}
        </TableCell>
        <TableCell scope="row">{entity?.author?.firstName}</TableCell>
        <TableCell>{user.firstName}</TableCell>
        <TableCell align="left">{getDateWithTime(createdAt)}</TableCell>
        <TableCell align="left">
          {<EllipsisText len={10}>{entity?.message || '—'}</EllipsisText>}
        </TableCell>
        <TableCell align="center">{entity?.mark}/5</TableCell>
        <TableCell>{moderationStatus}</TableCell>
        <TableCell align="center">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
          >
            {reason ? (
              <p>{reason}</p>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={statusId !== 1}
                  onClick={handleAcceptModeration}
                >
                  Принять
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  disabled={statusId !== 1}
                  onClick={handleOpen}
                >
                  Отклонить
                </Button>
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}

export default FeedbackTable
