import { Dialog } from '../Dialog'
import classNames from 'classnames'
import { dialogType } from '@/types/Dialogs'
import styles from './DialogsList.module.scss'

interface Props {
  rooms: Map<number, dialogType[]> | Map<number, unknown>
  userId?: number
  inDialogWithUser: boolean
  selectedDialog?: string
  dialogsAmount: number
  id?: string
  dialogsUpdated: boolean
}

export const DialogsList = ({
  rooms,
  userId,
  inDialogWithUser,
  selectedDialog,
  dialogsAmount,
}: Props) => {
  const wrapperClassNames = classNames({
    [styles.dialogsList]: true,
    [styles.inDialogWithUserWrapper]: inDialogWithUser,
  })

  return (
    <>
      <section className={wrapperClassNames}>
        {dialogsAmount === 0 && (
          <p className={styles.chooseDialog}>
            Пока у вас нет активных диалогов
          </p>
        )}
        {Array.from(rooms, ([name, value]) => name && value).map(
          (room: dialogType | unknown) => {
            if (!userId) return
            if (!room) return
            const dialog = room as dialogType
            const otherUser =
              dialog?.user1Id === userId ? dialog?.user2 : dialog?.user1
            return (
              <Dialog
                key={dialog?.id}
                id={otherUser?.id}
                name={otherUser?.firstName}
                unreadMessagesCount={
                  (rooms.get(dialog?.id) as dialogType)?.unreadMessagesCount
                }
                selectedDialog={selectedDialog}
                avatar={otherUser?.avatar}
                lastMessage={
                  (rooms.get(dialog?.id) as dialogType)?.messages?.[0] ||
                  dialog?.messages?.[0]
                }
              />
            )
          }
        )}
      </section>
    </>
  )
}
