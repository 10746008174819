import { AxiosError } from 'axios'
import { axiosInstance } from '@/config/index'
import { createNewReviewType, getReviewsType } from './reviews.types'

export const getReviews: getReviewsType = async (userId) => {
  try {
    return axiosInstance.get(`/reviews/user/${userId}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const createNewReview: createNewReviewType = async (review) => {
  try {
    return axiosInstance.post(`/reviews`, { ...review })
  } catch (error: unknown) {
    if (error instanceof AxiosError) throw new Error(error.message)
  }
}
