import { FunctionComponent, SVGProps } from 'react'

import { ReactComponent as Heart } from '@assets/icons/heart.svg'
import { ReactComponent as Info } from '@assets/icons/info.svg'
import { ReactComponent as Messages } from '@assets/icons/message.svg'
import { ReactComponent as MyAnnouncements } from '@assets/icons/my_announcements.svg'
import { ReactComponent as Notification } from '@assets/icons/notification_bell.svg'
import { ReactComponent as Settings } from '@assets/icons/settings.svg'
import { ReactComponent as Star } from '@assets/icons/star.svg'
import { ReactComponent as User } from '@assets/icons/User.svg'

interface Route {
  link: string
  text: string
  svg: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >
  notifications?: number
}

export const routes: Route[] = [
  { link: '/profile/messages', text: 'Сообщения', svg: Messages },
  { link: '/favorites', text: 'Избранное', svg: Heart },
  {
    link: '/notifications',
    text: 'Уведомления',
    svg: Notification,
  },
  {
    link: '/profile/announcements',
    text: 'Мои объявления',
    svg: MyAnnouncements,
  },
  { link: '/setting-main', text: 'Настройки', svg: Settings },
  { link: '/pro', text: 'PRO-аккаунт', svg: Star },
]

export const adminRoutes: Route[] = [
  {
    link: '/admin-panel/feedback-moderation',
    text: 'Модерация отзывов',
    svg: Notification,
  },
  {
    link: '/admin-panel/advert-moderation',
    text: 'Модерация объявлений',
    svg: MyAnnouncements,
  },
  {
    link: '/admin-panel/hobbies',
    text: 'Хобби',
    svg: Star,
  },
  {
    link: '/admin-panel/complaint-moderation',
    text: 'Жалобы',
    svg: Info,
  },
  {
    link: '/admin-panel/blog-moderation',
    text: 'Блоги',
    svg: MyAnnouncements,
  },
  {
    link: '/admin-panel/theme-moderation',
    text: 'Темы новостей',
    svg: Star,
  },
  {
    link: '/admin-panel/users-moderation',
    text: 'Пользователи',
    svg: User,
  },
]
