import {
  createThemesType,
  deleteThemeType,
  getThemesType,
  updateThemesType,
} from './themes.types'

import { axiosInstance } from '@/config'

export const getThemes: getThemesType = (params) => {
  return axiosInstance.get('blog/themes', { params })
}

export const deleteTheme: deleteThemeType = (id) => {
  return axiosInstance.delete(`blog/themes/${id}`)
}

export const createTheme: createThemesType = (params) => {
  return axiosInstance.post(`blog/themes`, params)
}

export const updateTheme: updateThemesType = (params) => {
  return axiosInstance.put(`blog/themes`, params)
}
