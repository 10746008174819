import NavMenu from '@/components/layout-elements/sider/components/NavMenu'
import { Link } from 'react-router-dom'
import { ReactComponent as LilufLogo } from '@assets/icons/liluf.svg'
import styles from '../../admin-sider.module.scss'
import { LogoutButton } from '@/components/elements/buttons'

function AdminAuthed() {
  return (
    <div className={styles.authed_user}>
      <div className={styles.authed_user_top}>
        <div className={styles.sider_header}>
          <Link to={'/'}>
            <LilufLogo className={styles.liluf_logo} />
          </Link>
        </div>
      </div>
      <div className={`${styles.sider_block} ${styles.sider_block_menu}`}>
        <NavMenu />
      </div>
      <LogoutButton />
    </div>
  )
}

export default AdminAuthed
