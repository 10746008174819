import { Control, Controller, FieldValues } from 'react-hook-form'
import { FormControl, FormControlProps } from '@mui/material'

import MuiSwitch from '@mui/material/Switch'
import React from 'react'
import styles from './Switch.module.scss'

interface Props extends FormControlProps {
  name: string
  label?: string
  control?: Control<FieldValues> | undefined
  title?: string
  desc?: string
}

export const Switch = ({
  name,
  control,
  title,
  desc,
  label,
  ...props
}: Props) => {
  return (
    <div className={styles.switchContainer}>
      {title && (
        <div className={styles.switchDetails}>
          <p className={styles.switchTitle}>{title}</p>
          <p className={styles.switchDesc}>{desc}</p>
        </div>
      )}
      <FormControl {...props}>
        <Controller
          name={name}
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value } }) => {
            return (
              <div className={styles.switchWrap}>
                <label className={styles.switchLabel}>{label}</label>
                <MuiSwitch
                  className={styles.switch}
                  onChange={(e) => onChange(e.target.checked ? true : false)}
                  checked={value}
                />
              </div>
            )
          }}
        />
      </FormControl>
    </div>
  )
}
