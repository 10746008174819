import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { getCityType } from './getCity.types'

const url = process.env['REACT_APP_CITY_API_URL']
const token = process.env['REACT_APP_CITY_API_KEY']
const url1 = process.env['REACT_APP_CITY_BY_KLADR_ID_API_URL']
// const secret = process.env['REACT_APP_CITY_SECRET_KEY']

export const getCity: getCityType = async (query: string) => {
  try {
    const options: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + token,
      },
      data: JSON.stringify({
        query: query,
        city_type_full: 'город',
        type: 'ADDRESS',
        hint: false,
        bounds: 'city-settlement',
      }),
    }
    return await (
      await axios(url as string, options)
    ).data
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const getCityByKladrId: getCityType = async (query: string) => {
  try {
    const options: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + token,
      },
      data: JSON.stringify({
        query: query,
        city_type_full: 'город',
        type: 'ADDRESS',
        hint: false,
        bounds: 'city-settlement',
      }),
    }
    return await (
      await axios(url1 as string, options)
    ).data
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}
