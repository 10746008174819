import Modal from '@/components/modals/Modal/Modal'
import { backendUrl } from '@/config'
import { debounce } from '@/helpers/decorators/debounce'
import { createAdvert, updateAdvert } from '@/services/api/adverts'
import { getChargableServices } from '@/services/api/chargableServices'
import { getCity } from '@/services/api/getCity'
import {
  updateAdvertImage,
  uploadAdvertImage,
} from '@/services/api/upload/advert'
import { advertImageType, OutletContextType } from '@/types'
import { suggestions } from '@/types/getCity'
import { services } from '@/types/services'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'

import styles from './AddAnnouncement.module.scss'
import { AnnouncementForm } from './AnnouncementForm/AnnouncementForm'
import { PaidModule } from './PaidModule/PaidModule'
import { PaymentForm } from './PaymentForm/PaymentForm'
import { PreviewCard } from './PreviewCard'

function AddAnnouncement() {
  const [setTitle] = useOutletContext<OutletContextType>()
  const {
    control,
    register,
    watch,
    handleSubmit,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    criteriaMode: 'firstError',
    shouldFocusError: true,
  })
  const navigate = useNavigate()
  const [stage, setStage] = useState('edit')
  const [cities, setCities] = useState<suggestions>([])
  const [services, setServices] = useState<services>([])
  const [images, setImages] = useState<{ id: number; image: FormData }[]>([])
  const [defaultImages, setDefaultImages] = useState<advertImageType[]>([
    { id: 0, name: '' },
    { id: 1, name: '' },
    { id: 2, name: '' },
  ])
  const [showAnnouncementError, setShowAnnouncementError] = useState(false)
  const { state } = useLocation()
  const [previewUrl, setPreviewUrl] = useState('')
  const [openPaidModal, setOpenPaidModal] = useState(false)

  useEffect(() => {
    setTitle('Добавить объявление')

    getChargableServices().then(
      (chargableServices) =>
        chargableServices &&
        setServices(
          chargableServices.data.map((service) => ({
            ...service,
          }))
        )
    )
  }, [])

  useEffect(() => {
    if (state?.reset) {
      reset({})
      window.location.reload()
    }
  }, [state])

  const hightlightAnnouncements = watch(`${services?.[0]?.field}`)

  const topAnnouncements = watch(`${services?.[1]?.field}`)

  const showTopAnnouncements = watch(`${services?.[2]?.field}`)

  const total = useMemo(() => {
    let totalAmount = 0
    hightlightAnnouncements
      ? (totalAmount += 100)
      : totalAmount < 0 && (totalAmount -= 100)
    topAnnouncements
      ? (totalAmount += 120)
      : totalAmount < 0 && (totalAmount -= 120)
    showTopAnnouncements
      ? (totalAmount += 150)
      : totalAmount < 0 && (totalAmount -= 150)

    return totalAmount
  }, [hightlightAnnouncements, topAnnouncements, showTopAnnouncements])

  const submitHandler = async (data: any) => {
    if (stage !== 'final' && total !== 0 && stage === 'final' && total > 0) {
      setStage('final')
      return
    }

    let { name, info } = data

    const {
      address,
      cityName,
      showPhone,
      subcategoryId,
      serviceColor,
      serviceTop,
      serviceTopSign,
      budget,
    } = data

    name = name.charAt(0).toLocaleUpperCase() + name.slice(1)
    info = info.charAt(0).toLocaleUpperCase() + info.slice(1)

    const formattedData = {
      address,
      cityName,
      subcategoryId,
      name,
      info,
      serviceColor,
      serviceTop,
      serviceTopSign,
      messageAllow: true,
      showPhone: !!showPhone,
      ...(budget ? { budget } : {}),
    }

    Number(data.budget) && (formattedData.budget = Number(data.budget))

    const currCityData = cities.find((item) => item.value === data.cityName)
    console.log(currCityData, data.cityKladrId, 'submitted')
    if (!currCityData && !data.cityKladrId) {
      return setError('cityName', {
        type: 'custom',
        message: 'Выберите город из представленного списка',
      })
    }

    const cityId = currCityData?.data?.kladr_id || data.cityKladrId
    let redirectUrl
    try {
      if (state?.mode === 'edit') {
        const updatedAdvert = (
          await updateAdvert(state?.id, {
            ...formattedData,
            cityKladrId: cityId,
          })
        )?.data
        setOpenPaidModal(true)

        if (updatedAdvert) {
          await Promise.all(
            images.map(
              async (imageInfo) =>
                imageInfo.image &&
                (await updateAdvertImage(
                  imageInfo.id,
                  state?.id,
                  imageInfo.image
                ).catch(
                  async () =>
                    await uploadAdvertImage(state?.id, imageInfo.image)
                ))
            )
          )
        }
        redirectUrl = updatedAdvert?.redirectUrl
      } else {
        const newAdvert = (
          await createAdvert({
            ...formattedData,
            cityKladrId: cityId,
          })
        )?.data
        setOpenPaidModal(true)

        if (newAdvert) {
          await Promise.all(
            images.map(
              async (imageInfo) =>
                imageInfo.image &&
                (await uploadAdvertImage(newAdvert.id, imageInfo.image))
            )
          )
        }
        redirectUrl = newAdvert?.redirectUrl
      }
      if (redirectUrl) {
        reset({})
        window.open(redirectUrl)
        window.location.reload()
      }
    } catch (err) {
      console.error(err)
      setShowAnnouncementError(true)
    }
  }

  const closePaidModal = (addNew?: boolean) => {
    reset({})
    if (addNew) {
      setOpenPaidModal(false)
      window.location.reload()
    } else {
      setOpenPaidModal(false)
      navigate('/profile/announcements')
    }
  }

  const fetchCities = async (value: string) => {
    try {
      const data = await getCity(value)

      data && setCities(data.suggestions)
    } catch (e) {
      console.log(e)
    }
  }

  const debouncedFetchCities = debounce(
    async (value: string) => await fetchCities(value)
  )

  const closeAnnouncementError = () => {
    setShowAnnouncementError(false)
  }

  useEffect(() => {
    const firstImage = images
      .find((prev) => typeof prev?.id === 'number')
      ?.image?.get('photo') as File
    console.log(firstImage, defaultImages, previewUrl)

    if (firstImage) {
      setPreviewUrl(firstImage ? URL.createObjectURL(firstImage) : '')
    } else if (defaultImages[0].name) {
      setPreviewUrl(`${backendUrl}files/${defaultImages[0].name}`)
    }
  }, [images, defaultImages[0].name])
  return (
    <div className={styles.container}>
      <Modal
        open={showAnnouncementError}
        onClose={() => closeAnnouncementError()}
      >
        <div className={styles.module_container}>
          <p>Возникла ошибка, попробуйте позже</p>
        </div>
      </Modal>
      <PaidModule open={openPaidModal} onClose={closePaidModal} />
      <form onSubmit={handleSubmit(submitHandler)} className={styles.form}>
        <AnnouncementForm
          stage={stage}
          errors={errors}
          setValue={setValue}
          debouncedFetchCities={debouncedFetchCities}
          cities={cities}
          watch={watch}
          services={services}
          total={total}
          resetForm={reset}
          setImages={setImages}
          setDefaultImages={setDefaultImages}
          defaultImages={defaultImages}
          onSubmit={async () => {
            const city = watch('cityName')
            const cityKladrId = watch('cityKladrId')
            const currCityData = cities.find((item) => item.value === city)

            if (!cityKladrId && !currCityData) {
              setError('cityName', {
                type: 'custom',
                message: 'Выберите город из представленного списка',
              })
            }
            if (total === 0) {
              setStage('edit')
            } else {
              setStage('final')
            }
          }}
          control={control}
          register={register}
          id={state?.id}
          mode={state?.mode || ''}
        />
        <PaymentForm
          stage={stage}
          total={total}
          setStage={setStage}
          services={services}
          register={register}
          control={control}
          errors={errors}
          watch={watch}
        />
      </form>
      <PreviewCard watch={watch} url={previewUrl} />
    </div>
  )
}

export default AddAnnouncement
