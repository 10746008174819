import { BuyPro, RenewPro } from '@/components/pages/ProAccount/ui'
import { useEffect, useState } from 'react'

import { OutletContextType } from '@/types'
import { useAppSelector } from '@helpers/hooks'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import dayjs from 'dayjs'
import { PaidModule } from '@/components/pages/AddAnnouncement/PaidModule/PaidModule'
import { GreenButton } from '@/components/elements/buttons'

function ProAccount() {
  const [setTitle] = useOutletContext<OutletContextType>()
  const { userInfo, authLoading } = useAppSelector((state) => state.auth)

  const overdue = dayjs(userInfo?.proDate).diff(dayjs())
  const isCommonUser = userInfo?.roleId === 2 && !userInfo?.proDate
  const isOverdueProAccount = userInfo?.roleId === 3 && overdue > 0
  const isOverdueUserAccount = userInfo?.roleId === 2 && !!userInfo?.proDate
  const isProAccount = userInfo?.roleId === 3 && overdue <= 0
  const { search } = useLocation()
  const [openPaidModal, setOpenPaidModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setTitle('PRO-аккаунт')
  }, [])

  useEffect(() => {
    if (search === '?paymentSuccess=true') setOpenPaidModal(true)
  }, [search])

  const closePaidModal = () => {
    navigate('/pro', { state: { reset: true } })
    setOpenPaidModal(false)
  }

  if (authLoading) return null

  return <>
    {isCommonUser && <BuyPro />}
    {(isOverdueProAccount || isOverdueUserAccount) && <RenewPro />}
    {isProAccount && <RenewPro disabled />}

    <PaidModule
      open={openPaidModal}
      onClose={closePaidModal}
      text={"Благодарим вас за покупку Pro-аккаунта!"}
      footer={<GreenButton onClick={closePaidModal}>Закрыть</GreenButton>}
    />
  </>
}

export default ProAccount
