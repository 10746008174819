import { ReactComponent as AddSVG } from '@assets/icons/CircleCross.svg'
import { ButtonProps } from '@mui/material'
import { ReactComponent as InfoSVG } from '@assets/icons/info.svg'
import { LoadingButton } from '@mui/lab'
import { ReactNode } from 'react'
import { ReactComponent as TrashSVG } from '@assets/icons/trash.svg'
import classNames from 'classnames'
import styles from './action-button.module.scss'

type ButtonTypes = 'add' | 'delete' | 'info' | 'main' | 'switch'

interface Props extends ButtonProps {
  active?: boolean
  loading?: boolean
  types: ButtonTypes
}

const icons: Partial<Record<ButtonTypes, ReactNode>> = {
  add: <AddSVG />,
  delete: <TrashSVG />,
  info: <InfoSVG />,
}

export const ActionButton = ({
  active,
  loading,
  types,
  className,
  ...buttonProps
}: Props) => {
  const btnClass = classNames(
    {
      [styles.switchActive]: types === 'switch' && active,
    },
    styles[types]
  )

  return (
    <LoadingButton
      {...buttonProps}
      className={`${btnClass} ${className}`}
      disableRipple={types === 'delete' || types === 'info'}
      loading={loading}
      startIcon={icons[types]}
    />
  )
}
