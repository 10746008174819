import React, { useState, useEffect } from 'react'

import Modal from '@/components/modals/Modal/Modal'
import { RegisterFormSecondStep } from '@/components/forms/AuthForm/RegisterFormSecondStep'
import { useAppSelector } from '@/helpers/hooks'
import styles from './complete-signup.module.scss'

function CompletingSignUp() {
  const { userInfo } = useAppSelector((state) => state.auth)
  const [open, setOpen] = useState(false)

  const closeHandler = () => setOpen(false)

  useEffect(() => {
    if (userInfo && userInfo.age === 0) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [userInfo])

  return (
    <Modal open={open} onClose={closeHandler} className={styles.modal}>
      <RegisterFormSecondStep />
    </Modal>
  )
}

export default CompletingSignUp
