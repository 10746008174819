import { NavLink, useLocation } from 'react-router-dom'
import styles from './SettingsNav.module.scss'

function SettingsNav() {
  const location = useLocation()

  const handleActivatedLink = (route: string) => {
    if (route !== location.pathname)
      return `${styles.UnActivelink} ${styles.link}`
    return `${styles.isActive} ${styles.link}`
  }

  return (
    <ul className={styles.settingsNavList}>
      <li>
        <NavLink
          className={handleActivatedLink('/setting-main')}
          to="/setting-main"
        >
          <div className={styles.settingsNavItem}>Основные</div>
          <div className={styles.circle} />
        </NavLink>
      </li>
      <li>
        <NavLink
          className={handleActivatedLink('/setting-contacts')}
          to="/setting-contacts"
        >
          <div className={styles.settingsNavItem}>Контакты и пароль</div>
          <div className={styles.circle} />
        </NavLink>
      </li>
      <li>
        <NavLink
          className={handleActivatedLink('/setting-notifications')}
          to="/setting-notifications"
        >
          <div className={styles.settingsNavItem}>Уведомления</div>
          <div className={styles.circle} />
        </NavLink>
      </li>
    </ul>
  )
}

export default SettingsNav
