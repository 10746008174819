import { ReactComponent as ArrowIcon } from '@assets/icons/Arrow.svg'
import { Link } from 'react-router-dom'
import { ReactComponent as PhotoIcon } from '@assets/icons/photo.svg'
import { backendUrl } from '@/config'
import styles from './Header.module.scss'

interface Props {
  userInfo: {
    avatar: string
    firstName: string
  } | null
}

export const Header = ({ userInfo }: Props) => {
  return (
    <div className={styles.otherUserInfo}>
      <Link to={'/profile/messages'} className={styles.goBackContainer}>
        <ArrowIcon className={styles.goBackArrow} />
        Назад
      </Link>
      <span className={styles.username}>{userInfo?.firstName}</span>
      <div
        className={styles.avatar}
        style={{
          backgroundImage: `url(${backendUrl}files/${userInfo?.avatar})`,
        }}
      >
        {(!userInfo?.avatar || typeof userInfo?.avatar == 'object') && (
          <PhotoIcon className={styles.photoIcon} />
        )}
      </div>
    </div>
  )
}
