import { Dispatch, SetStateAction, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { AxiosError } from 'axios'
import FormError from '@/components/forms/elements/FormError/FormError'
import { GreenButton } from '@/components/elements/buttons'
import Modal from '@/components/modals/Modal/Modal'
import { TextInput } from '@/components/forms/elements/inputs'
import { createTheme } from '@/services/api/themes'
import styles from './add-new-theme.module.scss'

const AddNewTheme = () => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => {
    reset()
    setIsOpen(false)
    setModalMessage('')
  }

  const AddThemeModal = ({
    setSubmitted,
  }: {
    setSubmitted: Dispatch<SetStateAction<boolean>>
  }) => {
    const submitHandler = async (data: FieldValues) => {
      const { themeName } = data
      try {
        await createTheme({ name: themeName })
        setSubmitted((prev) => !prev)
        setModalMessage('Тема успешно добавлена')
        reset()
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.data.message === 'THEME_ALREADY') {
            setModalMessage('Эта тема уже существует. Попробуйте еще раз')
          } else {
            setModalMessage('Произошла ошибка повторите попытку еще раз')
          }
          reset()
          throw new Error(error.message)
        }
      }
    }

    return (
      <>
        <Modal onClose={handleClose} open={isOpen}>
          {modalMessage ? (
            <h4>{modalMessage}</h4>
          ) : (
            <form
              className={styles.form}
              onSubmit={handleSubmit(submitHandler)}
            >
              <h3>Введите название новой темы</h3>
              <TextInput
                inputProps={{
                  placeholder:
                    'В названии не должно содержаться нецензурной лексики',
                }}
                {...register('themeName', { required: 'Обязательное поле' })}
                control={control}
              />
              <FormError errors={errors} name="themeName" />
              <GreenButton type={'submit'}>Сохранить</GreenButton>
            </form>
          )}
        </Modal>
      </>
    )
  }
  return { handleOpen, AddThemeModal }
}

export default AddNewTheme
