import React from 'react'
import styles from './rating-progress.module.scss'

interface Props {
  rating: number | undefined
  className?: string
}

function RatingProgress({ rating, className }: Props) {
  return (
    <div className={`rating ${className}`}>
      <div className={styles.content__rating_text}>
        <span>Рейтинг</span>
        <span className={styles.rating_text__num}>{rating || 0.0}</span>
      </div>
      <div className={styles.content__rating_bar}>
        <progress max="5" value={rating}></progress>
      </div>
    </div>
  )
}

export default RatingProgress
