import { FieldValues, UseFormWatch } from 'react-hook-form'

import { Card } from '@/components/elements/Cards'
import styles from './PreviewCard.module.scss'

interface Props {
  watch: UseFormWatch<FieldValues>
  url: string
}

export const PreviewCard = ({ watch, url }: Props) => {
  return (
    <div>
      <div className={styles.cardPreview}>
        <p className={styles.cardPreviewTitle}>
          Как другие пользователи видят Ваше объявление в каталоге:
        </p>
        <Card
          id={0}
          preview={true}
          key={url}
          title={
            watch('name')
              ? watch('name')
              : 'Ищу партнера для совместной аренды офиса'
          }
          desc={
            watch('info')
              ? watch('info')
              : 'Офис в центре города разделен стеной пополам. Вход общий, но есть небольшой коридор, где стоит вешалка и общий...'
          }
          userId={0}
          liked={true}
          customUrl={url}
          blockButtons={true}
        />
      </div>
    </div>
  )
}
