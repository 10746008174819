import 'dayjs/locale/ru'

import React, { useEffect } from 'react'

import AppRouter from '@/components/Router'
import dayjs from 'dayjs'
import { socket } from '@/config'
import { useAppSelector } from '@helpers/hooks'

dayjs.locale('ru')

function App() {
  const { userInfo } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (
      userInfo &&
      socket?.io?.opts?.extraHeaders &&
      (socket?.io?.opts?.extraHeaders?.Authorization === 'null' ||
        !socket?.io?.opts?.extraHeaders?.Authorization)
    ) {
      socket.io.opts.extraHeaders.Authorization = `${localStorage.getItem(
        'actok'
      )}`
      socket.disconnect().connect()
    }

    if (userInfo) {
      socket.connect()

      socket.on('connect', () => {
        console.log('socket connected')
        if (socket?.io?.opts?.extraHeaders?.Authorization) {
          socket.io.opts.extraHeaders.Authorization = `${localStorage.getItem(
            'actok'
          )}`
        }
      })

      socket.on('connect_error', () => {
        if (socket?.io?.opts?.extraHeaders?.Authorization) {
          socket.io.opts.extraHeaders.Authorization = `${localStorage.getItem(
            'actok'
          )}`
        }
      })

      socket.on('disconnect', (reason) => {
        if (reason === 'io server disconnect') {
          console.log('socket disconnected')
          socket.connect()
        }
      })
    } else {
      console.log('socket disconnected')
      socket.disconnect()
      socket.removeAllListeners()
    }

    return () => {
      console.log('socket disconnected App')
      socket.disconnect()
      socket.removeAllListeners()
    }
  }, [userInfo])

  useEffect(() => {
    window.addEventListener('storage', (e) => console.log(e, 'w'))
  }, [])

  return (
    <>
      <AppRouter />
    </>
  )
}

export default App
