import { ReactComponent as FileIcon } from '@assets/icons/file.svg'
import { ReactComponent as Trash } from '@assets/icons/trash.svg'
import styles from './File.module.scss'

interface Props {
  name: string
  id: string
  onDelete: (id: string) => void
}

export const File = ({ name, id, onDelete }: Props) => {
  return (
    <div className={styles.file}>
      <div className={styles.file_fileInfo}>
        <FileIcon />
        <div>{name}</div>
      </div>
      <Trash className={styles.trash} onClick={() => onDelete(id)} />
    </div>
  )
}
