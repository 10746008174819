import React from 'react'
import styles from './header.module.scss'
import { Link } from 'react-router-dom'
import { useAppSelector } from '@helpers/hooks'

interface Props {
  title?: string
}

export function Header(props: Props) {
  const { title } = props
  const { userInfo, isAdmin } = useAppSelector((state) => state.auth)

  return (
    <div className={styles.header}>
      <h1 className={styles.header_title}>{title}</h1>
      <button className={styles.header_slogan}>
        Love, invent, live, be friend
      </button>
      {userInfo && !isAdmin && (
        <Link
          to="/profile/announcements/add"
          className={styles.add_announcement}
        >
          Добавить объявление
        </Link>
      )}
    </div>
  )
}
