import { useState } from 'react'
import styles from '../sider/sider.module.scss'
import Modal from '@/components/modals/Modal/Modal'
import ChangeUserCity from '@/components/forms/ChangeUserCity'
import AdminAuthed from './components/AdminAuthed'

export function AdminSider() {
  const [openCityModal, setOpenCityModal] = useState(false)
  const changeCityModal = () => {
    setOpenCityModal((prev) => !prev)
  }

  return (
    <>
      <div className={styles.sider}>
        <AdminAuthed />
      </div>
      <Modal onClose={changeCityModal} open={openCityModal}>
        <ChangeUserCity onFinish={changeCityModal} />
      </Modal>
    </>
  )
}
