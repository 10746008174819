import { axiosInstance } from '@/config';
import { AxiosError } from 'axios';

import { deleteFuncType, updateFuncType } from '../upload.types';
import { advertUploadType } from './advert.types';

export const uploadAdvertImage: advertUploadType = async (advertId, file) => {
  try {
    return axiosInstance.post(`upload/advert/${advertId}`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const deleteAdvertImage: deleteFuncType = async (id, advertId) => {
  try {
    return axiosInstance.delete(`upload/advert/${advertId}/${id}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const updateAdvertImage: updateFuncType = async (id, advertId,file) => {
  try {
    return axiosInstance.patch(`upload/advert/${advertId}/${id}`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}
