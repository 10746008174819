import { Link, useLocation } from 'react-router-dom'
import {
  adminRoutes,
  routes,
} from '@/components/layout-elements/sider/components/NavMenu/model/routes'
import {
  setUnreadMessages,
  setUnreadNotifications,
} from '@/store/slices/notifications'
import { useEffect, useState } from 'react'

import { ReactComponent as Heart } from '@assets/icons/heart.svg'
import { ReactComponent as MenuHamburger } from '@assets/icons/menu_hambuger.svg'
import { ReactComponent as Messages } from '@assets/icons/message.svg'
import { ReactComponent as MyAnnouncements } from '@assets/icons/my_announcements.svg'
import { ReactComponent as Notification } from '@assets/icons/notification_bell.svg'
import { ReactComponent as PhotoIcon } from '@assets/icons/photo.svg'
import { ReactComponent as LogoutIcon } from '@assets/icons/logout_2.svg'
import { backendUrl } from '@/config'
import { dialogMessageEntity } from '@/types/Dialogs'
import { getNotificationsCount } from '@/services/api/notifications'
import { socket } from '@/config'
import styles from './nav-menu.module.scss'
import { useAppSelector } from '@helpers/hooks'
import { useDispatch } from 'react-redux'
import { LogoutButton } from '@/components/elements/buttons'

function NavMenu() {
  const { userInfo, isAdmin } = useAppSelector((state) => state.auth)
  const count = useAppSelector((state) => state.notifications)
  const notificationsCount: { [key: string]: number } = {
    '/profile/messages': count.unreadMessages,
    '/notifications': count.unreadNotifications,
  }
  const dispatch = useDispatch()
  const [opened, setOpened] = useState(false)
  const { pathname, state } = useLocation()

  const changeMenuVisibility = () => {
    setOpened((prev) => !prev)
  }

  useEffect(() => {
    if (state?.closed) {
      setOpened(false)
    } else if (
      (opened && pathname !== '/') ||
      pathname === '/settings' ||
      pathname === '/pro' ||
      pathname === '/profile/announcements' ||
      pathname === '/notifications' ||
      pathname === '/favorites' ||
      pathname === '/profile/messages'
    ) {
      setOpened(true)
    }
  }, [pathname])

  useEffect(() => {
    const getAllNotificationsCount = async () => {
      const count = (await getNotificationsCount())?.data
      dispatch(
        setUnreadNotifications({
          type: 'custom',
          ammount: count.unreadNotifications,
        })
      )
      dispatch(
        setUnreadMessages({
          type: 'custom',
          ammount: count.unreadMessages,
        })
      )
    }
    getAllNotificationsCount()
    socket.on('notification', async (res: dialogMessageEntity) => {
      console.log(res)
      dispatch(
        setUnreadNotifications({
          type: 'custom',
          ammount: res.info.count.unreadNotifications,
        })
      )
      dispatch(
        setUnreadMessages({
          type: 'custom',
          ammount: res.info.count.unreadMessages,
        })
      )
    })
  }, [])

  useEffect(() => {
    if (isAdmin) setOpened(true)
  }, [isAdmin])

  return (
    <div className={styles.nav_menu}>
      {!opened && !isAdmin && (
        <div className={styles.short_menu}>
          <div className={styles.short_menu__item}>
            <Link to={'/profile/messages'} state={{ closed: true }}>
              <Messages />
            </Link>
          </div>
          <div
            className={`${styles.short_menu__item} ${styles.short_menu__item_heart}`}
          >
            <Link to={'/favorites'} state={{ closed: true }}>
              <Heart />
            </Link>
          </div>
          <div className={styles.short_menu__item}>
            <Link to={'/notifications'} state={{ closed: true }}>
              <Notification />
            </Link>
          </div>
          <div className={styles.short_menu__item}>
            <Link to={'/profile/announcements'} state={{ closed: true }}>
              <MyAnnouncements />
            </Link>
          </div>
        </div>
      )}

      {opened && (
        <div className={styles.detail_menu}>
          {!isAdmin ? (
            <>
              {routes.map((route, index) => (
                <div
                  className={
                    route.link === pathname
                      ? styles.detail_menu__item_active
                      : styles.detail_menu__item
                  }
                  key={`${index}_${route.link}`}
                >
                  <Link to={route.link} className={styles.item__link}>
                    <div
                      className={`${styles.link__image}${
                        route.link === '/favorites' ? ' ' + styles.link_fav : ''
                      }`}
                    >
                      <route.svg />
                    </div>
                    <p className={styles.link__text}>{route.text}</p>
                  </Link>
                  {notificationsCount[`${route.link}`] > 0 && (
                    <div className={styles.notifications}>
                      {notificationsCount[`${route.link}`]}
                    </div>
                  )}
                </div>
              ))}
              <div className={styles.detail_menu__item}>
                <a className={styles.item__link}>
                  <div className={`${styles.link__image}`}>
                    <LogoutIcon />
                  </div>
                  <LogoutButton
                    btnStyles={`${styles.logout} ${styles.link__text}`}
                  >
                    Выйти из аккаунта
                  </LogoutButton>
                </a>
              </div>
            </>
          ) : (
            adminRoutes.map((route, index) => (
              <div
                className={styles.detail_menu__item}
                key={`${index}_${route.link}`}
              >
                <Link to={route.link} className={styles.item__link}>
                  <div
                    className={`${styles.link__image}${
                      route.link === '/favorites' ? ' ' + styles.link_fav : ''
                    }`}
                  >
                    <route.svg />
                  </div>
                  <p className={styles.link__text}>{route.text}</p>
                </Link>
                {notificationsCount[`${route.link}`] > 0 && (
                  <div className={styles.notifications}>
                    {notificationsCount[`${route.link}`]}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      )}

      {!isAdmin && (
        <div className={styles.menu_hamburger}>
          <div className={styles.sider_tablet_menu__info}>
            <div
              className={styles.info_avatar}
              style={{
                backgroundImage: `url(${backendUrl}files/${userInfo?.avatar})`,
              }}
            >
              {(!userInfo?.photo ||
                (typeof userInfo?.photo == 'object' &&
                  !userInfo?.photo.length)) && (
                <PhotoIcon style={{ width: 12 }} />
              )}
            </div>
            <span
              style={{
                fontWeight: 600,
                fontSize: 12,
                color: '#3A3D42',
                marginRight: 10,
              }}
            >
              {userInfo?.firstName}, {userInfo?.age}
            </span>
            <span style={{ color: '#33A03E', fontSize: 12 }}>
              {userInfo?.average && `(${userInfo?.average})`}
            </span>
          </div>
          <button
            className={styles.menu_hamburger__btn}
            onClick={changeMenuVisibility}
          >
            <MenuHamburger />
          </button>
        </div>
      )}
    </div>
  )
}

export default NavMenu
