import { backendUrl } from '@/config'
import styles from './Profile.module.scss'

interface Props {
  image?: string
  name?: string
  age?: string | number
}

export const ShowProfile = ({ image, name, age }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        {image && (
          <img className={styles.image} src={`${backendUrl}files/${image}`} />
        )}
      </div>
      <div className={styles.infoContainer}>
        <p className={styles.info}>
          {name}, {age}
        </p>
        <p className={styles.show}>Как другие видят мой профиль?</p>
      </div>
    </div>
  )
}
