import { Box, ModalProps, Modal as MuiModal } from '@mui/material'

import { ReactComponent as Close } from '@assets/icons/Cross.svg'
import React from 'react'
import styles from './Modal.module.scss'

interface Props extends ModalProps {
  onClose: () => void
  Closeable?: boolean
  className?: string
  wrapClassName?: string
  rootClassName?: string
}

const Modal = ({
  open,
  onClose,
  children,
  Closeable = true,
  className,
  wrapClassName,
  rootClassName,
}: Props) => {
  return (
    <MuiModal
      disableAutoFocus={true}
      className={`${styles.container} ${rootClassName}`}
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick' && !Closeable) {
          console.log('asd')

          return false
        }
        onClose()
      }}
      aria-labelledby="modal"
      aria-describedby="modal"
    >
      <Box
        className={className}
        sx={{
          display: 'flex',
          gap: '9px',
          '&:focus-visible': {
            outline: 'none',
          },
        }}
      >
        <Box className={`${wrapClassName} ${styles.wrap}`}>{children}</Box>
        {Closeable && (
          <Close onClick={() => onClose()} className={styles.close} />
        )}
      </Box>
    </MuiModal>
  )
}

export default Modal
