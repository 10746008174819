import { FieldValues, useForm } from 'react-hook-form'
import React, { useState } from 'react'

import ForgotPasswordText from './ForgotPasswordText'
import FormError from '@/components/forms/elements/FormError/FormError'
import { GreenButton } from '@/components/elements/buttons'
import { ReactComponent as ScreamingSmile } from '@assets/icons/emojis/face-screaming.svg'
import { TextInput } from '@/components/forms/elements/inputs/TextInput'
import { forgotPassword } from '@/services/api/auth'
import styles from '../auth-form.module.scss'
import { trimField } from '@/helpers/form/trimField'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

// interface Props{
//   changeStep?: (step:number)=>void
// }

export function ForgotPasswordForm() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = useForm()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [reqSended, setReqSended] = useState(false)

  const submitHandler = async (data: FieldValues) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('login')

    try {
      await forgotPassword(data.email, token)

      setReqSended(true)
    } catch (error: unknown) {
      if (error instanceof Error) {
        switch (error.message) {
          case 'USER_EMAIL_NOT_CONFIRMED':
            setError('email', {
              type: 'custom',
              message: 'Введённая почта не подтверждена',
            })
            break
          case 'USER_DOES_NOT_EXISTS':
            setError('email', {
              type: 'custom',
              message: 'Пользователя с введённой почтой не существует',
            })
            break
          case 'string.email':
            setError('email', {
              type: 'custom',
              message: 'Введён невалидный email',
            })
            break
        }
      }
      setReqSended(false)
    }
  }

  if (reqSended) {
    return (
      <ForgotPasswordText
        title={'Сброс пароля'}
        text={
          'Письмо с ссылкой на сброс пароля отправлено на указанную Вами почту:'
        }
        mail={getValues('email')}
      />
    )
  }

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
        <h3 className={styles.form_title}>
          Забыли пароль? <ScreamingSmile />
        </h3>

        <p className={styles.form__text}>
          Введите email, на который зарегистрирован Ваш аккаунт и мы отправим
          туда ссылку для сброса пароля
        </p>

        <TextInput
          control={control}
          className={styles.form_input}
          {...register('email', {
            required: 'Обязательное поле',
            ...trimField({ name: 'email', setValue }),
          })}
          inputProps={{ type: 'email', placeholder: 'Email' }}
        />

        <FormError errors={errors} name="email" />

        <GreenButton className={styles.form_btn} type={'submit'}>
          Отправить
        </GreenButton>
      </form>
    </>
  )
}
