import React, { useState } from 'react'

import { GreenButton } from '@/components/elements/buttons'
import Modal from '@/components/modals/Modal/Modal'
import { Stars } from '@/components/forms/elements/ratings'
import { TextInput } from '@/components/forms/elements/inputs'
import { ThanksModal } from './ThanksModal'
import styles from './LeaveAReview.module.scss'
import { useForm } from 'react-hook-form'
import FormError from '../elements/FormError/FormError'
import { createNewReview } from '@/services/api/reviews'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'

interface Props {
  open: boolean
  onClose: () => void
  onCloseFinished?: () => void
}

export const LeaveAReview = ({ open, onCloseFinished, onClose }: Props) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const [openThanksModal, setOpenThanksModal] = useState(false)
  const { id } = useParams()
  const userId = Number(id)
  const toggleThanksModal = () => {
    setOpenThanksModal((prev) => {
      if (prev) {
        onCloseFinished && onCloseFinished()
        return false
      }
      return true
    })
  }

  const submitHandler = async (data: any) => {
    const review = { ...data, userId: userId }
    try {
      await createNewReview(review)
      toggleThanksModal()
    } catch (error) {
      if (error instanceof AxiosError) console.log(error.message)
    }
    reset({})
  }

  return (
    <Modal
      closeAfterTransition
      className={styles.container}
      open={open}
      onClose={onClose}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div>
        <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
          <strong>Оставить отзыв</strong>
          <Stars
            control={control}
            {...register('mark', { required: 'Выберите рейтинг' })}
          />
          <FormError errors={errors} name="stars" />
          <TextInput
            className={styles.textArea}
            inputProps={{
              multiline: true,
              placeholder: 'В тексте не должно содержаться оскорблений и мата',
            }}
            control={control}
            {...register('message')}
          />
          <GreenButton type="submit">Оставить отзыв</GreenButton>
        </form>

        {/* модальное окно после отправки отзыва */}
        <ThanksModal open={openThanksModal} onClose={toggleThanksModal} />
      </div>
    </Modal>
  )
}
