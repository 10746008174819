import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableWrapper from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'

interface Props {
  Header: React.ReactNode
  Body: React.ReactNode
  handleChangePage: (e: any, newPage: number) => void
  page: number
  count: number
  rowsPerPage: number
}
const TableContainer: React.FC<Props> = ({
  Header,
  Body,
  handleChangePage,
  page,
  rowsPerPage,
  count,
}) => {
  const render = () => {
    return (
      <>
        <TableHead sx={(theme) => ({ background: theme.palette.grey[100] })}>
          {Header}
        </TableHead>
        <TableBody>{Body}</TableBody>
      </>
    )
  }
  return (
    <TableWrapper component={Paper} sx={{ borderRadius: 5 }}>
      <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
        {render()}
      </Table>
      <TablePagination
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        onPageChange={handleChangePage}
      />
    </TableWrapper>
  )
}

export default TableContainer
