import React, { useEffect } from 'react'

import { ActionButton } from '@/components/elements/buttons'
import { BlogCardsList } from '@/components/elements/Cards/BlogCardsList'
import { ReactComponent as GreenLoadingArrow } from '@assets/icons/green-loading-arrow.svg'
import { OutletContextType } from '@/types'
import styles from './blogs.module.scss'
import useBlogData from '@/components/pages/Blogs/hooks/useBlogData'
import { useOutletContext } from 'react-router-dom'

function Blogs() {
  const [setTitle] = useOutletContext<OutletContextType>()
  const {
    blogNews,
    themes,
    currTheme,
    changeCurrTheme,
    canSeeMore,
    changePage,
  } = useBlogData({})

  console.log(canSeeMore)

  useEffect(() => {
    setTitle('Блог')
  }, [])

  return (
    <div className={styles.blog}>
      <div className={styles.blog_themes}>
        {themes?.map((theme) => (
          <ActionButton
            types="switch"
            active={currTheme === theme?.id}
            onClick={() => changeCurrTheme(theme.id)}
          >
            {theme?.name}
          </ActionButton>
        ))}
      </div>
      <div className={styles.cards}>
        <BlogCardsList cardsList={blogNews} />
      </div>
      {canSeeMore && (
        <button className={styles.see_more_btn} onClick={() => changePage()}>
          Загрузить еще <GreenLoadingArrow />
        </button>
      )}
    </div>
  )
}

export default Blogs
