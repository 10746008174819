import { userInfoType } from '@/types/user'
import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { getUsers } from '@/services/api/users'

export interface ModerationState {
  users: {
    items: Array<userInfoType>
    total: number
    currentPage: number
    totalPages: number
  }
}

const initialState: ModerationState = {
  users: {
    items: [],
    currentPage: 1,
    totalPages: 0,
    total: 0,
  },
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = { items: action.payload.users, ...action.payload }
    },
  },
})

export const fetchUsers =
  (params: { limit: number; page: number }) => async (dispatch: Dispatch) => {
    const response = await getUsers(params)
    dispatch(setUsers(response?.data))
  }

// Action creators are generated for each case reducer function
export const { setUsers } = usersSlice.actions
export default usersSlice.reducer
