import { Dispatch, SetStateAction, useCallback, useState } from 'react'

type changeImageType = (firstImageId: number) => void

export const useImageModal = (defaultOpen?: boolean): [boolean, number, () => void, changeImageType, changeImageType, Dispatch<SetStateAction<boolean>>] => {
  const [open, setOpen] = useState(defaultOpen || false);
  const [firstImageId, setFirstImageId] = useState<number>(0)

  const toggleModal = useCallback(()=>{
    setOpen(prev=>!prev)
  },[])

  const changeFirstImageId = useCallback((firstImageId: number)=>{
    setFirstImageId(firstImageId)
  },[])

  const onImageClick = useCallback((firstImageId: number)=>{
    changeFirstImageId(firstImageId)
    toggleModal()
  },[])

  return [open, firstImageId, toggleModal, onImageClick, changeFirstImageId, setOpen]
}