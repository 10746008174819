import Modal from '@/components/modals/Modal/Modal'
import { Dispatch, SetStateAction, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { GreenSelect } from '@/components/forms/elements/selects/GreenSelect/GreenSelect'
import { TextInput } from '@/components/forms/elements/inputs'
import FormError from '@/components/forms/elements/FormError/FormError'
import { GreenButton } from '../buttons'
import styles from './RejectModal.module.scss'
import { updateAdminAnswer } from '@/services/api/moderations'
import { AxiosError } from 'axios'
const HandleRejectModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()
  const watchCategory = watch('category', false)

  const reasonsList = [
    {
      id: 'Оскорбление',
      value: 'Оскорбление',
    },
    {
      id: 'Мат',
      value: 'Мат',
    },
    {
      id: 'Другое',
      value: 'Другое',
    },
  ]

  const RejectModal = ({
    type,
    id,
    setSubmitted,
    handleOpenFailedModal,
    handleOpenSuccessModal,
  }: {
    type: string
    id: number
    setSubmitted: Dispatch<SetStateAction<boolean>>
    handleOpenFailedModal: () => void
    handleOpenSuccessModal: () => void
  }) => {
    const submitHandler = async (data: FieldValues) => {
      if (watchCategory !== 'Другое') delete data.comment
      const answerData = {
        statusId: 3,
        reason: watchCategory === 'Другое' ? data.comment : data.category,
      }

      try {
        await updateAdminAnswer(answerData, id)
        setSubmitted((prev) => !prev)
        handleClose()
        reset()
        handleOpenSuccessModal()
      } catch (error) {
        if (error instanceof AxiosError) {
          handleOpenFailedModal()
          throw new Error(error.message)
        }
      }
    }
    return (
      <>
        <Modal onClose={handleClose} open={isOpen}>
          <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
            <h4>Оставьте причину отказа</h4>
            <p>
              Пожалуйста, <span className={'green'}>укажите причину</span> по
              которой хотите отклонить {type}
            </p>
            <GreenSelect
              control={control}
              items={reasonsList}
              placeholder="Категория"
              {...register('category', { required: 'Обязательное поле' })}
            />
            {watchCategory === 'Другое' && (
              <>
                <p>Комментарий</p>
                <TextInput
                  inputProps={{
                    placeholder:
                      'В тексте не должно содержаться оскорблений и мата',
                    multiline: true,
                  }}
                  {...register('comment', { required: 'Обязательное поле' })}
                  control={control}
                />
                <FormError errors={errors} name="comment" />
              </>
            )}
            <GreenButton type={'submit'}>Оставить комментарий</GreenButton>
          </form>
        </Modal>
      </>
    )
  }
  return { handleOpen, RejectModal }
}

export default HandleRejectModal
