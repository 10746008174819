import TableContainer from '@/components/elements/TableContainer'
import Modal from '@/components/modals/Modal/Modal'
import { useAppSelector } from '@/helpers/hooks'
import { getModeration } from '@/services/api/moderation'
import { TableCell, TableRow } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import FeedbackTable from './FeedbackTable'

const FeedbackModeration = () => {
  const { allModeration } = useAppSelector((state) => state.moderation)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [params, setParams] = useState({
    page: 0,
    limit: 10,
    entityType: 'review',
  })
  useEffect(() => {
    getModeration(params)
  }, [params, submitted])

  const handleChangePage = (_: unknown, newPage: number) => {
    setParams((prev) => ({ ...prev, page: newPage }))
  }

  const handleClose = () => setIsOpen((prev) => !prev)
  const handleOpenSuccessModal = () => {
    setIsOpen(true)
    setIsSuccess(true)
  }
  const handleOpenFailedModal = () => {
    setIsOpen(true)
    setIsSuccess(false)
  }

  const renderList = useMemo(
    () =>
      allModeration.items.map((feedback) => (
        <FeedbackTable
          handleOpenSuccessModal={handleOpenSuccessModal}
          handleOpenFailedModal={handleOpenFailedModal}
          key={feedback.id}
          {...feedback}
          setSubmitted={setSubmitted}
        />
      )),
    [allModeration.items]
  )
  return (
    <>
      <Modal onClose={handleClose} open={isOpen}>
        <h4>
          {!isSuccess
            ? 'Произошла ошибка, повторите еще раз'
            : 'Ответ успешно записан'}
        </h4>
      </Modal>
      <h2 style={{ marginBottom: 20 }}>Модерация отзывов</h2>
      <TableContainer
        Header={
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>От кого: </TableCell>
            <TableCell>Кому: </TableCell>
            <TableCell>Дата и время: </TableCell>
            <TableCell>Отзыв: </TableCell>
            <TableCell align="center">Оценка: </TableCell>
            <TableCell>Статус: </TableCell>
            <TableCell>Ответ модератора: </TableCell>
          </TableRow>
        }
        Body={renderList}
        handleChangePage={handleChangePage}
        page={params.page}
        rowsPerPage={params.limit}
        count={allModeration.total}
      />
    </>
  )
}

export default FeedbackModeration
