export const MenuStyle = {
  '& .MuiPopover-paper': {
    borderRadius: '8px',
    boxShadow: '0px 4px 62px rgba(113, 140, 114, 0.48)',
  },
  '& .MuiMenu-list': {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
  },
  '& .MuiMenuItem-gutters': {
    fontSize: '0.8rem',
    fontFamily: "'Raleway', sans-serif",
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'space-between',
    transition: '300ms ease',
    '&:hover': {
      backgroundColor: '#F3F5F3',
      '& .circle': {
        opacity: 1,
      },
    },
  },
  '& .MuiButtonBase-root': {
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: '#F3F5F3 !important',
    },
  },
  '& .Mui-selected': {
    backgroundColor: '#F3F5F3 !important',
    '& .circle': {
      opacity: 1,
    },
  },
  '& .circle': {
    width: '9px',
    borderRadius: '5px',
    height: '9px',
    backgroundColor: '#809c81',
    opacity: 0,
    transition: '100ms ease',
  },
}
