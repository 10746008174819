import { SubscriptionType } from '@/types/subscription'
import { createSlice } from '@reduxjs/toolkit'

const initialState: initialStateType = {
  subscriptions: {
    items: [],
    total: 0,
  },
}

interface initialStateType {
  subscriptions: {
    items: SubscriptionType[]
    total: number
  }
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setAllSubscriptions: (state, action) => {
      state.subscriptions = action.payload
    },
  },
})

export const { setAllSubscriptions } = subscriptionSlice.actions
export default subscriptionSlice.reducer
