import React from 'react'
import styles from '../auth-form.module.scss'

interface Props {
  mail: string
}

function ConfirmMail({mail}: Props) {
  return (
    <div className={styles.form}>
      {/* <h3 className={styles.form_title}>Спасибо за регистрацию!</h3>
      <p>Войдите в ваш аккаунт чтобы продолжить работу.</p> */}
      <h3 className={styles.form_title}>Подтвердите почту</h3>
      <p className={styles.form__text}>
        Письмо с подтверждением отправлено на указанную Вами почту:
      </p>
      <div className={`${styles.form__text} ${styles.form__text_m0}`}>
        <span className={styles.green2}>{mail}</span>
      </div>
    </div>
  )
}

export default ConfirmMail
