import { ReactComponent as Arrow } from '@assets/icons/Arrow.svg'
import React from 'react'
import { Link } from 'react-router-dom'

import styles from './GoBackTitle.module.scss'

interface Props {
  route?: string
  text: string
  onClick?: () => void
  className?: string
}

export const GoBackTitle = ({ route, text, onClick, className }: Props) => {
  return (
    <Link
      onClick={() => {
        onClick && onClick()
      }}
      className={`${className} ${styles.GoBackTitle}`}
      to={route || ''}
    >
      <Arrow className={styles.arrow} />
      {text}
    </Link>
  )
}
