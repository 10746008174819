import { Link } from 'react-router-dom'
import PaymentLogos from '@assets/icons/paymentLogos.svg'
import styles from './footer.module.scss'
import { useAppDispatch, useAppSelector } from '@helpers/hooks'
import { showModalStep } from '@store/slices/auth'
import Modal from '@/components/modals/Modal/Modal'
import { useState } from 'react'

export function Footer() {
  const { userInfo } = useAppSelector((state) => state.auth)
  const [openProModal, setOpenProModal] = useState(false)
  const dispatch = useAppDispatch()

  return (
    <>
      <div className={styles.footer_wrapper}>
        <div className={styles.footer}>
          <div className={styles.footer__copyright}>
            <span>
              <span className={styles.left__num}>2022.</span>
              <span>LILUF</span>
            </span>
            <Link
              to={'/privacy-policy'}
              target={'_blank'}
              className={styles.footer_link}
            >
              Политика конфиденциальности
            </Link>
            <Link
              to={'/order-cancellation'}
              target={'_blank'}
              className={styles.footer_link}
            >
              Отказ от услуги
            </Link>
            <Link
              to={'/public-offer'}
              target={'_blank'}
              className={styles.footer_link}
            >
              Публичная оферта
            </Link>
          </div>

          <div className={styles.footer__menu}>
            <Link
              to={userInfo ? '/pro' : ''}
              className={styles.footer_link}
              onClick={() => !userInfo && setOpenProModal(true)}
            >
              PRO-аккаунт
            </Link>
            <Link to={'/blog'} className={styles.footer_link}>
              Блог
            </Link>
            <Link to={'/support-service'} className={styles.footer_link}>
              Служба поддержки
            </Link>
          </div>
        </div>
        <div className={styles.footer_bottom}>
          <div className={styles.footer_bottom__wrap}>
            <div className={styles.footer_bottom__left}>
              <p>
                ИП Саввин Н.В. Регистрационный номер ИП – 312774604500205 ИНН
                773720506262
              </p>
              <p>г. Москва, ул. Южнопортовая 21с7</p>
            </div>
            <div>
              <div className={styles.footer_bottom__left}>
                <a href="tel:+74952155600">7 (495) 215-56-00</a>
                <a href="mailto:help@liluf.ru">help@liluf.ru</a>
              </div>
            </div>
          </div>

          <img src={PaymentLogos} alt="payment logos" />
        </div>
      </div>

      <Modal
        className={styles.modal}
        open={openProModal}
        onClose={() => setOpenProModal(false)}
      >
        <div>
          <span>
            Для просмотра информации о PRO-аккаунте необходимо войти или{' '}
            <a
              className={styles.link}
              onClick={() => {
                setOpenProModal(false)
                dispatch(showModalStep(2))
              }}
            >
              зарегистрироваться
            </a>{' '}
            на сайте{' '}
          </span>
        </div>
      </Modal>
    </>
  )
}
