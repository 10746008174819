  import React, { useState } from 'react'
import { addFavoriteUser, unFavoriteUser } from '@/services/api/favorites'

import { ReactComponent as HeartIcon } from '@assets/icons/heart.svg'
import { Link } from 'react-router-dom'
import { ReactComponent as PhotoIcon } from '@assets/icons/photo.svg'
import RatingProgress from '../RatingProgress'
import { backendUrl } from '@/config'
import classNames from 'classnames'
import styles from './profile-info.module.scss'
import PhotoSliderModal from '@/components/modals/PhotoSliderModal'
import { useImageModal } from '@helpers/hooks/useImageModal'

interface Props {
  photo: string | null
  name: string
  age: number | string
  rating: number
  phone?: string | number
  userId: number
  isLiked?: boolean
  canFavorite?: boolean
  onFavoriteChange?: () => void
  forceLike?: boolean
  className?: string
  forbidden?: boolean
  onEmptyAvatarClick?: () => void;
  isAvatar?: boolean;
}

function ProfileShortInfo({
  photo,
  age,
  rating,
  name,
  userId,
  isLiked,
  canFavorite,
  forceLike,
  onFavoriteChange,
  className,
  onEmptyAvatarClick,
  isAvatar,
  forbidden,
}: Props) {
  const [liked, setLiked] = useState(isLiked)
  const [open, firstImageId, toggleModal, onImageClick] = useImageModal()

  const likeClass = classNames({
    [styles.profile_info__like]: true,
    [styles.profile_info__liked]: forceLike ? forceLike : liked,
  })
  const wrapperClass = classNames({
    [styles.profile_info]: true,
    [styles.profileFavorite_info]: canFavorite ? canFavorite : false,
  })

  const handleAddToFavorite = async () => {
    if (!canFavorite) return
    await addFavoriteUser(userId)
    setLiked(true)
    onFavoriteChange && onFavoriteChange()
  }
  const handleUnFavoriteUser = async () => {
    if (!canFavorite) return
    await unFavoriteUser(userId)
    !forceLike && setLiked(false)
    onFavoriteChange && onFavoriteChange()
  }

  return (
    <div className={`${className} ${wrapperClass}`}>
      <div
        className={`avatar ${styles.profile_info__avatar}`}
        style={{ backgroundImage: `url(${backendUrl}files/${photo})`, cursor: photo || isAvatar ? "pointer" : "default" }}
        onClick={photo ? ()=>onImageClick(0) : isAvatar ? () => onEmptyAvatarClick && onEmptyAvatarClick() : undefined}
      >
        {(!photo || (typeof photo == 'object')) && (
          <PhotoIcon className={styles.photoIcon} />
        )}
      </div>
      <div className={styles.profile_info__content}>
        {forbidden ? (
          <p style={{ cursor: 'default'}} className={`info ${styles.content__name}`}>
            {name && `${name},`} <span className={styles.name__num}>{age}</span>
          </p>
        ) : (
          <Link
            to={`/profile/${userId}`}
            style={{ textDecoration: 'none' }}
          >
            <p className={`info ${styles.content__name}`}>
              {name && `${name},`}{' '}
              <span className={styles.name__num}>{age}</span>
            </p>
          </Link>
        )}
        <RatingProgress
          className={styles.profile_info__rating}
          rating={rating}
        />
        <p className={`rating ${styles.profile_info__mobileRating}`}>
          ({rating || 0.0})
        </p>
        {canFavorite && (
          <div className={styles.profile_info__favorite}>
            <HeartIcon
              className={likeClass}
              onClick={() => {
                liked ? handleUnFavoriteUser() : handleAddToFavorite()
              }}
            />{' '}
            <p>В избранное</p>
          </div>
        )}
      </div>

      <PhotoSliderModal open={open} onClose={toggleModal} firstImageId={firstImageId} images={[{ id:0, name: photo || "" }]} />
    </div>
  )
}

export default ProfileShortInfo
