import { createSlice } from '@reduxjs/toolkit'
import { hobbiesType, allHobbiesAdminType } from '@/types/Hobbies'

export interface AuthState {
  allHobbies: hobbiesType[] | [],
  allHobbiesAdmin: allHobbiesAdminType,
}

const initialState: AuthState = {
  allHobbies: [],
  allHobbiesAdmin: {
    items: [],
    total: 0
  }
}

export const hobbiesSlice = createSlice({
  name: 'hobbies',
  initialState,
  reducers: {
    setAllHobbies: (state, action) => {
      state.allHobbies = action.payload
    },
    setAllHobbiesAdmin: (state, action) => {
      state.allHobbiesAdmin = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAllHobbies, setAllHobbiesAdmin } = hobbiesSlice.actions;
export default hobbiesSlice.reducer
