import { store } from '@/store'
import { setAllHobbies, setAllHobbiesAdmin } from '@/store/slices/hobbies'
import { getHobbies, getHobbiesAdmin } from './hobbies/hobbies'

export const getAllHobbies = async (name?: string) => {
  const response = await getHobbies(name)
  store.dispatch(setAllHobbies(response?.data))
}

export const getHobbiesForAdmin = async (qParams: {
  page: number
  limit: number
}) => {
  const tempParams = { ...qParams, page: qParams.page + 1 }
  const response = await getHobbiesAdmin(tempParams)
  store.dispatch(setAllHobbiesAdmin(response?.data))
}
