import { FieldValues, useForm } from 'react-hook-form'
import { SearchInput } from '@/components/forms/elements/inputs'
import { debounce } from '@helpers/decorators/debounce'

import styles from './change-user-city.module.scss'
import { GreenButton } from '@/components/elements/buttons'
import FormError from '@/components/forms/elements/FormError/FormError'
import { updateUserCity } from '@services/api/user'
import { useAppSelector } from '@helpers/hooks'
import { getAllCities } from '@/services/api/cities'

interface Props {
  onFinish?: () => void
}

function ChangeUserCity({ onFinish }: Props) {
  const { userInfo } = useAppSelector((state) => state.auth)
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setError,
  } = useForm()

  const { cities } = useAppSelector((state) => state.cities)

  const fetchCities = async (value: string) => {
    try {
      await getAllCities(value)
    } catch (e) {
      console.log(e)
    }
  }
  const debouncedFetchCities = debounce((value: string) => fetchCities(value))

  const submitHandler = (data: FieldValues) => {
    const currCityData = cities.find((item) => item.value === data.cityName)
    if (!currCityData) {
      return setError('cityName', {
        type: 'custom',
        message: 'Выберите город из представленного списка',
      })
    }

    const { kladr_id } = currCityData.data

    updateUserCity({
      userId: userInfo!.id,
      cityName: currCityData.value,
      cityKladrId: kladr_id,
    })

    if (onFinish) {
      onFinish()
    }
  }
  return (
    <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
      <h2 className={styles.form_title}>Укажите свой город</h2>
      <p className={styles.form_text}>Укажите город, который требуется найти</p>
      <div className={styles.form_city}>
        <SearchInput
          options={cities.map(({ value }) => value)}
          control={control}
          className={styles.search_input}
          OnTextChange={(value) => {
            setValue('cityName', value)
            debouncedFetchCities(value)
          }}
          placeholder="Город"
          inputProps={{
            placeholder: 'Город',
          }}
          {...register('cityName', {
            required: 'Укажите город',
          })}
        />
      </div>
      <FormError errors={errors} name="cityName" />

      <GreenButton type={'submit'}>Сменить</GreenButton>
    </form>
  )
}

export default ChangeUserCity
