import { AxiosError } from 'axios'
import { axiosInstance } from '@/config'
import { getChargableServicesType } from './chargableServices.types'

export const getChargableServices: getChargableServicesType = async () => {
  try {
    return await axiosInstance.get('/chargeable-services')
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}
