const QueryParamsParser = (queryBaseStr: string, qParams: any = {}) => {
  const qParamsArr: any = []
  for (const key in qParams) {
    if (
      (qParams[key] !== null &&
        qParams[key] !== '' &&
        qParams[key] !== undefined) ||
      qParams[key] === false
    ) {
      qParamsArr.push(`${key}=${qParams[key]}`)
    }
  }
  if (qParamsArr.length) {
    qParamsArr.forEach((p: any, i: any) => {
      if (i === 0) {
        queryBaseStr += `?${p}`
      } else {
        queryBaseStr += `&${p}`
      }
    })
  }
  return queryBaseStr
}

export default QueryParamsParser
