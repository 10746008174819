import { Button, TableCell, TableRow } from '@mui/material'

import { BlogNews } from '@/services/api/blog'
import EllipsisText from '@/components/elements/EllipsisText'
import React from 'react'
import { getDateWithTime } from '@/helpers/DateFormatters'

interface IProps extends BlogNews {
  handleEdit: (id: number, slug: string) => void
  handleConfirm: (id: number) => void
}
const BlogTable: React.FC<IProps> = ({
  id,
  title,
  createdAt,
  handleConfirm,
  handleEdit,
  slug,
}) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell align="left">
        {<EllipsisText len={30}>{title}</EllipsisText>}
      </TableCell>
      <TableCell>{getDateWithTime(createdAt)}</TableCell>
      <TableCell align="center">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <>
            <Button
              variant="contained"
              color="info"
              size="small"
              onClick={() => handleEdit(id, slug)}
            >
              Редактировать
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => handleConfirm(id)}
            >
              Удалить
            </Button>
          </>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default BlogTable
