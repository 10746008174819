import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as LilufLogo } from '@assets/icons/liluf.svg'
import { ReactComponent as LocationPinIcon } from '@assets/icons/location_pin.svg'
import NavMenu from '@/components/layout-elements/sider/components/NavMenu'
import { ReactComponent as PhotoIcon } from '@assets/icons/photo.svg'
import ProAccountBanner from '@/components/layout-elements/sider/components/ProAccountBanner'
import ProfileShortInfo from '@/components/elements/ProfileShortInfo'
import classNames from 'classnames'
import { routes } from '@/components/layout-elements/sider/components/NavMenu/model/routes'
import styles from '../../sider.module.scss'
import { useAppSelector } from '@helpers/hooks'
import { backendUrl } from '@/config'
import { useRef, useState } from 'react'
import Modal from '@/components/modals/Modal/Modal'
import { uploadFirstAvatar } from '@/services/api/upload/user'

interface Props {
  changeCityModal: () => void
}

function UserAuthed({ changeCityModal }: Props) {
  const { userInfo } = useAppSelector((state) => state.auth)
  const { pathname } = useLocation()
  const [closed, setClosed] = useState(false)
  const [formatNotSupported, setFormatNotSupported] = useState<boolean>(false)
  const [sizeNotSupported, setSizeNotSupported] = useState<boolean>(false)
  const [avatar, setAvatar] = useState<string>('')
  const [, setAvatarLoading] = useState(false)

  const avatarInputRef = useRef<HTMLInputElement>(null)

  const proBannerClass = classNames({
    [styles.mobile_sider_block]: true,
    [styles.mobile_sider_block_onProPage]: pathname === '/pro',
  })
  const handleOpenAndClose = () => setClosed((prev) => !prev)

  const onEmptyAvatarClick = () => {
    if (!avatarInputRef?.current) return
    avatarInputRef?.current?.click()
  }

  const onAvatarFileChange = async (file: File) => {
    const Image = new FormData()
    Image.append('photo', file)
    const megaBytes: number = +(file.size / 1048576).toFixed(2)
    if (megaBytes > 10) return setSizeNotSupported(true)
    setAvatarLoading(true)
    Image.append('order', '1')
    try {
      const uploadedImage = await uploadFirstAvatar(Image).then((res) => {
        console.log(res)
        return res?.data?.photo?.filename
      })
      setAvatar(uploadedImage)
    } catch (e) {
      console.log(e)
    }
    setAvatarLoading(false)
  }

  const onModalClose = (stateFunc: any) => {
    stateFunc(false)
  }

  console.log(avatar, userInfo?.avatar)

  return (
    <div className={styles.authed_user}>
      <div className={styles.authed_user_top}>
        <div className={styles.sider_header}>
          <Link
            to={'/'}
            onClick={() => {
              setTimeout(() => {
                window.location.reload()
              }, 0)
            }}
          >
            <LilufLogo className={styles.liluf_logo} />
          </Link>
          {userInfo && userInfo.cityName && (
            <button
              className={styles.header__country}
              onClick={changeCityModal}
            >
              <LocationPinIcon /> <span>{userInfo.cityName}</span>
            </button>
          )}
        </div>

        <div className={styles.sider_tablet_menu}>
          <div className={styles.sider_tablet_menu__info}>
            <div
              className={styles.info_avatar}
              style={{
                backgroundImage: `url(${backendUrl}files/${userInfo?.avatar})`,
              }}
            >
              {(!userInfo?.photo ||
                (typeof userInfo?.photo == 'object' &&
                  !userInfo?.photo.length)) && (
                <PhotoIcon style={{ width: 12 }} />
              )}
            </div>
            <span
              style={{
                fontWeight: 600,
                fontSize: 12,
                color: '#3A3D42',
                marginRight: 10,
              }}
            >
              {userInfo?.firstName}, {userInfo?.age}
            </span>
            <span style={{ color: '#33A03E', fontSize: 12 }}>
              {userInfo?.average && `(${userInfo?.average})`}
            </span>
          </div>

          <div className={styles.sider_tablet_menu__links}>
            {routes.map((route, id) => (
              <div
                className={styles.detail_menu__item}
                key={`${id}_${route.text}`}
              >
                <Link to={route.link} className={styles.item__link}>
                  <div
                    className={`${styles.link__image}${
                      route.link === '/favorites' ? ' ' + styles.link_fav : ''
                    }${
                      route.link === '/settings' || route.link === '/pro'
                        ? ' ' + styles.link_big
                        : ''
                    }`}
                  >
                    <route.svg />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.sider_mobile_menu}>
          <NavMenu />
        </div>
      </div>
      <input
        onChange={async (event) => {
          if (!event.target.files && !event?.target?.files?.[0]) return
          const file = event.target.files[0]
          if (
            file.type !== 'image/jpeg' &&
            file.type !== 'image/png' &&
            file.type !== 'image/heic'
          )
            return setFormatNotSupported(true)
          onAvatarFileChange(file)
        }}
        hidden
        accept="image/*"
        multiple
        type="file"
        className={styles.sider_avatar_input}
        ref={avatarInputRef}
        name="userFirstAvatar"
      />
      <div className={`${styles.sider_block} ${styles.sider_block_short_info}`}>
        {userInfo && (
          <ProfileShortInfo
            name={userInfo?.firstName}
            age={userInfo?.age}
            rating={userInfo?.average}
            photo={avatar || userInfo?.avatar}
            userId={userInfo?.id}
            onEmptyAvatarClick={onEmptyAvatarClick}
            isAvatar
          />
        )}
      </div>
      <div className={`${styles.sider_block} ${styles.sider_block_menu}`}>
        <NavMenu />
      </div>

      <div
        className={`${styles.sider_block} ${styles.sider_block_pro}`}
        style={closed ? { display: 'none' } : {}}
      >
        <ProAccountBanner handleOpenAndClose={handleOpenAndClose} />
      </div>
      <div className={proBannerClass} style={closed ? { display: 'none' } : {}}>
        <ProAccountBanner handleOpenAndClose={handleOpenAndClose} />
      </div>
      <Modal
        open={formatNotSupported}
        onClose={() => onModalClose(setFormatNotSupported)}
      >
        <p>
          Данный формат фото не поддерживается, загрузите изображение в формате
          .jpeg, .jpg, .png, .heic (heif)
        </p>
      </Modal>
      <Modal
        open={sizeNotSupported}
        onClose={() => onModalClose(setSizeNotSupported)}
      >
        <p>
          Загружаемое изображение должно быть меньше 10Мб.
          <br />
          Повторите попытку снова
        </p>
      </Modal>
    </div>
  )
}

export default UserAuthed
