import {
  addFavoriteUserType,
  addToFavoritesType,
  getFavoriteAdvertsType,
  getFavoriteUsersType,
  unFavoriteType,
  unFavoriteUserType,
} from './favorites.types'

import { AxiosError } from 'axios'
import { axiosInstance } from '@/config'

export const getFavoriteAdverts: getFavoriteAdvertsType = async () => {
  try {
    return axiosInstance.get('/adverts/favorites')
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const getFavoriteUsers: getFavoriteUsersType = async () => {
  try {
    return axiosInstance.get('/user-favorites')
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const addToFavorites: addToFavoritesType = async (id) => {
  try {
    return axiosInstance.post('adverts/favorites', { advertId: id })
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const unFavorite: unFavoriteType = async (id) => {
  try {
    return axiosInstance.delete(`adverts/favorites/${id}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const addFavoriteUser: addFavoriteUserType = async (targetId) => {
  try {
    return axiosInstance.post('user-favorites', { targetId: targetId })
  } catch (error: unknown) {
    if (error instanceof AxiosError) throw new Error(error.message)
  }
}

export const unFavoriteUser: unFavoriteUserType = async (targetId) => {
  try {
    return axiosInstance.delete(`user-favorites/${targetId}`)
  } catch (error: unknown) {
    if (error instanceof AxiosError) throw new Error(error.message)
  }
}
