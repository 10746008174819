import React, { useEffect, useState, useMemo } from 'react'

import { GreenButton } from '../buttons'
import { LeaveAReview } from '@/components/forms'
import Modal from '@/components/modals/Modal/Modal'
import RatingProgress from '../RatingProgress'
import { UserFeedback } from '../UserFeedback'
import styles from './Reviews.module.scss'
import { getUserReviews } from '@/services/api/review'
import { useAppSelector } from '@/helpers/hooks'

interface Props {
  open: boolean
  onClose: () => void
  userid: number
  average?: number
  forbidden?: boolean
}

export const Reviews = ({
  open,
  onClose,
  userid,
  average,
  forbidden,
}: Props) => {
  const [openLeaveAReview, setOpenLeaveAReview] = useState(false)
  const { userReviews } = useAppSelector((state) => state.reviews)

  const onCloseFinished = () => {
    setOpenLeaveAReview(false)
    onClose()
  }

  const toggleLeaveAReview = () => {
    setOpenLeaveAReview((prev) => (prev ? false : true))
  }

  const renderReviews = useMemo(
    () =>
      userReviews.map((review, index) => (
        <UserFeedback key={index} className={styles.feedback} review={review} />
      )),
    [userReviews]
  )

  useEffect(() => {
    if (!forbidden) getUserReviews(userid)
  }, [userid, forbidden])

  return (
    <Modal className={styles.Modalcontainer} open={open} onClose={onClose}>
      <div className={styles.container}>
        <LeaveAReview
          onCloseFinished={() => onCloseFinished()}
          onClose={() => toggleLeaveAReview()}
          open={openLeaveAReview}
        />
        <p>Отзывы</p>
        <div className={styles.rating}>
          <RatingProgress
            className={styles.ratingProgress}
            rating={forbidden ? 0 : average}
          />
          <GreenButton onClick={() => toggleLeaveAReview()}>
            Оставить отзыв
          </GreenButton>
        </div>
        <div className={styles.feedbacks}>{renderReviews}</div>
      </div>
    </Modal>
  )
}
