import { axiosInstance } from '@/config'
import { AxiosError } from 'axios'

import {
  activateNotificationsType,
  activateNotificationType,
  deactivateNotificationType,
  getNotificationsCountType,
  getMainNotificationsType,
  getUserNotificationType,
  readMainNotificationsType,
} from './notifications.types'

export const getUserNotification: getUserNotificationType = async () => {
  try {
    return axiosInstance.get(`user-notifications`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const activateNotification: activateNotificationType = async (id) => {
  try {
    return axiosInstance.post(`user-notifications/activate/${id}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const deactivateNotification: deactivateNotificationType = async (
  id
) => {
  try {
    return axiosInstance.post(`user-notifications/deactivate/${id}`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const activateNotifications: activateNotificationsType = async (
  notifications
) => {
  try {
    return axiosInstance.put(`user-notifications`, {
      settings: notifications,
    })
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const readMainNotifications: readMainNotificationsType = async (
  notifications
) => {
  try {
    return axiosInstance.patch(
      `notifications/mark-read
    `,
      notifications ? { ids: notifications } : {}
    )
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const getMainNotifications: getMainNotificationsType = async ({
  page,
  limit,
}) => {
  try {
    return axiosInstance.get(
      `notifications/feed?page=${page || '1'}&limit=${
        limit || 10
      }&sortDirection=DESC&sortColumn=createdAt`
    )
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}

export const getNotificationsCount: getNotificationsCountType = async () => {
  try {
    return axiosInstance.get(`notifications/count`)
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      throw new Error(e.message)
    }
  }
}
