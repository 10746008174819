import React, { useState } from 'react'
import ProBanner from '@assets/images/pro_banner.png'
import styles from './buy-pro.module.scss'
import { GreenButton } from '@/components/elements/buttons'
import { buyProAccount } from '@/services/api/users'
import { AxiosError } from 'axios'
import Modal from '@/components/modals/Modal/Modal'

export function BuyPro() {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const handleBuyProAccount = async () => {
    try {
      const redirect = await buyProAccount()
      console.log(redirect.data.redirectUrl)
      window.open(redirect.data.redirectUrl, '_blank');
    } catch (error) {
      if (error instanceof AxiosError) {
        setModalOpen(true)
        setModalMessage('Произошла ошибка. Повторите попытку еще раз')
      }
    }
  }
  const handleModalClose = () => {
    setModalOpen((prev) => !prev)
  }
  return (
    <div className={styles.buy_pro}>
      <div className={styles.buy_pro__image}>
        <img src={ProBanner} />
      </div>
      <div className={styles.buy_pro__info}>
        <h3 className={styles.info__title}>
          Откройте новые возможности с <span>PRO-аккаунтом!</span>
        </h3>
        <p className={`${styles.list__text} ${styles.list__text_p}`}>
          {' '}
          Почему так выгодно:{' '}
        </p>
        <ul className={styles.info__list}>
          <li className={styles.list__item}>
            <span className={styles.list__text}>
              {' '}
              Просмотр контактов (номер телефона) без ограничений{' '}
            </span>
          </li>
          <li className={styles.list__item}>
            <span className={styles.list__text}>
              Подписка на новые объявления по сохраненной фильтрации
            </span>
          </li>
          <li className={styles.list__item}>
            <span className={styles.list__text}>
              Возможность неограниченно добавлять пользователей в избранное
            </span>
          </li>
        </ul>

        <p className={styles.info__price}>Всего 200 руб. в месяц</p>
        <GreenButton
          className={styles.info__btn}
          onClick={() => handleBuyProAccount()}
        >
          Подключить
        </GreenButton>
      </div>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <p>{modalMessage}</p>
      </Modal>
    </div>
  )
}
