import { ReactComponent as HeartEmoji } from '@assets/icons/emojis/heart.svg'
import Modal from '@/components/modals/Modal/Modal'
import React from 'react'
import styles from './ThanksModal.module.scss'

interface Props {
  open: boolean
  onClose: () => void
}

export const ThanksModal = ({ open, onClose }: Props) => {
  return (
    <Modal open={open} onClose={onClose} className={styles.container}>
      <div className={styles.wrap}>
        <strong>
          Спасибо! <HeartEmoji />
        </strong>
        <div className={styles.desc}>
          <p className={'green'}>Ваше мнение очень важно!</p>
          <p>Жалоба отправлена на рассмотрение.</p>
        </div>
      </div>
    </Modal>
  )
}
