import { Chip, ChipProps } from '@mui/material'
import styles from './Tag.module.scss'
import { ReactComponent as CrossSVG } from '@assets/icons/Cross.svg'
import classnames from 'classnames'

interface Props extends ChipProps {
  label: string
  handleClick?: (value: string) => void
  handleDelete?: (value: string) => void
  enabled?: boolean
}

export const Tag = ({
  label,
  enabled,
  handleClick,
  handleDelete,
  ...props
}: Props) => {
  const ChipStyles = classnames({
    [styles.tag]: true,
    [styles.enabled]: enabled,
  })

  return (
    <Chip
      {...props}
      label={label}
      clickable={handleClick ? true : false}
      onClick={() => {
        handleClick && handleClick(label)
      }}
      onDelete={() => {
        handleDelete && handleDelete(label)
      }}
      deleteIcon={<CrossSVG className={styles.deleteIcon} />}
      className={ChipStyles}
    />
  )
}
