import { UserData } from '@/types/Auth'
import { mainNotificationType } from '@/types/Notifications/Notifications'
import { Link } from 'react-router-dom'

export const DefineNotificationType = (
  notification: mainNotificationType,
  userInfo: UserData
) => {
  const notificationInfo = notification.notification
  switch (notificationInfo.entityType) {
    case 'REVIEW':
      return {
        ...notification,
        title: `Пользователь ${notificationInfo.info.firstName} оставил отзыв в вашем профиле. `,
        description: [
          `Перейдите в `,
          <Link to={`/profile/${userInfo?.id}?reviews=true`}>профиль</Link>,
          ` чтобы посмотреть`,
        ],
      }
    case 'ADVERT':
      const status =
        notificationInfo.action === 'REJECT' ? 'отклонено' : 'одобрено'
      return {
        ...notification,
        title: `Ваше объявление ${notificationInfo.info.advertName} было ${status} модератором`,
        link: `/announcement/${notificationInfo.entityId}`,
      }
    case 'MESSAGE':
      return {
        ...notification,
        title: `Вам поступило новое сообщение.`,
        description: [
          `Посмотрите его `,
          <Link to={`/profile/messages/${notificationInfo.entityId}`}>
            здесь
          </Link>,
          ` на страницe сообщений`,
        ],
      }
    case 'USER':
      return {
        ...notification,
        title: `Ваш про-аккаунт закончится через неделю.`,
        description: [
          `Чтобы продлить его перейдите к `,
          <Link to="/">оплате</Link>,
          ` на страницу`,
        ],
        link: `/profile/${userInfo?.id}?reviews=true`,
      }
    case 'SALE':
      // бэкенд еще не готов
      return {}
    case 'COMPLAINT':
      return {
        ...notification,
        title: `Ваша жалоба на ${notificationInfo.info.firstName} ${
          notificationInfo.info.lastName
        } была ${
          notificationInfo.action === 'REJECT' ? 'отклонена' : 'одобрена'
        }`,
      }
    case 'SUBSCRIPTION_ADVERTS':
      return {
        ...notification,
        title: `Новое объявление по сохраненному набору фильтров`,
        description: [`Перейти в `, <Link to="/">объявление</Link>],
      }
    default:
      return notification
  }
}
