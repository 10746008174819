import { Link } from 'react-router-dom'
import { ReactComponent as PhotoIcon } from '@assets/icons/photo.svg'
import { backendUrl } from '@/config'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { dialogMessageType } from '@/types/Dialogs'
import styles from './Dialog.module.scss'

interface Props {
  avatar: string
  name: string
  lastMessage: dialogMessageType
  id: number
  unreadMessagesCount: number
  selectedDialog?: string
}

export const Dialog = ({
  avatar,
  name,
  id,
  unreadMessagesCount,
  selectedDialog,
  lastMessage,
}: Props) => {
  const dialogClasses = classNames({
    [styles.dialog]: true,
    [styles.selected]: selectedDialog === `${id}` && true,
  })

  return (
    <Link className={styles.link} to={`/profile/messages/${id}`}>
      <section className={dialogClasses}>
        <div
          className={styles.dialog_avatar}
          style={{ backgroundImage: `url(${backendUrl}files/${avatar})` }}
        >
          {(!avatar || typeof avatar == 'object') && (
            <PhotoIcon className={styles.photoIcon} />
          )}
        </div>
        <div className={styles.dialog_center}>
          <h4>{name}</h4>
          <span>{lastMessage?.messages}</span>
        </div>
        <div className={styles.dialog_right}>
          <span>{dayjs(lastMessage?.createdAt).format('HH:mm')}</span>
          {/* тут исходя от наличия новых смс будет появляться нижний блок */}
          {unreadMessagesCount > 0 && (
            <div className={styles.dialog_right_counter}>
              <span>{unreadMessagesCount}</span>
            </div>
          )}
        </div>
      </section>
    </Link>
  )
}
