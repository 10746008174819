import Eye from '@/assets/icons/eye-circle.svg'
import { Link } from 'react-router-dom'
import { UserData } from '@/types/Auth'
import { backendUrl } from '@/config'
import classNames from 'classnames'
import styles from './Card.module.scss'
interface Props {
  id: number
  image?: string
  title: string
  myUserInfo?: UserData | null
  customUrl?: string
  views?: number
  slug?: string
}

export const BlogCard = ({
  id,
  image,
  title,
  views,
  customUrl,
  slug,
}: Props) => {
  const infoClass = classNames({
    [styles.card__info]: true,
    [styles.noImage]: !customUrl && !image && true,
  })

  console.log(image)

  return (
    <div className={`cardContainer ${styles.card}`}>
      <Link
        to={id ? `/blog/${slug}` : ''}
        state={{ advertId: id }}
        target={id ? '_blank' : ''}
        className={styles.card__imageLink}
      >
        {image && (
          <img
            className={styles.card__image}
            src={customUrl || `${backendUrl}files/${image}`}
          />
        )}

        <div className={styles.card__views}>
          {views} <img src={Eye} />{' '}
        </div>
      </Link>
      <div className={infoClass}>
        <Link
          className={styles.card__title}
          to={slug ? `/blog/${slug}` : `/blog/${slug}`}
          state={{ advertId: id }}
          target={id ? '_blank' : ''}
        >
          <h4>{title}</h4>
        </Link>
      </div>
    </div>
  )
}
