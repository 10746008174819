import { GreenButton } from '@/components/elements/buttons';
import FormError from '@/components/forms/elements/FormError/FormError';
import { SearchInput, TextInput } from '@/components/forms/elements/inputs';
import Modal from '@/components/modals/Modal/Modal';
import { debounce } from '@/helpers/decorators/debounce';
import { trimField } from '@/helpers/form/trimField';
import { useAppSelector } from '@/helpers/hooks';
import { getAllCities } from '@/services/api/cities';
import { changeUserPassword, getUserPhoneNumber, userContactsSettings } from '@/services/api/user';
import { getCurrentUser } from '@/services/api/users';
import { OutletContextType } from '@/types';
import success from '@assets/images/success_buble.png';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import settingsStyles from '../Settings.module.scss';
import styles from './SettingsContacts.module.scss';

interface IDefaultValues {
  phone?: string
  email?: string
  cityName?: string
}

function SettingsContacts() {
  const [setTitle] = useOutletContext<OutletContextType>()
  const { userInfo } = useAppSelector((state) => state.auth)
  const { cities } = useAppSelector((state) => state.cities)
  const [changePassword, setChangePassword] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const [isPasswordModal, setIsPasswordModal] = useState<boolean>(false)
  const [isChangesSaved, setIsChangesSaved] = useState<boolean>(true)
  const [userPhone] = useState<string>('')
  useEffect(() => {
    setTitle('Настройки')
  }, [])
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    reset,
    getValues,
    watch,
  } = useForm()

  const city = watch('cityName')
  const onPasswordModalClose = () => {
    setIsPasswordModal((prev) => !prev)
  }

  useEffect(() => {
    if (userInfo?.cityName) {
      getAllCities(userInfo.cityName)
    }
  }, [userInfo?.cityName])

  const phoneInputValue = useWatch({
    control,
    name: 'phone',
  })

  useEffect(() => {
    const regex = /[^+\d]/g
    const phoneValue = phoneInputValue || ' '
    const match = phoneValue?.replace(regex, '')

    setValue('phone', match)
  }, [phoneInputValue])

  const submitHandler = async (data: FieldValues) => {
    const currCityData = cities.find((item) => item.value === data.cityName)
    const isDataNotEmpty =
      (data.hasOwnProperty('cityName') && data.hasOwnProperty('email')) ||
      data.hasOwnProperty('new_password')

    const isPasswordConfirmed = Boolean(
      data.hasOwnProperty('cityName') &&
        data.hasOwnProperty('email') &&
        data.password
    )

    if (isDataNotEmpty) {
      onPasswordModalClose()
    }

    if (!currCityData) {
      return setError('cityName', {
        type: 'custom',
        message: 'Выберите город из представленного списка',
      })
    }

    if (isPasswordModal || !data.password) {
      setIsChangesSaved(false)
    }

    if (isPasswordConfirmed) {
      try {
        const { kladr_id } = currCityData.data
        if (!changePassword || data.phone !== userPhone) {
          delete data.new_password
          await userContactsSettings(userInfo?.id, {
            ...data,
            cityKladrId: kladr_id,
            cityName: data.cityName,
            phone: data.phone,
            email: data.email,
            password: data.password,
          }).then(() => {
            setModalMessage('Изменения успешно сохранены')
            setIsOpen(true)
            setIsSuccess(true)
            setIsPasswordModal(false)
            setChangePassword(false)
            reset({
              phone: userPhone,
              email: userInfo?.email,
              cityName: city,
              password: '',
            })
          })
          await getPhoneNumber(Number(userInfo?.id))
        } else {
          const params = {
            oldPassword: data.password,
            newPassword: data.new_password,
          }
          await changeUserPassword({ ...params }).then(() => {
            setModalMessage('Пароль успешно изменен')
            setIsOpen(true)
            setIsSuccess(true)
            setChangePassword(false)
            setIsPasswordModal(false)
            reset({
              phone: userPhone,
              email: userInfo?.email,
              cityName: city,
              new_password: '',
              password: '',
            })
          })
        }
        const uid = localStorage.getItem('uid')
        getCurrentUser(uid)
        await getPhoneNumber(Number(userInfo?.id))
      } catch (error) {
        if (error instanceof AxiosError) {
          setIsSuccess(false)

          switch (error?.response?.data.message) {
            case 'EMAIL_OR_PASSWORD_INCORRECT':
              setModalMessage(
                'Неверный email или пароль. Повторите попытку еще раз'
              )
              reset({
                phone: userPhone,
                email: userInfo?.email,
                cityName: userInfo?.cityName,
                password: '',
              })
              setIsOpen(true)
              break
            case 'PASSWORD_INCORRECT':
              setModalMessage('Неверный пароль. Повторите попытку еще раз')
              reset({
                phone: userPhone,
                email: userInfo?.email,
                cityName: userInfo?.cityName,
                password: '',
              })
              setIsOpen(true)
              setError('password', {
                type: 'custom',
                message: 'Текущий пароль неверный',
              })
              break
            case 'EMAIL_ALREADY_USE':
              setIsOpen(true)
              setModalMessage(
                'Данная эл.почта уже существует. Попробуйте еще раз'
              )
              reset({
                phone: userPhone,
                email: '',
                cityName: userInfo?.cityName,
                password: '',
              })
              break
            case 'PHONE_ALREADY_USE':
              setIsOpen(true)
              setModalMessage('Телефон используется в другом профиле.')
              reset({
                phone: '',
                email: userInfo?.email,
                cityName: userInfo?.cityName,
                password: '',
              })
              break
            default:
              setIsOpen(true)
              setIsSuccess(false)
              setModalMessage('Что-то пошло не так. Попробуйте попытку еще раз')
              break
          }
        }
      }
    } else {
      // setIsChangesSaved(true)
    }
  }

  useEffect(() => {
    const defaultValues: IDefaultValues = {}
    defaultValues.email = userInfo?.email
    defaultValues.phone = userPhone
    defaultValues.cityName = city
    reset({ ...defaultValues })
  }, [userInfo, city, userPhone])

  useEffect(() => {
    if (!userInfo?.id) return
    getPhoneNumber(userInfo?.id)
  }, [userInfo?.id])

  useEffect(() => {
    reset({
      cityName: userInfo?.cityName,
      email: userInfo?.email,
      phone: userPhone,
    })
  }, [userInfo?.cityName])

  const onClose = () => {
    setIsOpen((prev) => !prev)
  }

  const getPhoneNumber = async (userid: number) => {
    const phoneNumber = await getUserPhoneNumber(userid).then(
      (res) => res?.data.phone
    )
    setValue('phone', phoneNumber)
  }

  const fetchCities = async (value: string) => {
    try {
      await getAllCities(value)
    } catch (e) {
      console.log(e)
    }
  }
  const debouncedFetchCities = debounce((value: string) => fetchCities(value))
  return (
    <div className={styles.container}>
      <p className={styles.tabletTitle}>Контакты и пароль</p>
      <form onSubmit={handleSubmit(submitHandler)} className={styles.form}>
        <div className={styles.inputContainer}>
          <SearchInput
            label="Город"
            placeholder={' '}
            options={cities.map(({ value }) => value)}
            control={control}
            className={styles.search_input}
            OnTextChange={(value) => {
              setValue('cityName', value)
              debouncedFetchCities(value)
            }}
            {...register('cityName', {
              required: 'Укажите город',
            })}
          />

          <TextInput
            label="Мобильный телефон"
            {...register('phone', {
              required: 'Обязательное поле',
              minLength: {
                value: 8,
                message: 'минимальная длина номера телефона - 8 символов',
              },
              ...trimField({ name: 'phone', setValue }),
            })}
            control={control}
          />
          <FormError errors={errors} name="phone" />
          <TextInput
            label="Email"
            {...register('email', {
              required: 'Обязательное поле',
              ...trimField({ name: 'email', setValue }),
            })}
            control={control}
          />
          <FormError errors={errors} name="email" />
          <div
            className={
              changePassword
                ? styles.change_password_active
                : styles.change_password
            }
          >
            {changePassword && (
              <>
                <TextInput
                  control={control}
                  inputProps={{ type: 'password' }}
                  label="Новый пароль"
                  {...register('new_password', {
                    required: changePassword ? 'Обязательное поле' : false,
                    minLength: changePassword && {
                      value: 6,
                      message: 'минимальная длина пароля - 6 символов',
                    },
                    maxLength: changePassword && {
                      value: 40,
                      message: 'максимальная длина пароля - 40 символов',
                    },
                    ...trimField({ name: 'new_password', setValue }),
                  })}
                />
                <FormError errors={errors} name="new_password" />
              </>
            )}
          </div>
          <p
            className={styles.changePassText}
            onClick={() => setChangePassword((prev) => !prev)}
          >
            Сменить пароль
          </p>
          <Modal open={isPasswordModal} onClose={onPasswordModalClose}>
            <div className={styles.modal}>
              <div className={styles.modal__password}>
                <h3>Для сохранения изменений подтвердите пароль</h3>
                <div>
                  <TextInput
                    control={control}
                    inputProps={{ type: 'password' }}
                    label={'Текущий пароль'}
                    {...register('password', {
                      required: isPasswordModal ? 'Обязательное поле' : false,
                      minLength: {
                        value: 6,
                        message: 'минимальная длина пароля - 6 символов',
                      },
                      maxLength: {
                        value: 40,
                        message: 'максимальная длина пароля - 40 символов',
                      },
                    })}
                  />
                  {isChangesSaved && (
                    <p className={`form_error ${styles.password_error}`}>
                      Обязательное поле
                    </p>
                  )}
                </div>

                <div className={styles.button_wrapper}>
                  <GreenButton
                    type="button"
                    className={settingsStyles.button}
                    onClick={async () => {
                      const data = getValues()
                      await submitHandler(data)
                    }}
                  >
                    Сохранить
                  </GreenButton>
                  <GreenButton
                    onClick={() => onPasswordModalClose()}
                    className={styles.button_cancel}
                  >
                    Отмена
                  </GreenButton>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <GreenButton type="submit" className={settingsStyles.button}>
          Сохранить изменения
        </GreenButton>
      </form>
      <Modal open={isOpen} onClose={onClose}>
        <div className={styles.modal}>
          <div className={styles.modal__success}>
            {isSuccess ? (
              <img src={success} alt="success" />
            ) : modalMessage === 'Неверный пароль. Повторите попытку еще раз' ||
              modalMessage ===
                'Неверный email или пароль. Повторите попытку еще раз' ? null : (
              <p>Упс...</p>
            )}
            <span>{modalMessage}</span>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SettingsContacts
