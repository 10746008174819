import 'swiper/css'
import 'swiper/css/navigation'

import { GreenButton } from '@/components/elements/buttons'
import YMap from '@/components/elements/YMap'
import { Switch } from '@/components/forms/elements/checkboxes'
import FormError from '@/components/forms/elements/FormError/FormError'
import { TextInput } from '@/components/forms/elements/inputs'
import { GreenSearchInput } from '@/components/forms/elements/inputs/GreenSearchInput/GreenSearchInput'
import { GreenSelect } from '@/components/forms/elements/selects/GreenSelect/GreenSelect'
import { Upload } from '@/components/forms/elements/uploads'
import { useAppSelector } from '@/helpers/hooks'
import { getAdvertById } from '@/services/api/adverts'
import { getCategories, getSubCategories } from '@/services/api/categories'
import { advertEditType, advertImageType } from '@/types'
import { categories } from '@/types/categories'
import { suggestions } from '@/types/getCity'
import { Box } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'

import styles from './AnnouncementForm.module.scss'

// Import Swiper styles

interface Props {
  total: number
  control: Control
  id: number
  mode: string
  setValue: (name: string, value: string) => void
  register: UseFormRegister<FieldValues>
  onSubmit: () => void
  watch: UseFormWatch<FieldValues>
  services: any[]
  debouncedFetchCities: (value: string) => void
  cities: suggestions
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any
    }>
  >
  setImages: Dispatch<SetStateAction<{ id: number; image: FormData }[]>>
  setDefaultImages: Dispatch<SetStateAction<advertImageType[]>>
  defaultImages: advertImageType[]
  resetForm: (advert: advertEditType) => void
  stage: string
}

export const AnnouncementForm = ({
  control,
  register,
  onSubmit,
  watch,
  total,
  services,
  setImages,
  debouncedFetchCities,
  cities,
  errors,
  mode,
  id,
  stage,
  defaultImages,
  setValue,
  setDefaultImages,
  resetForm,
}: Props) => {
  const { userInfo } = useAppSelector((state) => state.auth)
  const [categories, setCategories] = useState<categories>([])
  const [subCategories, setSubCategories] = useState<categories>([])

  const category = watch('category')
  useEffect(() => {
    const fetchAdvert = async () => {
      if (mode !== 'edit') return
      const {
        showPhone,
        subcategoryId,
        categoryId,
        name,
        info,
        budget,
        messageAllow,
        subcategory,
        cityName,
        address,
        cityKladrId,
        photo,
      } = (await getAdvertById(id))?.data as advertEditType
      const formattedAdvert = {
        subcategoryId,
        name,
        info,
        showPhone,
        messageAllow,
        address,
        category: subcategory?.categoryId || categoryId,
        cityName,
        budget,
        cityKladrId,
        photo,
      }
      setDefaultImages((prev: advertImageType[]) => {
        prev.map((image, index) => {
          if (!photo[index]) return image
          prev[index] = photo[index]
        })
        return prev
      })
      resetForm(formattedAdvert as advertEditType)
    }
    fetchAdvert()
  }, [mode])

  useEffect(() => {
    getCategories().then(
      (categoriesData) => categoriesData && setCategories(categoriesData?.data)
    )
  }, [])

  useEffect(() => {
    if (!category) return
    getSubCategories({ categoryId: category }).then(
      (subCategories) => subCategories && setSubCategories(subCategories?.data)
    )
  }, [category])

  const formattedCategories = useMemo(
    () =>
      categories.map((category) => ({
        id: category.id,
        value: category.name,
      })),
    [categories]
  )

  const formattedSubCategories = useMemo(
    () =>
      subCategories.map((subCategory) => ({
        id: subCategory.id,
        value: subCategory.name,
      })),
    [subCategories]
  )

  const allServices = useMemo(
    () =>
      services.map((service) => (
        <Switch
          title={service.name}
          label={`${service.price} руб.`}
          defaultChecked={false}
          {...register(`${service.field}`)}
          control={control}
        />
      )),
    [services, mode]
  )

  const handleGeoClick = async (city: string, address: string) => {
    await debouncedFetchCities(city)
    const currCityData = cities?.find((item) => item.value === `г ${city}`)
      ?.data?.kladr_id
    console.log(currCityData, cities)
    setValue('cityName', `г ${city}`)
    currCityData && setValue('cityKladrId', currCityData)
    setValue('address', address)
  }

  return (
    <>
      <div
        className={styles.formWrapper}
        style={{ display: stage === 'edit' ? 'flex' : 'none' }}
      >
        <div className={styles.uploadContainer}>
          {defaultImages.map((image) => (
            <Upload
              key={`${image.id}-${image.name}`}
              id={`${image.id}`}
              src={image.name || ''}
              afterUpload={setImages}
              type="advert"
              name="image1"
              control={control}
            />
          ))}
        </div>
        <div className={`${styles.formInputBox} ${styles.categories}`}>
          <GreenSelect
            label="Категория"
            items={formattedCategories}
            {...register('category', { required: 'Обязательное поле' })}
            control={control}
          />
          <FormError errors={errors} name="category" />
          <GreenSelect
            label="Подкатегория"
            items={formattedSubCategories}
            {...register('subcategoryId', { required: 'Обязательное поле' })}
            control={control}
          />
          <FormError errors={errors} name="subcategoryId" />
        </div>
        <div className={styles.formInputBox}>
          <h5 className={styles.labelTitle}>Заголовок объявления</h5>
          <TextInput
            inputProps={{ placeholder: 'Кратко и по делу' }}
            {...register('name', { required: 'Обязательное поле' })}
            onTextChange={(value) => {
              if (value[0] == value.charAt(0).toLocaleUpperCase()) return value
              let newValue = `${value}`
              newValue = value.charAt(0).toLocaleUpperCase() + value.slice(1)
              return newValue
            }}
            control={control}
          />
          <FormError errors={errors} name="name" />
          <h5 className={styles.labelTitle}>Текст объявления</h5>
          <TextInput
            inputProps={{
              multiline: true,
              placeholder:
                'Рекомендуется заполнить описание не менее чем на 300 символов',
              helperText: (
                <Box
                  component="span"
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span></span>
                  <span>{`${
                    ((watch('info') as string) || '').length
                  } / ${7500}`}</span>
                </Box>
              ),
            }}
            {...register('info', {
              required:
                'Длина описания не может быть менее 1 символа или превышать 7500 символов',
              minLength: {
                value: 1,
                message:
                  'Длина описания не может быть менее 1 символа или превышать 7500 символов',
              },
              maxLength: {
                value: 7500,
                message:
                  'Длина описания не может быть менее 1 символа или превышать 7500 символов',
              },
            })}
            onTextChange={(value) => {
              if (value[0] == value.charAt(0).toLocaleUpperCase()) return value
              let newValue = `${value}`
              newValue = value.charAt(0).toLocaleUpperCase() + value.slice(1)
              return newValue
            }}
            control={control}
          />
          <FormError errors={errors} name="info" />
        </div>
        <div className={styles.formInputBox}>
          <h5 className={styles.labelTitle}>Бюджет (не обязательно!)</h5>
          <p className={styles.labelDesc}>
            Укажите примерный бюджет, который требуется от другого пользователя
            для совместного времяпровождения, аренды помещения или других
            действий. Бюджет можно не указывать.
          </p>
          <div className={styles.budgetInput}>
            <TextInput
              {...register('budget')}
              inputMode="numeric"
              rules={{
                min: { value: 0, message: 'Введите положительное число' },
              }}
              inputProps={{ inputMode: 'numeric', type: 'number' }}
              control={control}
            />
            <p>руб.</p>
          </div>
          <FormError errors={errors} name="budget" />
        </div>
        <div className={`${styles.locationBox} ${styles.formInputBox}`}>
          <GreenSearchInput
            label={'Город'}
            className={styles.greenInput}
            options={cities.map(({ value }) => {
              return value
            })}
            control={control}
            OnTextChange={(value) => {
              debouncedFetchCities(value)
            }}
            inputProps={{
              placeholder: 'Город',
            }}
            {...register('cityName', {
              required: { value: true, message: 'Укажите город' },
            })}
          />
          <FormError errors={errors} name="cityName" />
          <TextInput
            label="Адрес"
            {...register('address', {
              required: 'Обязательное поле',
              minLength: { value: 2, message: 'Aдрес слишком короткий' },
            })}
            control={control}
          />
          <FormError errors={errors} name="address" />
          <YMap
            className={styles.map}
            handleGeoClick={handleGeoClick}
            mapGeocode={`${watch('cityName') || 'Москва'} ${watch('address')}`}
          />
        </div>
        {!!userInfo?.phone && (
          <div className={` ${styles.formInputBox}`}>
            {/*           <Switch
            title="Можно писать сообщения"
            defaultChecked={false}
            desc="Переписывайтесь прямо здесь и сейчас"
            {...register('messageAllow')}
            control={control}
          /> */}
            <Switch
              title="Показывать мобильный телефон"
              defaultChecked={false}
              desc="Получайте больше откликов"
              {...register('showPhone')}
              control={control}
            />
          </div>
        )}
        {allServices?.length > 0 && (
          <div className={`${styles.locationBox} ${styles.formInputBox}`}>
            {allServices}
          </div>
        )}
        <div className={styles.submitContainer}>
          <p className={styles.total}>
            Итого к оплате: <span className={styles.green}>{total} руб.</span>
          </p>
          <GreenButton
            className={mode === 'edit' ? styles.publish : ''}
            onClick={onSubmit}
            type={total !== 0 ? 'button' : 'submit'}
          >
            Опубликовать
          </GreenButton>
        </div>
      </div>
    </>
  )
}
