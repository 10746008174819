import { AdminSider, Footer, Header } from '@/components/layout-elements'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'

import CompletingSignUp from '@/components/layout-elements/CompletingSignUp'
import { Sider } from '@/components/layout-elements/sider'
import styles from './everyone-layout.module.scss'
import { useAppSelector } from '@/helpers/hooks'
import { AuthorizedUsersOnlyModal } from '@/components/layout-elements/AuthorizedUsersOnlyModal/AuthorizedUsersOnlyModal'
import classNames from 'classnames'
import { ShowProfile } from '@/components/elements/ShowProfile'
import SettingsNav from '@/components/layout-elements/SettingsNav'
import { LogoutButton } from '@/components/elements/buttons'
import { CircularProgress } from '@mui/material'

type EveryoneLayoutProps = {
  withAuth?: boolean
}

export function EveryoneLayout({ withAuth }: EveryoneLayoutProps) {
  const [title, setTitle] = React.useState('Здесь есть всё')
  const { authLoading, userInfo, isAdmin } = useAppSelector(
    (state) => state.auth
  )
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const rightColClass = classNames({
    [styles.rightCol]: !pathname.includes('setting') && true,
    [styles.rightColSettings]: pathname.includes('setting') && true,
  })

  useEffect(() => {
    document.title = 'Liluf | ' + title
  }, [title])

  useEffect(() => {
    if (!authLoading && withAuth && !userInfo) {
      navigate('/', { replace: true })
    }
  }, [authLoading])

  console.log('pathname.includes(setting)', pathname.includes('setting'))

  return (
    <>
      <div className={`container ${styles.layout}`}>
        <div
          className={styles.layout_content}
          style={{
            justifyContent: pathname.includes('setting')
              ? 'initial'
              : 'space-between',
          }}
        >
          <div className={styles.leftCol}>
            {isAdmin ? <AdminSider /> : <Sider />}
          </div>
          <div className={rightColClass}>
            <div>
              <Header title={title || 'Здесь есть всё'} />
              {authLoading ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 50,
                  }}
                >
                  <CircularProgress
                    style={{ height: 21, width: 21 }}
                    color="success"
                  />
                </div>
              ) : (
                <Outlet context={[setTitle, title]} />
              )}
            </div>
          </div>
          <div
            style={{
              display: !pathname.includes('setting') ? 'none' : 'flex',
            }}
            className={styles.settings_nav}
          >
            <ShowProfile
              name={userInfo?.firstName}
              age={userInfo?.age}
              image={userInfo?.avatar || ''}
            />
            <SettingsNav />
            <LogoutButton />
          </div>
        </div>

        <Footer />
      </div>
      <CompletingSignUp />
      <AuthorizedUsersOnlyModal />
    </>
  )
}
