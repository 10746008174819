import TableContainer from '@/components/elements/TableContainer'
import Modal from '@/components/modals/Modal/Modal'
import { useAppSelector } from '@/helpers/hooks'
import { getModeration } from '@/services/api/moderation'
import { TableCell, TableRow } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import AdvertsTable from './AdvertsTable'

const AdvertModeration = () => {
  const { allModeration } = useAppSelector((state) => state.moderation)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [params, setParams] = useState({
    page: 0,
    limit: 10,
    entityType: 'advert',
  })
  useEffect(() => {
    getModeration(params)
  }, [params, submitted])

  const handleClose = () => setIsOpen((prev) => !prev)
  const handleOpenSuccessModal = () => {
    setIsOpen(true)
    setIsSuccess(true)
  }
  const handleOpenFailedModal = () => {
    setIsOpen(true)
    setIsSuccess(false)
  }

  const renderList = useMemo(
    () =>
      allModeration.items.map((advert) => (
        <AdvertsTable
          handleOpenSuccessModal={handleOpenSuccessModal}
          handleOpenFailedModal={handleOpenFailedModal}
          key={advert.id}
          {...advert}
          setSubmitted={setSubmitted}
        />
      )),
    [allModeration.items]
  )

  const handleChangePage = (_: unknown, newPage: number) => {
    setParams((prev) => ({ ...prev, page: newPage }))
  }
  return (
    <>
      <Modal onClose={handleClose} open={isOpen}>
        <h4>
          {!isSuccess
            ? 'Произошла ошибка, повторите еще раз'
            : 'Ответ успешно записан'}
        </h4>
      </Modal>
      <h2 style={{ marginBottom: 20 }}>Модерация объявлений</h2>
      <TableContainer
        Header={
          <TableRow>
            <TableCell>Ссылка на объявление</TableCell>
            <TableCell>Пользователь</TableCell>
            <TableCell>Дата и время создания: </TableCell>
            <TableCell>Статус</TableCell>
            <TableCell>Ответ модератора: </TableCell>
          </TableRow>
        }
        Body={renderList}
        handleChangePage={handleChangePage}
        page={params.page}
        rowsPerPage={params.limit}
        count={allModeration.total}
      />
    </>
  )
}

export default AdvertModeration
