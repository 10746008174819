import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'

import AnnouncementFormStyles from '../AnnouncementForm/AnnouncementForm.module.scss'
import Divider from '@mui/material/Divider'
import { GoBackTitle } from '@/components/elements/GoBackTitle'
import { GreenButton } from '@/components/elements/buttons'
import React from 'react'
import { Switch } from '@/components/forms/elements/checkboxes'
import { services } from '@/types/services'
import styles from './PaymentForm.module.scss'

interface Props {
  total: number
  control: Control
  register: UseFormRegister<FieldValues>
  setStage: (title: string) => void
  services: services
  watch: UseFormWatch<FieldValues>
  stage: string
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any
    }>
  >
}

export const PaymentForm = ({
  control,
  register,
  setStage,
  total,
  stage,
  services,
  errors,
}: Props) => {
  return (
    <>
      {stage !== 'edit' && (
        <div className={styles.finalContainer}>
          <GoBackTitle
            onClick={() => setStage('edit')}
            text={'Вернуться назад'}
          />
          <div className={styles.payments}>
            <p>Подтвердите платные услуги:</p>
            <>
              {services?.map((service) => (
                <Switch
                  title={service.name}
                  label={`${service.price} руб.`}
                  {...register(`${service.field}`)}
                  control={control}
                />
              ))}
            </>
            <Divider />
            <div
              className={`${styles.submitContainer} ${AnnouncementFormStyles.submitContainer}`}
            >
              <p className={AnnouncementFormStyles.total}>
                Итого к оплате:{' '}
                <span className={AnnouncementFormStyles.green}>
                  {total} руб.
                </span>
              </p>
              <GreenButton
                onClick={() => {
                  Object.entries(errors).length > 0 && setStage('edit')
                }}
                type="submit"
              >
                Оплатить и опубликовать
              </GreenButton>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
