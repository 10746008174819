import { reviews } from '@/types/Reviews'
import { createSlice } from '@reduxjs/toolkit'

interface initialState {
  userAverageRating: number
  userReviews: reviews[]
}

const initialState: initialState = {
  userAverageRating: 0,
  userReviews: [],
}

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setUserReviews: (state, action) => {
      state.userReviews = action.payload
    },
    setUserAverage: (state, action) => {
      state.userAverageRating = action.payload
    },
  },
})

export const { setUserReviews, setUserAverage } = reviewsSlice.actions
export default reviewsSlice.reducer
