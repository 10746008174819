import proBanner from '@assets/images/pro_banner.png'
import styles from './pro-account.module.scss'
import { GreenButton } from '@/components/elements/buttons'
import { Link } from 'react-router-dom'

interface IProps {
  handleOpenAndClose: () => void
}

function ProAccountBanner({ handleOpenAndClose }: IProps) {
  return (
    <div className={styles.proBanner}>
      <button onClick={handleOpenAndClose} className={styles.mobile_close}>
        &times;
      </button>
      <img src={proBanner} />
      <div>
        <h4 className={styles.proBanner__title}>
          Откройте новые возможности
          <br />с <span className={styles.orange}>PRO-аккаунтом</span>
        </h4>
        <p className={styles.proBanner__text}>
          Неограниченное количество откликов, возможность добавлять более 5
          объявлений в месяц и многое другое!
        </p>
        <GreenButton
          className={`${styles.proBanner_orangeButton} ${styles.proBanner_orangeButton_mobile}`}
          loading={false}
        >
          <Link to={'/pro'} className={styles.more}>
            Узнать подробнее
          </Link>
        </GreenButton>
      </div>
      <GreenButton className={styles.proBanner_orangeButton} loading={false}>
        <Link to={'/pro'} className={styles.more}>
          Узнать подробнее
        </Link>
      </GreenButton>
    </div>
  )
}

export default ProAccountBanner
