import { AuthForm } from '@/components/forms'
import React from 'react'
import styles from '@/components/layout-elements/sider/sider.module.scss'
import { Link } from 'react-router-dom'
import { ReactComponent as LilufLogo } from '@assets/icons/liluf.svg'

function NotAuthed() {
  return (
    <>
      <div className={`${styles.sider_header} ${styles.sider_header_not_auth}`}>
        <Link to={'/'} onClick={()=>{
          setTimeout(()=>{
            window.location.reload()
          },0)
        }}>
          <LilufLogo className={styles.liluf_logo} />
        </Link>
      </div>

      <div className={styles.not_authed}>
        <div className={styles.not_authed_form}>
          <AuthForm />
        </div>

        <div className={styles.sider_about_us}>
          <div
            className={`${styles.sider_header} ${styles.sider_header_mobile_not_auth}`}
          >
            <Link to={'/'}>
              <LilufLogo className={styles.liluf_logo} />
            </Link>
          </div>
          <p className={styles.about_us_title}>
            <span className={styles.green}>Платформа LILUF</span> - поиск друзей
            и соседей!
          </p>
          <p className={styles.about_us_text}>
            Наша миссия — знакомство людей для совместного отдыха и решения
            бизнес-задач. У нас можно найти друзей, партнеров и подрядчиков.
            <br /> <br /> С помощью нашего сервиса вы найдете компанию, с кем
            сходить в кино или отправиться в путешествие, разделить аренду
            переговорной или фотостудии, организовать совместное мероприятие и
            незабываемый отдых и сможете решить другие задачи.
          </p>
        </div>
      </div>
    </>
  )
}

export default NotAuthed
