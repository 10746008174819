import { Control, FieldValues } from 'react-hook-form'
import { FormControlProps, Radio } from '@mui/material'
import React, { AllHTMLAttributes } from 'react'

import styles from './ButtonRadio.module.scss'

interface Props extends FormControlProps {
  name?: string
  label?: React.ReactNode
  control?: Control<FieldValues> | undefined
  defaultValue?: string
  selectsProps?: AllHTMLAttributes<HTMLLabelElement>
  value?: string
}

export const ButtonRadio = ({ value, id }: Props) => {
  return (
    <>
      <div className={styles.container}>
        <Radio value={value} id={id} className={styles.input} />
        <label htmlFor={id} className={styles.label}>
          {id}
        </label>
      </div>
    </>
  )
}
