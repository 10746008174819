import React, { Dispatch, SetStateAction } from 'react'
import { Button, TableCell, TableRow } from '@mui/material'
import { Link } from 'react-router-dom'
import useRejectModal from '@/components/elements/RejectModal'
import { moderationItemType } from '@/services/api/moderations/moderations.types'
import { updateAdminAnswer } from '@/services/api/moderations'
import { AxiosError } from 'axios'
import { domain } from '@/config'
import { getDateWithTime } from '@/helpers/DateFormatters'
interface IProps extends moderationItemType {
  setSubmitted: Dispatch<SetStateAction<boolean>>
  handleOpenFailedModal: () => void
  handleOpenSuccessModal: () => void
}

const AdvertsTable: React.FC<IProps> = ({
  user,
  statusId,
  createdAt,
  id,
  reason,
  entityId,
  setSubmitted,
  handleOpenFailedModal,
  handleOpenSuccessModal,
}) => {
  const { handleOpen, RejectModal } = useRejectModal()
  const handleAcceptModeration = async () => {
    const answerData = {
      statusId: 2,
    }
    try {
      await updateAdminAnswer(answerData, id)
      setSubmitted((prev) => !prev)
      handleOpenSuccessModal()
    } catch (error) {
      if (error instanceof AxiosError) {
        handleOpenFailedModal()
        throw new Error(error.message)
      }
    }
  }
  const moderationStatus =
    statusId === 3
      ? 'Не принято'
      : statusId === 2
      ? 'Одобрено'
      : 'Не обработано'
  return (
    <>
      <RejectModal
        type="объявление"
        id={id}
        setSubmitted={setSubmitted}
        handleOpenSuccessModal={handleOpenSuccessModal}
        handleOpenFailedModal={handleOpenFailedModal}
      />
      <TableRow>
        <TableCell align="left">
          <Link
            to={`/announcements/${entityId}`}
            target="_blank"
          >{`${domain}announcements/${entityId}`}</Link>
        </TableCell>
        <TableCell>
          {user.firstName} {user.lastName}
        </TableCell>
        <TableCell align="left">{getDateWithTime(createdAt)}</TableCell>
        <TableCell align="left">{moderationStatus}</TableCell>
        <TableCell align="center">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
          >
            {reason ? (
              <p>{reason}</p>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={statusId !== 1}
                  onClick={handleAcceptModeration}
                >
                  Принять
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  disabled={statusId !== 1}
                  onClick={handleOpen}
                >
                  Отклонить
                </Button>
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}

export default AdvertsTable
